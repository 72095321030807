/*
 =========================================================
 * Vue Black Dashboard - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from 'vue-router-prefetch'
import App from "./App";
import store from "./store";
import axios from 'axios'
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";

import fontMinAws from "@/assets/fontawesome-free-6.2.0-web/css/all.min.css"
import fontAws from "@/assets/fontawesome-free-6.2.0-web/css/all.min.css"
import style from "@/assets/css/style.css"

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n"
import './registerServiceWorker'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import dotenv from 'dotenv';

// Load environment variables
dotenv.config();

Vue.use(VueSweetalert2);
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.prototype.$http = axios;
const token = localStorage.getItem('loyalty-auth-token')
if(token) Vue.prototype.$http.defaults.headers.common['Authorization'] = token; 

Vue.filter('formatDate', function (value) {
  if (!value) return '';

  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
});

Vue.filter('formatDate2', function(value) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const date = new Date(value);
  return date.toLocaleDateString('en-US', options);
});

Vue.filter('lastSevenChars', function (value) {
  if (!value) return '';
  return value.slice(-7);
});

/* eslint-disable no-new */
new Vue({
  store,
  router,
  i18n,
  fontMinAws,
  fontAws,
  style,
  render: h => h(App)
}).$mount("#app");
