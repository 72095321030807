<template>
  <div style="position:relative;">
        <div class="row globalTabs">
            <div class="col-md-12">
                <h3 style="margin-bottom: 70px;">Welcome to Manager</h3>
                <div class="card card-nav-tabs card-plain">
                    <div class="card-header card-header-danger">
                        <div class="nav-tabs-navigation">
                            <div class="nav-tabs-wrapper">
                                <ul class="nav nav-tabs" data-tabs="tabs">
                                    <li @click="selectPage('payments')" class="nav-item" >
                                        <router-link to="/manager/payments">
                                            <span class="nav-link" :class="selectedPage == 'payments' ? 'active' : '' "  data-toggle="tab">Payments</span>
                                        </router-link>
                                    </li>
                                    <li @click="selectPage('users')" class="nav-item">
                                        <router-link to="/manager/users">
                                            <span class="nav-link" :class="selectedPage == 'users' ? 'active' : '' " data-toggle="tab">Users</span>
                                        </router-link>
                                    </li>
                                    <li v-if="false" @click="selectPage('invoices')" class="nav-item">
                                        <router-link to="/manager/invoices">
                                            <span class="nav-link" :class="selectedPage == 'invoices' ? 'active' : '' " data-toggle="tab">Invoices</span>
                                        </router-link>
                                    </li>
                                    <li @click="selectPage('account')" class="nav-item">
                                        <router-link to="/manager/account">
                                            <span class="nav-link" :class="selectedPage == 'account' ? 'active' : '' " data-toggle="tab">Account Info</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div></div>
            </div>
        </div>
        <div  class="newStroe row">
            <div class="col-sm-12">
                <card>
                    <div>
                        <div v-if="selectedPage == 'payments'">
                            <div class="text-center">
                                <router-link to="/manager/payments/add">
                                    <button class="btn-add-payment">
                                        <span><i class="fa-regular fa-credit-card"></i></span>
                                         <span> Add Payment </span> 
                                     </button>
                                </router-link>
                            </div>
                            <div class="mt-5">
                                <div class="row">
                                    <div v-for="(payment,index) in payments" :key="index" class="col-12 col-sm-6 col-md-4 mb-4">
                                        <div class="item-p">
                                            <div class="d-flex mb-2">
                                                <div class="mr-4">#{{payment._id | lastSevenChars}}</div>
                                                <div>
                                                    <span class="mr-2">
                                                        <i class="fa-solid fa-calendar" style="color: #f0f0f0;"></i>
                                                    </span>
                                                    <span>{{payment.createdAt | formatDate}}</span>
                                                </div>
                                            </div>
                                            <div class="p-m mb-2">
                                                <span class="mr-2"><i class="fa-solid fa-cart-shopping" style="color: #e6e7ea;"></i></span>
                                                <span class="gr-clr">{{payment.method}}</span>
                                            </div>
                                            <div class="p-s mb-2">
                                                <span class="mr-2"><i class="fa-solid fa-list-check" style="color: #f0f0f0;"></i></span>
                                                <span class="">{{ payment.status }}</span>
                                            </div>
                                            <div class="text-center">
                                                <span class="text-md font-weight-bold gr-clr">{{ payment.total }}</span>&nbsp;
                                                <span>USD</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-4">
                                        <div class="item-p">
                                            <div class="d-flex mb-2">
                                                <div class="mr-4">#2579417</div>
                                                <div>
                                                    <span class="mr-2">
                                                        <i class="fa-solid fa-calendar" style="color: #f0f0f0;"></i>
                                                    </span>
                                                    <span>2023-07-22 13:07</span>
                                                </div>
                                            </div>
                                            <div class="p-m mb-2">
                                                <span class="mr-2"><i class="fa-solid fa-cart-shopping" style="color: #e6e7ea;"></i></span>
                                                <span class="gr-clr">paypal</span>
                                            </div>
                                            <div class="p-s mb-2">
                                                <span class="mr-2"><i class="fa-solid fa-list-check" style="color: #f0f0f0;"></i></span>
                                                <span>CREATED</span>
                                            </div>
                                            <div class="text-center">
                                                <span class="text-md font-weight-bold gr-clr">37.00</span>&nbsp;
                                                <span>USD</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div v-if="selectedPage == 'users'">
                            <div v-if="false" class="d-flex actions-bar">
                                <base-button @click="newUser()" class="ml-auto" type="primary" >Add User</base-button>
                            </div>
                            <div class="table-responsive">
                                <base-table :data="table1.data"
                                            :columns="table1.columns"
                                            v-on:emitToEdit="editUser($event)"
                                            
                                            thead-classes="text-primary">
                                </base-table>
                            </div>
                        </div>
                        <div v-if="selectedPage == 'invoices'">
                            INVOICES
                        </div>
                        <div v-if="selectedPage == 'account'">
                            <h3 class="white-clr mb-5">Account informations</h3>
                            <div>
                                <div>
                                    <div v-if="false" class="d-flex">
                                        <div class="mr-4 mb-4 white-clr">ID:</div>
                                        <div class="white-clr">{{ myAccount._id }}</div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="mr-4 mb-4 white-clr">status:</div>
                                        <div class="orange-clr">{{myAccount.status}}</div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="mr-4 mb-4 white-clr">Pack & Subscription:</div>
                                        <div class="blue-clr">{{myAccount.pack}} - {{myAccount.subscription}}</div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="mr-4 mb-4 white-clr">Created date:</div>
                                        <!-- <div class="white-clr">2021-04-08 14:27</div> -->
                                        <div class="green-clr">{{myAccount.createdAt | formatDate}}</div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="mr-4 mb-4 white-clr">Expiration:</div>
                                        <div class="red-clr">{{myAccount.experationDate | formatDate}}</div>
                                        <!-- <div class="white-clr">2023-01-12 14:27</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>

<script>

import { BaseTable } from "@/components";
import NotificationTemplate from '@/pages/Notifications/NotificationTemplate';
const tableColumns = ["fullname" , "email", "phone", "actions"];
const tableData = [
  {
    _id: 1,
    name: "Dakota Rice",
    email: "$36.738",
    phone: "Niger",
    points: "Oud-Turnhout",
  },
  {
    _id: 2,
    name: "Minerva Hooper",
    email: "$23,789",
    phone: "Curaçao",
    points: "Sinaai-Waas"
  },
  {
    _id: 3,
    name: "Sage Rodriguez",
    email: "$56,142",
    phone: "Netherlands",
    points: "Baileux"
  },
  {
    _id: 4,
    name: "Philip Chaney",
    email: "$38,735",
    phone: "Korea, South",
    points: "Overland Park"
  },
  {
    _id: 5,
    name: "Doris Greene",
    email: "$63,542",
    phone: "Malawi",
    points: "Feldkirchen in Kärnten"
  },
  {
    _id: 6,
    name: 'Mason Porter',
    email: '$98,615',
    phone: 'Chile',
    points: 'Gloucester'
  },
  {
    _id: 7,
    name: 'Jon Porter',
    email: '$78,615',
    phone: 'Portugal',
    points: 'Gloucester'
  }
];

export default {
    components:{
        BaseTable
    },  
    data(){
        return{
            selectedPage: '',
            account:{},
            table1: {
                title: "Simple Table",
                columns: [...tableColumns],
                data: [] //[...tableData] //[...tableData]
            },
            table2: {
                title: "Table on Plain Background",
                columns: [...tableColumns],
                data: [...tableData]
            },
            listUsers: []
        }
    },
    async mounted() {
        console.log('ACCOUNT in manager', this.$store.state.myAccount);
        this.account = this.$store.state.myAccount;
        if(this.$route.path == '/manager/payments'){
            this.selectedPage = 'payments';
            await this.$store.dispatch('loadPayments'); 
        } 
        if(this.$route.path == '/manager/users'){
            this.selectedPage = 'users';
            await this.$store.dispatch('loadUsers');
            this.formData();
            //this.getData();
        }
        if(this.$route.path == '/manager/invoices') this.selectedPage = 'invoices';
        if(this.$route.path == '/manager/account') this.selectedPage = 'account';
    },
    computed:{
        users(){
            return this.$store.state.users;
        },
        payments(){
            return this.$route.path == '/manager/payments' ? this.$store.state.payments : [];
        },
        myAccount(){
            return this.$route.path == '/manager/account' ? this.$store.state.myAccount : {};
        }
    },
    methods: {
        // async getData(){
        // //await this.$store.dispatch('loadCustomers');
        //     this.formData();
        // },
        async formData(){
            this.table1.data = [];
            this.listUsers = [];
            this.users.forEach(user => {
                let use = {
                    _id : (user._id) ?  user._id : '',
                    fullname : (user.fullname) ?  user.fullname : '',
                    email : (user.email) ?  user.email : '',
                    phone : (user.phone) ?  user.phone : ''
                };
                this.listUsers.push(use);
            });
            this.table1.data = [...this.listUsers];
        },
        selectPage(page){
            this.selectedPage = page;
        },
        notifyVue(verticalAlign, horizontalAlign, msg, type) {
            const color = Math.floor(Math.random() * 4 + 1);
            this.$notify({
                component: NotificationTemplate,
                message: msg,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: type, //this.type[color],
                timeout: 3000
            });
        },
        editUser(id){
            console.log('to edit customer',id);
            this.$router.push(`users/update/${id}`);
        },
        async deleteUser(id){
            if (confirm('Are you sure you want to delete this user?')) {
                let userId = id;
                let res = await this.$store.dispatch('deleteUser', userId);
                console.log('res delete user _______===>', res);
                if(res.status == 200){
                    this.notifyVue('bottom', 'center', 'The User has been deleted successfuly', 'success');
                    await this.$store.dispatch('loadUsers');
                    this.formData();
                }else this.notifyVue('bottom', 'center', 'Error delete User', 'danger');
            } else {
                // Do nothing!
                console.log('Cancel delete user ******.');
            }
            
        },
        newUser(){
            this.$router.push('users/create');
        },
    },
};
</script>

<style>

.globalTabs h3{
    font-size: 25px !important;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.4em !important;
}

.globalTabs  p {
    font-size: 14px;
    margin: 0 0 10px !important;
    font-weight: 300;
}

.globalTabs  small {
    font-size: 75%;
    color: #777;
    font-weight: 400;
}

.globalTabs .container .title{
    color: #3c4858;
    text-decoration: none;
    margin-top: 30px;
    margin-bottom: 25px;
    min-height: 32px;
}

.globalTabs .container .title h3{
    font-size: 25px;
    font-weight: 300;
}

.globalTabs div.card {
    border: 0;
    /* margin-bottom: 30px; */
    margin-top: 30px;
    border-radius: 6px;
    color: rgba(0,0,0,.87);
    background: #fff;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}

.globalTabs div.card.card-plain {
    background: transparent;
    box-shadow: none;
}

.globalTabs div.card .card-header {
    border-radius: 3px;
    padding: 1rem 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -30px;
    border: 0;
    background: linear-gradient(60deg,#eee,#bdbdbd);
}

.globalTabs .card-plain .card-header:not(.card-avatar) {
    margin-left: 0;
    margin-right: 0;
}

.globalTabs .div.card .card-body{
    padding: 15px 30px;
}

.globalTabs div.card .card-header-primary {
    background: linear-gradient(60deg,#ab47bc,#7b1fa2);
    box-shadow: 0 5px 20px 0 rgba(0,0,0,.2), 0 13px 24px -11px rgba(156,39,176,.6);
}

.globalTabs div.card .card-header-danger {
    /* background: linear-gradient(60deg,#ef5350,#d32f2f); */
    background: #27293d;
    box-shadow: 0 1px 20px 0px rgb(0 0 0 / 10%);
    /* box-shadow: 0 5px 20px 0 rgba(0,0,0,.2), 0 13px 24px -11px rgba(244,67,54,.6); */
}


.globalTabs .card-nav-tabs .card-header {
    margin-top: -30px!important;
}

.globalTabs .card .card-header .nav-tabs {
    padding: 0;
}

.globalTabs .nav-tabs {
    border: 0;
    border-radius: 3px;
    padding: 0 15px;
}

.globalTabs .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.globalTabs .nav-tabs .nav-item {
    margin-bottom: -1px;
}

.globalTabs .nav-tabs .nav-item .nav-link.active {
    background-color: hsla(0,0%,100%,.2);
    transition: background-color .3s .2s;
}

.globalTabs .nav-tabs .nav-item .nav-link{
    border: 0!important;
    color: #fff!important;
    font-weight: 500;
}

.globalTabs .nav-tabs .nav-item .nav-link {
    color: #fff;
    border: 0;
    margin: 0;
    border-radius: 3px;
    line-height: 24px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px 15px;
    background-color: transparent;
    transition: background-color .3s 0s;
}

.globalTabs .nav-link{
    display: block;
}

.globalTabs .nav-tabs .nav-item .material-icons {
    margin: -1px 5px 0 0;
    vertical-align: middle;
}

.globalTabs .nav .nav-item {
    position: relative;
}
.globalTabs footer{
    margin-top:100px;
    color: #555;
    background: #fff;
    padding: 25px;
    font-weight: 300;

}
.globalTabs .footer p{
    margin-bottom: 0;
    font-size: 14px;
    margin: 0 0 10px;
    font-weight: 300;
}
.globalTabs footer p a{
    color: #555;
    font-weight: 400;
}

.globalTabs footer p a:hover {
    color: #9f26aa;
    text-decoration: none;
}

.card-nav-tabs .card-header .nav-tabs-navigation .nav-tabs-wrapper .nav-tabs .nav-link{
  cursor: pointer;
}


.templates .reward-card{
    height: auto;
    min-height: 266px;
}

.chs-template{
    margin-bottom: 20px;
    max-height: 266px;
}

.chs-template .content-reward-card{
    box-shadow: 0 1px 20px 0px rgb(0 0 0 / 10%) !important;
    transition: .6s ease-out !important;
}

.chs-template .content-reward-card:hover{
    box-shadow: #ad5389 0px 0px 16px 1px !important;
    transform: translateY(15px) !important  ;
}

.chs-template.reward-card .reward-overlay{
    position: absolute !important;
    transition: all .7s ease !important;
    /* opacity: 0; */
    background-color: rgba(0, 0, 0, 0) !important;
}

.chs-template.reward-card:hover .reward-overlay{
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.chs-template.reward-card:hover .reward-overlay.all-overlay{
    height: 100%;
    width:100%;
    left: 0;
    top: 0;
    left: 0;
    bottom: 0;
}

.choosing-template-custmz{
    height: 200px;
}

.input-color{
    padding-right: 0;
    padding-left: 0;
    margin-top: 33px;
}

.file-img{
    margin-bottom: 25px;
}

.label-img{
    margin-bottom: 13px !important;
    display: block;
}

.content-btns{
    margin-left: auto;
    width: 30%;
    display: flex;
    justify-content: right;
    position: fixed;
    left: 67%;
    bottom: 3px;
}

.content-btns .btn-prev{
    margin-right: 20px;
}

.btn-add-payment{
    padding: 7px 10px;
    font-size: 0.875rem;
    /* line-height: 1.25rem; */
    border-radius: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    text-transform: none;
    border: 0;
    outline: 0;
    cursor: pointer;

    background-color: #ff9f43;
    color: #fff;
}

.item-p{
    width: 95%;
    border: 1px solid #e44cc4;
    color: #fff;
    padding: 14px;
}

.gr-clr{
    color: #28c76f;
}

.bl-clr{
    color: #5e97fa;
}

.white-clr{
    color: #ffffff;
}

.red-clr{
    color: #ea5455;
}

.orange-clr{
    color: #ff9f43;
}

.green-clr{
    color:#20c18d;
}

.blue-clr{
    color: #5c95fb;
}


</style>