import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dashboard.vue"); 
const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () => import(/* webpackChunkName: "common" */"@/pages/Notifications.vue");
const Icons = () => import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");
const Stores = () => import ("@/pages/Stores.vue");
const addStore = () => import("@/pages/store/addStore.vue");
const Rewards = () => import("@/pages/rewards.vue");
const addReward = () => import("@/pages/reward/addReward.vue");
const GiftCards = () => import("@/pages/giftcards.vue");
const AddGiftCard = () => import("@/pages/giftcard/addGiftCard.vue");
const Customers = () => import("@/pages/customers.vue");
const Test = () => import("@/components/Cards/Test.vue");
const Login = () => import("@/components/Login.vue");
const Signup = () => import("@/pages/signup.vue");
const customerForm = () => import("@/pages/customer/form.vue");
const Points = () => import("@/pages/points.vue");
const pointIntervalForm = () => import("@/pages/point/form.vue");
const Orders = () => import("@/pages/orders.vue");
const orderForm = () => import("@/pages/order/form.vue");
const Redeems = () => import("@/pages/redeems.vue");
const redeemForm = () => import("@/pages/redeem/form.vue");
const Manager = () => import("@/pages/manager.vue");
const addPayment = () => import("@/pages/payment/addPayment.vue");
const successPayment = () => import("@/pages/payment/success.vue");
const userForm = () => import("@/pages/users/form.vue");


const routes = [
  {
    path: "/",
    component: DashboardLayout,
    //redirect: "/dashboard",
    children: [
      {
        path: "/",
        name: "dashboard",
        component: Dashboard
      },
      {
        path: "/login",
        name: "login",
        component: Login
      },
      {
        path: "signup",
        name: "signup",
        component: Signup
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard
      },
      {
        path: "test",
        name: "test",
        component: Test
      },
      {
        path: "profile",
        name: "profile",
        component: Profile
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications
      },
      {
        path: "icons",
        name: "icons",
        component: Icons
      },
      {
        path: "maps",
        name: "maps",
        component: Maps
      },
      {
        path: "typography",
        name: "typography",
        component: Typography
      },
      {
        path: "table-list",
        name: "table-list",
        component: TableList
      },
      {
        path: "stores",
        name: "stores",
        component: Stores
      },
      {
        path: "stores/addStore",
        name: "addStore",
        component: addStore
      },
      {
        path: "/stores/update/:id",
        name: "stores-update",
        component: addStore
      },
      {
        path: "rewards",
        name: "rewards",
        component: Rewards
      },
      {
        path: "rewards/addReward",
        name: "addReward",
        component: addReward
      },
      {
        path: "/rewards/update/:id",
        name: "rewards-update",
        component: addReward
      },
      {
        path: "giftcards",
        name: "giftcards",
        component: GiftCards
      },
      {
        path: "giftcards/addGiftCard",
        name: "addGiftCard",
        component: AddGiftCard
      },
      {
        path: "customers",
        name: "customers",
        component: Customers
      },
      {
        path: "customers/create",
        name: "create-customer",
        component: customerForm
      },
      {
        path: "/customers/update/:id",
        name: "customers-update",
        component: customerForm
      },
      {
        path: "points",
        name: "points",
        component: Points
      },
      {
        path: "points/create",
        name: "create-point",
        component: pointIntervalForm
      },
      {
        path: "/points/update/:id",
        name: "points-update",
        component: pointIntervalForm
      },
      {
        path: "orders",
        name: "orders",
        component: Orders
      },
      {
        path: "orders/create",
        name: "create-order",
        component: orderForm
      },
      {
        path: "orders/update/:id",
        name: "orders-update",
        component: orderForm
      },
      {
        path: "redeems",
        name: "redeems",
        component: Redeems
      },
      {
        path: "redeems/update/:id",
        name: "redeems-update",
        component: redeemForm
      },
      {
        path: "manager",
        name: "manager",
        component: Manager
      },
      {
        path: "manager/payments",
        name: "payments",
        component: Manager
      },
      {
        path: "manager/users",
        name: "users",
        component: Manager
      },
      {
        path: "manager/invoices",
        name: "invoices",
        component: Manager
      },
      {
        path: "manager/account",
        name: "account",
        component: Manager
      },
      {
        path: "manager/payments/add",
        name: "addPayment",
        component: addPayment
      },
      {
        path: "manager/payments/success",
        name: "addPayment",
        component: successPayment
      },
      {
        path: "manager/users/create",
        name: "create-user",
        component: userForm
      },
      {
        path: "manager/users/update/:id",
        name: "users-update",
        component: userForm
      },
    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
