<template>
    <div style="width:100%; height: 100vh;" class="login-wrapper">
        <img class="lg-wave" src="@/assets/images/wave.png" >
        <div class="container-lg">
            <div class="lg-img">
                <img src="@/assets/images/undraw_gifts.svg">
            </div>
            <div class="login-container">
                <form @submit.prevent>
                    <img class="avatar" src="@/assets/images/undraw_profile.svg">
                    <h2>Welcome</h2>
                    <p class="error-msg">{{ userErrors.emailError }}</p>
                    <div id="username-cont" class="lg-input-div one">
                        <div class="lg-i">
                            <i class="fas fa-user"></i>
                        </div>
                        <div>
                            <h5>Email</h5>
                            <input @focus="addFocus('username-cont')" @blur="removeFocus('username-cont',email)" v-model="email" value="" class="lg-input" type="text">
                        </div>
                    </div>
                    <p class="error-msg">{{ userErrors.passwordError }}</p>
                    <div id="password-cont" class="lg-input-div two">
                        <div class="lg-i">
                            <i class="fas fa-lock"></i>
                        </div>
                        <div>
                            <h5>Password</h5>
                            <input @focus="addFocus('password-cont')" @blur="removeFocus('password-cont',password)" v-model="password" value="" class="lg-input" type="password">
                        </div>
                    </div>
                    <div class="d-flex">
                        <a class="lg-forget-pass" style="margin-right: 40%;" href="#">Forget password?</a>
                        <a class="lg-forget-pass" :href="signupUrl">SIGN UP</a>
                    </div>
                    <!-- <input @submit="submitLogin($event)" type="submit" class="lg-btn" value="Login"> -->
                    <button class="lg-btn" @click="submitLogin()" >Login</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import NotificationTemplate from '@/pages/Notifications/NotificationTemplate';

export default {
    components: { 
        NotificationTemplate
    },
    data(){
        return{
            isFocusUsername : false,
            email:'',
            password:'',
            userErrors:{
                passwordError: '',
                emailError: ''
            },
        }
    },
    computed: {
        signupUrl() {
            // Check the current hostname and return the appropriate signup URL
            return window.location.hostname === 'admin.rewardisfy.com'
                ? 'https://admin.rewardisfy.com/signup'
                : 'http://localhost:8080/signup';
        }
    },
    methods:{
        notifyVue(verticalAlign, horizontalAlign, msg, type) {
            const color = Math.floor(Math.random() * 4 + 1);
            this.$notify({
                component: NotificationTemplate,
                message: msg,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: type, //this.type[color],
                timeout: 3000
            });
        },
        addFocus(id){
            document.getElementById(id).classList.add('focus');
        },
        removeFocus(id,val){
            if(val == '') document.getElementById(id).classList.remove('focus');
        },
        async submitLogin(){
            this.userErrors.emailError = '';
            this.userErrors.passwordError = '';
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if(this.email !== '' && this.password !== '' && emailPattern.test(this.email) ){
                let user = {
                    email : this.email,
                    password : this.password
                }
                let res = await this.$store.dispatch('login',user);
                console.log('res submit login', res);
                if(res.success) this.$router.push('/dashboard');
                else if(!res.success){
                    console.log('error data',res);
                    alert(res.msg)
                    //this.notifyVue('bottom', 'center', res.msg , 'danger');
                }else{
                    alert('Error');
                    //this.notifyVue('bottom', 'center', 'Error' , 'danger');
                }
                
            }else{
                // this.notifyVue('bottom', 'center', 'Error', 'danger');
                if(this.email == '') this.userErrors.emailError = 'Please Enter a valid Email';
                if(this.password == '') this.userErrors.passwordError = 'Please Enter your Password';
                
            }
        }
    }
}
</script>

<style>
    .login-wrapper{
        background: rgb(255, 255, 255);
        position: relative;
        z-index: 1;
        /* height: 100%; */
    }
    .login-wrapper h1,.login-wrapper h2,.login-wrapper h3,.login-wrapper h4,.login-wrapper h5{
        color: #161617;
    }
    .login-wrapper h2{
        color: #161617;
    }
    .lg-wave{
        position: fixed;
        height: 100%;
        left: 0;
        bottom: 0;
        z-index: -1;
    }

    .container-lg{
        width: 100vw;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 7rem;
        padding: 0 2rem;
    }

    .lg-img{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .lg-img img{
        width: 500px;
    }

    .login-container{
        display: flex;
        align-items: center;
        text-align: center;
    }

    .login-container form{
        width: 360px;
    }

    .avatar{
        width: 100px;
    }

    .login-container form h2{
        font-size: 2.9rem;
        text-transform: uppercase;
        margin: 15px 0;
        color: #333;
    }

    .lg-input-div{
        position: relative;
        display: grid;
        grid-template-columns: 7% 93%;
        margin: 25px 0;
        padding: 5px 0;
        border-bottom: 2px solid #d9d9d9;
    }

    .lg-input-div::after, .lg-input-div::before{
        content: '';
        position: absolute;
        bottom: -2px;
        width: 0;
        height: 2px;
        background-color: #38d39f;
    }

    .lg-input-div::after{
        right: 50%;
    }

    .lg-input-div::before{
        left:50%
    }

    .lg-input-div.focus .lg-i i{
        color: #38d39f;
    }

    .lg-input-div.focus::after, .lg-input-div.focus::before{
        width: 50%;
    }

    .lg-input-div.focus div h5{
        top: -5px;
        font-size: 15px;
    }

    .lg-input-div.one{
        margin-top: 0;
    }

    .lg-input-div.two{
        margin-bottom: 4px;
    }

    .lg-i{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lg-i i{
        color: #d9d9d9;
    }

    .lg-input-div > div{
        position: relative;
        height: 45px;
    }

    .lg-input-div > div h5{
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #999;
        font-size: 18px;
        transition: .3s;
    }

    .lg-input-div input{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: none;
        outline: none;
        background: none;
        padding: 0.5rem 0.7rem;
        font-size: 1.2rem;
        color: #555;
    }

    .lg-forget-pass{
        display: block;
        text-align: right;
        text-decoration: none;
        color: #999;
        font-size: 0.9rem;
        transition: .3s;
    }

    .lg-forget-pass:hover{
        color: #38d39f;
    }

    .lg-btn{
        display: block;
        width: 100%;
        height: 50px;
        border-radius: 25px;
        margin: 1rem 0;
        font-size: 1.2rem;
        outline: none;
        border: none;
        background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
        color: #fff;
        text-transform: uppercase;
        background-size: 200%;
        transition: .5s;
        cursor: pointer;
    }

    .lg-btn:hover{
        background-position: right;
    }

    @media screen and (max-width:1050px){
        .container-lg{
            grid-gap: 5rem;
        }
    }

    @media screen and (max-width:1000px){
        .login-container form{
            width: 290px;
        }

        .login-container form h2{
            font-size: 2.4rem;
            margin: 8px 0;
        }

        .lg-img img{
            width: 400px;
        }
    }

    @media screen and (max-width:900px){
        .lg-img{
            display: none;
        }

        .container-lg{
            grid-template-columns: 1fr;
        }

        .lg-wave{
            display: none;
        }

        .login-container{
            justify-content: center;
        }
    }

    .error-msg{
        margin-bottom: 5px;
        font-size: 11px;
        color: #d74343 !important;
    }
    
</style>