<template>
    <div style="width:100%" class="login-wrapper">
        <img class="lg-wave" src="@/assets/images/wave.png" >
        <div class="container-lg">
            <div class="lg-img">
                <img src="@/assets/images/undraw_gifts.svg">
            </div>
            <div class="login-container">
                <form @submit.prevent >
                    <img class="avatar" src="@/assets/images/undraw_profile.svg">
                    <h2>Sign up</h2>
                    <p class="error-msg">{{ userErrors.fullnameError }}</p>
                    <div id="fullname-cont" class="lg-input-div one">
                        <div class="lg-i">
                            <i class="fas fa-user"></i>
                        </div>
                        <div>
                            <h5>Fullname</h5>
                            <input v-model="user.fullname" @focus="addFocus('fullname-cont')"  @blur="removeFocus('fullname-cont',user.fullname)"  value="" class="lg-input" type="text">
                        </div>
                    </div>
                    <p class="error-msg">{{ userErrors.emailError }}</p>
                    <div id="username-cont" class="lg-input-div one">
                        <div class="lg-i">
                            <i class="fa-solid fa-envelope"></i>
                        </div>
                        <div>
                            <h5>Email</h5>
                            <input v-model="user.email" @focus="addFocus('username-cont')" @blur="removeFocus('username-cont',user.email)"  value="" class="lg-input" type="email">
                        </div>
                    </div>
                    <p class="error-msg">{{ userErrors.passwordError }}</p>
                    <div id="password-cont" class="lg-input-div two">
                        <div class="lg-i">
                            <i class="fas fa-lock"></i>
                        </div>
                        <div>
                            <h5>Password</h5>
                            <input v-model="user.password" @focus="addFocus('password-cont')" @blur="removeFocus('password-cont',user.password)" value="" class="lg-input" type="password">
                        </div>
                    </div>
                    <p class="error-msg">{{ userErrors.phoneNumberError }}</p>
                    <div id="phone-cont" class="lg-input-div one">
                        <div class="lg-i">
                            <i class="fa-solid fa-phone"></i>
                        </div>
                        <div>
                            <!-- <h5>Phone number</h5> -->
                            <input v-model="user.phone" id="phone" @focus="addFocus('phone-cont')" @blur="removeFocus('phone-cont',user.phone)"  value="" class="lg-input" type="text">
                        </div>
                    </div>
                    <p class="error-msg">{{ storeErrors.storeNameError }}</p>
                    <div id="storename-cont" class="lg-input-div one">
                        <div class="lg-i">
                            <i class="fa-solid fa-store"></i>
                        </div>
                        <div>
                            <h5>Store name</h5>
                            <input v-model="store.name" @focus="addFocus('storename-cont')" @blur="removeFocus('storename-cont',store.name)"   value="" class="lg-input" type="text">
                        </div>
                    </div>

                    <p class="error-msg">{{ storeErrors.storeSubdomainError }}</p>
                    <span><small>Enter your store's name in this field to create your store's link</small></span>
                    <div id="storeurl-cont" class="lg-input-div one">
                        <div class="lg-i">
                            <i class="fa-solid fa-link"></i>
                        </div>
                        <div style="position: relative;">
                            <!-- <h5>Store URL </h5> -->
                            <span class="https-part">https://</span>
                            <input v-model="storeurl" @focus="addFocus('storeurl-cont')" @blur="removeFocus('storeurl-cont',store.url)"   value="" class="lg-input" type="text">
                            <span class="subdomain-url">.rewrdisfy.com</span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <!-- <a class="lg-forget-pass" style="margin-right: 40%;" href="#">Forget password?</a> -->
                        <a class="lg-forget-pass" :href="loginUrl">SIGN IN</a>
                    </div>
                    <!-- <input @submit="submitLogin($event)" type="submit" class="lg-btn" value="Login"> -->
                    <button @click="register()" class="lg-btn" >Sign up</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import utilsScript from 'intl-tel-input/build/js/utils.js';
import NotificationTemplate from '@/pages/Notifications/NotificationTemplate';

export default {
    components:{
        NotificationTemplate
    },
    data(){
        return{
            isFocusUsername : false,
            user:{
                accountId:'',
                //teamId: '',
                email:'',
                password:'',
                fullname:'',
                // storename:'',
                // storeurl:'',
                phone:'',
                dialCode: '',
                role: 'ADMIN',
                stores:[]
            },
            account:{
                isTrial : true,
                pack: 'STARTER',
                status: 'TRIAL',
                subscription: 'MONTHLY',
                trialDays:7
            },
            store:{
                name: '',
                url: '',
                address: '',
                country: '',
                city: '',
                users: []
            },
            team:{
                name: ''
            },
            email:'',
            password:'',
            fullname:'',
            storename:'',
            storeurl:'',
            phone:'',
            userErrors:{
                fullnameError: '',
                passwordError: '',
                emailError: '',
                phoneNumberError:'',
            },
            storeErrors:{
                storeNameError: '',
                storeSubdomainError: ''
            }
        }
    },
    mounted(){
        const input = document.querySelector("#phone");
        this.iti = intlTelInput(input, {
            //initialCountry: "auto",
            utilsScript: utilsScript,
            nationalMode: true 
        }); 
        // input.placeholder = '';
    },  
    beforeDestroy() {
        if (this.iti) {
            this.iti.destroy(); // Clean up on component destroy
        }
    },
    watch: {
        'store.name': function (newName) {
            this.storeurl = newName.trim().replace(/\s+/g, '-').toLowerCase();;
        },
        'storeurl': function (newUrl) {
            const formattedUrl = newUrl.trim().replace(/\s+/g, '-').toLowerCase();;
            if (formattedUrl !== '') {
                this.store.url = `https://${formattedUrl}.rewardisfy.com`;
            }
        },
        
    },
    computed:{
        loginUrl() {
            // Check the current hostname and return the appropriate signup URL
            return window.location.hostname === 'admin.rewardisfy.com'
                ? 'https://admin.rewardisfy.com/login'
                : 'http://localhost:8080/login';
        }
    },
    methods:{
        notifyVue(verticalAlign, horizontalAlign, msg, type) {
            const color = Math.floor(Math.random() * 4 + 1);
            this.$notify({
                component: NotificationTemplate,
                message: msg,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: type, //this.type[color],
                timeout: 3000
            });
        },
        addFocus(id){
            document.getElementById(id).classList.add('focus');
        },
        removeFocus(id,val){
            if(val == '') document.getElementById(id).classList.remove('focus');
        },
        async register(){
            this.userErrors.fullnameError = '';
            this.userErrors.passwordError = ''; 
            this.userErrors.emailError = '';
            this.userErrors.phoneNumberError = '';
            this.storeErrors.storeNameError = '';
            this.storeErrors.storeSubdomainError = '';
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
           
            if(this.user.phone !== '' && this.user.email !== '' &&
             this.user.password !=='' && this.user.fullname !== '' && this.user.phone !== '' 
            && this.store.name !== '' && this.store.url !== '' && emailPattern.test(this.user.email) 
            && this.iti.isValidNumber()){
                let subdomain = this.store.url.toLowerCase();
                let resFindStore = await this.$store.dispatch('findStore',subdomain);
                if(resFindStore.data.message == 'store not found'){
                    let account = this.account;
                    let res = await this.$store.dispatch('createAccount',account);
                    if(res.status == 200 || res.status == 201){
                        this.user.accountId = res.data._id;
                        this.user.dialCode = this.iti.getSelectedCountryData().iso2;
                        let formatPhone = this.user.phone.replace(/\D/g, '');
                        this.user.phone = formatPhone;
                        let user = this.user;
                        let resUser = await this.$store.dispatch('createUser',user);
                        console.log('resUser in signup comp ====>',resUser);
                        if(resUser.status == 200 || resUser.status == 201){ 
                            this.store.users.push(resUser.data.result._id);
                            let formatUrl = this.store.url.trim().replace(/\s+/g, '-').toLowerCase();;
                            this.store.url = formatUrl;
                            let store = this.store
                            let resStore = await this.$store.dispatch('addNewStore',store);
                            if(resStore.status == 200 || resStore.status == 201){
                                let userUpd = {
                                    stores : []
                                };
                                userUpd.stores.push(resStore.data._id);
                                let id = resUser.data.result._id;
                                let resUpdateUser = await this.$store.dispatch('updateUser',{id,userUpd});
                                if(resUpdateUser.status == 200 || resUpdateUser.status == 201){
                                    alert('Your account created successfully');
                                    setTimeout(function() {
                                        console.log("Wait");
                                    }, 3000);
                                    //this.$router.push('/login');
                                    if (window.location.hostname === 'admin.rewardisfy.com') {
                                        window.location.replace("https://admin.rewardisfy.com/login");
                                    } else {
                                        window.location.replace("http://localhost:8080/login");
                                    }
                                }else{
                                    this.notifyVue('bottom', 'center', 'Error', 'danger');
                                    alert('Error in user store');
                                }
                            }else{
                                this.notifyVue('bottom', 'center', 'Error', 'danger');
                                alert('Error when creating the store');
                            }
                                
                        }else{
                            this.userErrors.emailError = resUser.data.msg
                            this.notifyVue('bottom', 'center', 'Error', 'danger');
                        }
                        
                    }else{
                        this.notifyVue('bottom', 'center', 'Error', 'danger');
                        alert('Error when creating the account');
                    }
                }else{
                    this.storeErrors.storeSubdomainError = 'This Store url already exist!! try to enter another name';
                }
                
            }else{
                this.notifyVue('bottom', 'center', 'Error', 'danger');
                if(this.user.fullname == '') this.userErrors.fullnameError = 'Please enter your fullname';
                if(this.user.email == '') this.userErrors.emailError = 'Please enter your email';
                if(this.user.phone == '') this.userErrors.phoneNumberError = 'Please enter your phone number';
                if(this.user.email !== '' && !emailPattern.test(this.user.email)) this.userErrors.emailError = 'Please enter a valid email address';
                if (!this.iti.isValidNumber()) {
                    this.userErrors.phoneNumberError = "Please enter a valid phone number.";
                }
                if(this.user.password == '') this.userErrors.passwordError = 'Please enter a valid password';
                if(this.store.name == '') this.storeErrors.storeNameError = "Please enter your store's name";
                if(this.store.url == '') this.storeErrors.storeSubdomainError = "Please enter your store's name in this field to create your store's link";
            }
        }
    }
}
</script>

<style>
    
    .login-wrapper{
        background: rgb(255, 255, 255);
        position: relative;
        z-index: 1;
        /* height: 100%; */
    }
    .login-wrapper h1,.login-wrapper h2,.login-wrapper h3,.login-wrapper h4,.login-wrapper h5{
        color: #161617;
    }
    .login-wrapper h2{
        color: #161617;
    }
    .lg-wave{
        position: fixed;
        height: 100%;
        left: 0;
        bottom: 0;
        z-index: -1;
    }

    .container-lg{
        width: 100vw;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 7rem;
        padding: 0 2rem;
    }

    .lg-img{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .lg-img img{
        width: 500px;
    }

    .login-container{
        display: flex;
        align-items: center;
        text-align: center;
    }

    .login-container form{
        width: 360px;
    }

    .avatar{
        width: 100px;
    }

    .login-container form h2{
        font-size: 2.9rem;
        text-transform: uppercase;
        margin: 15px 0;
        color: #333;
    }

    .lg-input-div{
        position: relative;
        display: grid;
        grid-template-columns: 7% 93%;
        margin: 25px 0;
        padding: 5px 0;
        border-bottom: 2px solid #d9d9d9;
    }

    .lg-input-div::after, .lg-input-div::before{
        content: '';
        position: absolute;
        bottom: -2px;
        width: 0;
        height: 2px;
        background-color: #38d39f;
    }

    .lg-input-div::after{
        right: 50%;
    }

    .lg-input-div::before{
        left:50%
    }

    .lg-input-div.focus .lg-i i{
        color: #38d39f;
    }

    .lg-input-div.focus::after, .lg-input-div.focus::before{
        width: 50%;
    }

    .lg-input-div.focus div h5{
        top: -5px;
        font-size: 15px;
    }

    .lg-input-div.one{
        margin-top: 0;
    }

    .lg-input-div.two{
        margin-bottom: 4px;
    }

    .lg-i{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lg-i i{
        color: #d9d9d9;
    }

    .lg-input-div > div{
        position: relative;
        height: 45px;
    }

    .lg-input-div > div h5{
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #999;
        font-size: 18px;
        transition: .3s;
    }

    .lg-input-div input{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: none;
        outline: none;
        background: none;
        padding: 0.5rem 0.7rem;
        font-size: 1.2rem;
        color: #555;
    }

    .lg-forget-pass{
        display: block;
        text-align: right;
        text-decoration: none;
        color: #999;
        font-size: 0.9rem;
        transition: .3s;
    }

    .lg-forget-pass:hover{
        color: #38d39f;
    }

    .lg-btn{
        display: block;
        width: 100%;
        height: 50px;
        border-radius: 25px;
        margin: 1rem 0;
        font-size: 1.2rem;
        outline: none;
        border: none;
        background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
        color: #fff;
        text-transform: uppercase;
        background-size: 200%;
        transition: .5s;
        cursor: pointer;
    }

    .lg-btn:hover{
        background-position: right;
    }

    @media screen and (max-width:1050px){
        .container-lg{
            grid-gap: 5rem;
        }
    }

    @media screen and (max-width:1000px){
        .login-container form{
            width: 290px;
        }

        .login-container form h2{
            font-size: 2.4rem;
            margin: 8px 0;
        }

        .lg-img img{
            width: 400px;
        }
    }

    @media screen and (max-width:900px){
        .lg-img{
            display: none;
        }

        .container-lg{
            grid-template-columns: 1fr;
        }

        .lg-wave{
            display: none;
        }

        .login-container{
            justify-content: center;
        }
    }

    .subdomain-url{
        position: absolute;
        right: -33%;
        top: 15px;
    }

    .https-part{
        position: absolute;
        left: -25%;
        top: 15px;
    }

    .error-msg{
        margin-bottom: 5px;
        font-size: 11px;
        color: #d74343 !important;
    }
    
</style>