<template>
  <div>
    <div class="filters-bar">
      <div>
        <div class="d-flex ml-auto align-items-center str-filters">
          <span>Select Store :</span>
          <base-input class="base-input">
            <select style="cursor:pointer;" v-model="selectedStore" class="form-control" id="exampleFormControlSelect1">
              <option value="all">All</option>
              <option :value="store._id" v-for="(store,index) in stores" :key="index">{{store.name}}</option>
            </select>
          </base-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card title="Prices Ranges with their points">
          <div class="d-flex actions-bar">
            <base-button @click="newPointInterval()" class="ml-auto" type="primary" >New Point</base-button>
          </div>
          <div class="table-responsive">
            <base-table :data="table1.data"
                        :columns="table1.columns"
                        v-on:emitToEdit="editPoints($event)"
                        v-on:emitToDelete="deletePoints($event)"
                        thead-classes="text-primary">
            </base-table>
            <Pagination
              :currentPage="pagination.currentPage"
              :totalPages="pagination.totalPages"
              :totalRecords="pagination.totalRecords"
              @updatePage="handlePageChange"
            />
          </div>
        </card>
      </div>
    </div>
  </div>

</template>
<script>
import { BaseTable } from "@/components";
import Pagination from "@/components/Pagination.vue";
import NotificationTemplate from '@/pages/Notifications/NotificationTemplate';

const tableColumns = ["minprice","maxprice", "points", "store", "actions"];
const tableData = [
  {
    id: 1,
    name: "Dakota Rice",
    email: "$36.738",
    phone: "Niger",
    points: "Oud-Turnhout",
  },
  {
    id: 2,
    name: "Minerva Hooper",
    email: "$23,789",
    phone: "Curaçao",
    points: "Sinaai-Waas"
  },
  {
    id: 3,
    name: "Sage Rodriguez",
    email: "$56,142",
    phone: "Netherlands",
    points: "Baileux"
  },
  {
    id: 4,
    name: "Philip Chaney",
    email: "$38,735",
    phone: "Korea, South",
    points: "Overland Park"
  },
  {
    id: 5,
    name: "Doris Greene",
    email: "$63,542",
    phone: "Malawi",
    points: "Feldkirchen in Kärnten"
  },
  {
    id: 6,
    name: 'Mason Porter',
    email: '$98,615',
    phone: 'Chile',
    points: 'Gloucester'
  },
  {
    id: 7,
    name: 'Jon Porter',
    email: '$78,615',
    phone: 'Portugal',
    points: 'Gloucester'
  }
];

export default {
    components: {
      BaseTable,
      NotificationTemplate,
      Pagination
    },
    data() {
        return {
          page:1,
          limit:10,
          pagination: {
            currentPage: 1,
            totalPages: 1,
            totalRecords: 0
          },
          table1: {
              title: "Simple Table",
              columns: [...tableColumns],
              data:  [] //[...tableData]
          },
          table2: {
              title: "Table on Plain Background",
              columns: [...tableColumns],
              data: [...tableData]
          },
          pointsInterval:[],
          selectedStore: '',
          
        };
    },
    async mounted() {
      await this.$store.dispatch('loadStores');
      let selectedStoreIndex = localStorage.getItem("selectedStoreIndex");
      console.log('selected store index_____',selectedStoreIndex);
      if(selectedStoreIndex == 'all') this.selectedStore = 'all';
      else this.selectedStore = this.stores[selectedStoreIndex]._id;
      //await this.getData();
    },
    computed:{
      stores(){
        return this.$store.state.stores 
      },
      points(){
        return this.$store.state.points;
      },
      points_info(){
        return this.$store.state.points_info;
      }
    },
    watch:{
      async selectedStore(newVal,oldVal){
        for (let index = 0; index < this.stores.length; index++) {
          const id_store = this.stores[index]._id;
          if(id_store == newVal) localStorage.setItem("selectedStoreIndex", index);
          if(newVal == 'all') localStorage.setItem("selectedStoreIndex", 'all');
        }
        let storeId = newVal;
        await this.$store.dispatch('loadPoints',{
          storeId: storeId,
          page: this.page,
          limit: this.limit
        });
        this.getData();
      }
    },
    methods:{
      notifyVue(verticalAlign, horizontalAlign, msg, type) {
        const color = Math.floor(Math.random() * 4 + 1);
        this.$notify({
            component: NotificationTemplate,
            message: msg,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: type, //this.type[color],
            timeout: 3000
        });
      },
      editPoints(id){
        console.log('to edit point',id);
        this.$router.push(`/points/update/${id}`); 
      },
      async deletePoints(id){
        const res = await this.$store.dispatch('getPointInterval',id);
        const storeId = res.data.storeId._id;
        this.$swal({
          title: 'Are you sure?',
          text: 'Are you sure you want to delete these points',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed!',
          cancelButtonText: 'Cancel',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // User confirmed, perform the action
            let res = await this.$store.dispatch('removePoints',id);
            if(res.status == 200){
              this.notifyVue('bottom', 'center', 'Points deleted successfuly', 'success');
              await this.$store.dispatch('loadPoints',{
                storeId: storeId,
                page: this.page,
                limit: this.limit
              });
              this.getData();
            }else{
              this.notifyVue('bottom', 'center', 'Error delete Points', 'danger');
            }
          }
        });
      },
      newPointInterval(){
          this.$router.push('/points/create')
      },
      async getData(){
          //await this.$store.dispatch('loadPoints');
          this.formData();
      },
      formData(){
          this.table1.data = [];
          this.pointsInterval = [];
          this.points.forEach(point => {
            let p = {
                _id: point._id,
                minprice: point.minPrice,
                maxprice: point.maxPrice,
                points: point.points,
                store: point.storeId.name,
                storeId: point.storeId._id
            }
            this.pointsInterval.push(p);
          });
          this.table1.data = [...this.pointsInterval];
          this.pagination.currentPage = this.points_info.currentPage;
          this.pagination.totalPages = this.points_info.totalPages;
          this.pagination.totalRecords = this.points_info.totalRecords;
          console.log('table 1 points interval',this.table1);
      },
      async handlePageChange(page) {
        let storeId = this.selectedStore;
        await this.$store.dispatch('loadPoints',{
          storeId: storeId,
          page: page,
          limit: this.limit
        });
        this.formData();
      }
    }
  
};
</script>
<style>

.actions-bar{
  margin-bottom: 29px;
}

</style>
