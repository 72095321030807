<template>
  <div>
    <div class="filters-bar">
      <div>
        <div class="d-flex ml-auto align-items-center str-filters">
          <span>Select Store :</span>
          <base-input class="base-input">
            <select style="cursor:pointer;" v-model="selectedStore" class="form-control" id="exampleFormControlSelect1">
              <option value="all">All</option>
              <option :value="store._id" v-for="(store,index) in stores" :key="index">{{store.name}}</option>
            </select>
          </base-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card style="position: relative;" title="Requests to Redeem Rewards">
          <i class="fa-solid fa-filter fa-lg" @click="isFilters = !isFilters"
          style="color: #e14eca; position: absolute; position: absolute;
          right: 2%;
          top: 7%;
          cursor: pointer;">
             </i>
          <div v-if="isFilters" class="form">
            <div class="row">
              <div class="col-8">
                <base-input label="Search" v-model="searchQuery" type="text" placeholder="name, email, phone number, reward name"/>
              </div>
              <div class="col-4 pt-3">
                <base-button @click="searchBy()" simple type="primary">Apply</base-button>
              </div>
            </div>
          </div>
          <div v-if="false" class="d-flex actions-bar">
            <base-button  class="ml-auto" type="primary" >New Reddem requeest</base-button>
          </div>
          <div class="table-responsive">
            <base-table v-if="table1.data && table1.data.length > 0" :data="table1.data"
                        :columns="table1.columns"
                        v-on:emitChangeStatus="editStatus($event)"
                        @emitToEdit="editRedeem($event)"
                        @emitToDelete="deleteRedeem($event)"
                        thead-classes="text-primary">
            </base-table>
            <Pagination
              :currentPage="pagination.currentPage"
              :totalPages="pagination.totalPages"
              :totalRecords="pagination.totalRecords"
              @updatePage="handlePageChange"
            />
          </div>
        </card>
      </div>
    </div>
  </div>

</template>
<script>
import { BaseTable } from "@/components";
import NotificationTemplate from '@/pages/Notifications/NotificationTemplate';
import Pagination from "@/components/Pagination.vue";
const tableColumns = ["firstname","lastname", "email", "phone", "reward", "points", "status", "actions"];

const tableData = [
  {
    id: 1,
    name: "Dakota Rice",
    email: "$36.738",
    phone: "Niger",
    points: "Oud-Turnhout",
  },
  {
    id: 2,
    name: "Minerva Hooper",
    email: "$23,789",
    phone: "Curaçao",
    points: "Sinaai-Waas"
  },
  {
    id: 3,
    name: "Sage Rodriguez",
    email: "$56,142",
    phone: "Netherlands",
    points: "Baileux"
  },
  {
    id: 4,
    name: "Philip Chaney",
    email: "$38,735",
    phone: "Korea, South",
    points: "Overland Park"
  },
  {
    id: 5,
    name: "Doris Greene",
    email: "$63,542",
    phone: "Malawi",
    points: "Feldkirchen in Kärnten"
  },
  {
    id: 6,
    name: 'Mason Porter',
    email: '$98,615',
    phone: 'Chile',
    points: 'Gloucester'
  },
  {
    id: 7,
    name: 'Jon Porter',
    email: '$78,615',
    phone: 'Portugal',
    points: 'Gloucester'
  }
];

export default {
    components: {
        BaseTable,
        NotificationTemplate,
        Pagination
    },
    data() {
      return {
        isFilters:false,
        searchQuery: '',
        page:1,
        limit:10,
        pagination: {
          currentPage: 1,
          totalPages: 1,
          totalRecords: 0
        },
        table1: {
            title: "Simple Table",
            columns: [...tableColumns],
            data: [] //[...tableData],
        },
        table2: {
            title: "Table on Plain Background",
            columns: [...tableColumns],
            data: [...tableData]
        },
        selectedStore: '',
        redeemsList: []
      };
    },
    async mounted() {
      await this.$store.dispatch('loadStores');
      let selectedStoreIndex = localStorage.getItem("selectedStoreIndex");
      console.log('selected store index_____',selectedStoreIndex);
      if(selectedStoreIndex == 'all') this.selectedStore = 'all';
      else this.selectedStore = this.stores[selectedStoreIndex]._id;
    },
    computed:{
      stores(){
        return this.$store.state.stores 
      },
      redeems(){
        return this.$store.state.redeems
      },
      redeems_info(){
        return this.$store.state.redeems_info;
      }
    },
    watch:{
      async selectedStore(newVal,oldVal){
        for (let index = 0; index < this.stores.length; index++) {
          const id_store = this.stores[index]._id;
          if(id_store == newVal) localStorage.setItem("selectedStoreIndex", index);
          if(newVal == 'all') localStorage.setItem("selectedStoreIndex", 'all');
        }
        let storeId = newVal;
        await this.$store.dispatch('loadRedeemRequests', {
          page: this.page,
          limit: this.limit,
          storeId: storeId,
          searchQuery: this.searchQuery  // assuming searchQuery is the search input
        });
        this.getData();
      }
    },
    methods:{
      notifyVue(verticalAlign, horizontalAlign, msg, type) {
        const color = Math.floor(Math.random() * 4 + 1);
        this.$notify({
            component: NotificationTemplate,
            message: msg,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: type, //this.type[color],
            timeout: 3000
        });
      },
      async searchBy(){
        let storeId = this.selectedStore;
        await this.$store.dispatch('loadRedeemRequests', {
          page: this.page,
          limit: this.limit,
          storeId: storeId,
          searchQuery: this.searchQuery  // assuming searchQuery is the search input
        });
        this.getData();
      },
      async getData(){
        this.formData();
      },
      formData(){
        this.table1.data = [];
        this.redeemsList = [];
        if(this.redeems.length > 0){
          this.redeems.forEach(redeem => {
            let r = {
              _id: redeem._id,
              firstname:  (redeem.customerId && redeem.customerId.firstname ) ?  redeem.customerId.firstname : '',
              lastname: (redeem.customerId && redeem.customerId.lastname ) ?  redeem.customerId.lastname : '',
              email: (redeem.customerId && redeem.customerId.email ) ?  redeem.customerId.email : '',
              phone: (redeem.customerId && redeem.customerId.phone ) ? redeem.customerId.phone: '',
              reward: (redeem.rewardId && redeem.rewardId.name ) ? redeem.rewardId.name: '',
              points: (redeem.rewardId && redeem.rewardId.points) ? redeem.rewardId.points:'' ,
              status: (redeem && redeem.status) ? redeem.status: ''
            }
            this.redeemsList.push(r);
          });
          this.table1.data = [...this.redeemsList];
          this.pagination.currentPage = this.redeems_info.currentPage;
          this.pagination.totalPages = this.redeems_info.totalPages;
          this.pagination.totalRecords = this.redeems_info.totalRecords;
        }
        console.log('table 1 redeems list :::',this.table1);
      },
      async handlePageChange(page) {
        let storeId = this.selectedStore;
        await this.$store.dispatch('loadRedeemRequests',{
          storeId: storeId,
          searchQuery: this.searchQuery,        
          page: page,
          limit: this.limit
        });
        this.formData();
      },
      async editStatus({id, status,item}){
        console.log('id :'+id,'status :',status, 'item:',item);
        let redeemObject = null;
        this.redeems.forEach(redeem => {
          if(redeem._id == id){
            redeemObject = redeem;
          }
        });
        let data = {
          status : status,
          customerId: redeemObject.customerId._id, //"63982b6dbb75f998a0253a7e",
          storeId: redeemObject.rewardId.storeId, //"639722485080a4c9ba668b95",
          rewardId: redeemObject.rewardId._id //"63972c9d490a0709811beb92"
        }
        if (confirm("Do you relly want to update status") == true) {
         let res = await this.$store.dispatch('updateRedeem',{id,data});
         console.log('COMP: res update redeem:::', res);
         if(res.status == 200) alert('Status upadate successfully');
         else alert('Error update status');
        }else{
          console.log('Canceled upadte status');
        }
      },
      editRedeem(id){
        console.log('to edit redeem',id);
        this.$router.push(`/redeems/update/${id}`);
      },
      async deleteRedeem(id){
        this.$swal({
          title: 'Are you sure?',
          text: 'Are you sure you want to delete this Redeem',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed!',
          cancelButtonText: 'Cancel',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // User confirmed, perform the action
            let res = await this.$store.dispatch('removeRedeem',id);
            if(res.status == 200){
              this.notifyVue('bottom', 'center', 'Redeem deleted successfuly', 'success');
              await this.$store.dispatch('loadRedeemRequests', {
                page: this.page,
                limit: this.limit,
                storeId: this.selectedStore,
                searchQuery: this.searchQuery  // assuming searchQuery is the search input
              });
              this.getData();
            }else{
              this.notifyVue('bottom', 'center', 'Error delete Redeem', 'danger');
            }
          }
        });
      },
    }
};
</script>
<style>

.actions-bar{
  margin-bottom: 29px;
}

</style>
