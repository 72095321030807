<template>
  <div class="newStroe row">
    <div class="col-sm-4">
      <h4>
        <span v-if="!isEdit">Add new Order</span>
        <span v-if="isEdit">Edit Order</span>
      </h4>
    </div> 
    <div class="col-sm-8">
      <card>
        <form @submit.prevent>
            <base-input label="phone number"
                        type="text"
                        disabled="disabled"
                        v-model="phone"
                        placeholder="phone number"
                        >
            </base-input>
            <base-input label="Firstname"
                        type="text"
                        disabled="disabled"
                        v-model="firstname"
                        placeholder="firstname"
                        >
            </base-input>
            <base-input label="Lastname"
                        type="text"
                        disabled="disabled"
                        v-model="lastname"
                        placeholder="lastname"
                        >
            </base-input>
            <div>
                <base-input label="Select Store :" class="base-input">
                    <select v-model="order.storeId" style="cursor:pointer;" class="form-control order-stores" id="exampleFormControlSelect1">
                      <option :value="store._id" v-for="(store,index) in stores" :key="index">{{store.name}}</option>
                    </select>
                </base-input>
            </div>
            <base-input label="price"
                        type="number"
                        v-model="order.price"
                        placeholder="price"
                        >
            </base-input>
            <base-button native-type="submit" @click="saveOrder()" type="primary">Save</base-button>
        </form>
      </card>
  </div>
  </div>
</template>

<script>

import NotificationTemplate from '@/pages/Notifications/NotificationTemplate'; 

export default {
  components:{
    NotificationTemplate
  },
  data(){
    return{
      isEdit: false,
      phone:'',
      firstname:'',
      lastname:'',
      order:{
        customerId:'',
        storeId:'',
        price:0,
        status: 'CREATED',
        currency:'USD'
      }
    }
  },
  async mounted(){
    await this.$store.dispatch('loadStores');
    if(this.$route.params.hasOwnProperty('id')) this.isEdit = true;
    if(!this.isEdit) {
      let selectedStoreIndex = localStorage.getItem("selectedStoreIndex");
      if(selectedStoreIndex == 'all') this.order.storeId = this.stores[0]._id;
      else this.order.storeId = this.stores[selectedStoreIndex]._id;
    }
    if(this.isEdit) this.setData();
  },
  computed:{
    stores(){
      let myStores = [];
      if(this.isEdit){
        this.$store.state.stores.forEach(store => {
          if(this.order.storeId == store._id) myStores.push(store);
        });
        return myStores;
      }else{
        return this.$store.state.stores 
      }
    }
  },
  methods:{
    notifyVue(verticalAlign, horizontalAlign, msg, type) {
      const color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
          component: NotificationTemplate,
          message: msg,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: type, //this.type[color],
          timeout: 3000
      });
    },
    async setData(){
      const id = this.$route.params.id;
      const res = await this.$store.dispatch('getOrder',id);
      console.log('res setData',res);
      this.order.storeId = res.data.storeId._id;
      this.order.customerId = res.data.customerId._id;
      this.phone = res.data.customerId.phone;
      this.firstname = res.data.customerId.firstname;
      this.lastname = res.data.customerId.lastname;
      this.order.price = res.data.price;
      this.order.status = res.data.status;
      this.order.currency = res.data.currency;
    },
    async createOrder(){
      if(this.phone !=''){
        let resCustomer = await this.$store.dispatch('customerQuery', this.phone);
        if(resCustomer.status == 200){
          this.order.customerId = resCustomer.data._id;
          const order = this.order;
          let res = await this.$store.dispatch('createNewOrder', order);
          if(res.status == 200) this.notifyVue('bottom', 'center', 'Order created successfuly', 'success');
          else this.notifyVue('bottom', 'center', 'Error create order', 'danger');
        }else this.notifyVue('bottom', 'center', 'Error create order', 'danger');
      }else this.notifyVue('bottom', 'center', 'Phone number cannot be empty', 'danger');
    },
    async updateOrder(){
      const id = this.$route.params.id;
      const order = this.order;
      let resOrder = await this.$store.dispatch('updateTheOrder', {id,order});
      if(resOrder.status == 200) this.notifyVue('bottom', 'center', 'Order updated successfuly', 'success');
      else this.notifyVue('bottom', 'center', 'Error update order', 'danger');
    },
    saveOrder(){
      if(!this.isEdit) this.createOrder();
      else this.updateOrder();
    },

  }
}
</script>

<style>
  .order-stores option{
    color: #000;
  }
</style>