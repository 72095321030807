<template>
<div>
  <div class="filters-bar">
    <div>
      <div class="d-flex ml-auto align-items-center str-filters">
        <span>Select Store :</span>
          <base-input class="base-input">
            <select style="cursor:pointer;" v-model="selectedStore" class="form-control" id="exampleFormControlSelect1">
              <option value="all">All</option>
              <option :value="store._id" v-for="(store,index) in stores" :key="index">{{store.name}}</option>
            </select>
          </base-input>
      </div>
    </div>
  </div>
  <div class="stores d-flex" style="height: 206px;">
    <card class="card-create-reward" style="width: 32%; height:100%">
      <router-link  :to="{ path: 'rewards/addReward' }">
        <div class="text-center thisCard" >
          <svg class="icon-to-create-reward" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M190.5 68.8L225.3 128l-1.3 0-72 0c-22.1 0-40-17.9-40-40s17.9-40 40-40l2.2 0c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40L32 128c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l448 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32l-41.6 0c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88l-2.2 0c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0L152 0C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40l-72 0-1.3 0 34.8-59.2C329.1 55.9 342.9 48 357.8 48l2.2 0c22.1 0 40 17.9 40 40zM32 288l0 176c0 26.5 21.5 48 48 48l144 0 0-224L32 288zM288 512l144 0c26.5 0 48-21.5 48-48l0-176-192 0 0 224z"/></svg>
          <!-- <i class="tim-icons icon-bag-16 icon-bag-40 mb-3 mb-icn icn-pointer"></i> -->
          <div class="d-flex justify-content-center">
            <i class="tim-icons tim-icons-16 icon-simple-add this-mt"></i>
            <h4  class="card-title title-new">Create new Reward</h4>
          </div>
        </div>
      </router-link >
    </card>
    <div v-if="false" class="reward-card">
      <!-- <h2>Reward Card</h2> -->
      <div class="content-reward-card">
        <div class="header-reward-card header-reward-coffe d-flex">
          <h3>6 Points</h3>
          <base-input class="base-input">
            <select class="form-control" id="exampleFormControlSelect1">
              <option>Actions</option>
              <option>Edit</option>
              <option>Delete</option>
              <option>View</option>
            </select>
          </base-input>
        </div>
        <img src="@/assets/images/coffe.jpg">
        <div class="footer-rward-card d-flex">
          <h3>Coffe</h3>
          <div @click="toggleClass('toggle1')" id="toggle1" class="toggle-btn activated-reward">
            <div class="inner-circle"> </div>
          </div>
        </div>
      </div>
      <div class="reward-overlay topOverlay">
        <h3>Free Coffe</h3>
      </div>
      <div class="reward-overlay leftOverlay">
        <!-- <h3></h3> -->
      </div>
      <div class="reward-overlay rightOverlay">
        <!-- <h3></h3> -->
      </div>
      <div class="reward-overlay bottomOverlay">
        <p>Points : <span class="point-nubmber">6</span></p>
      </div>
    </div>
    <div v-if="false" class="reward-card">
      <div class="content-reward-card">
        <div class="header-reward-card d-flex">
          <base-input class="base-input">
            <select class="form-control" id="exampleFormControlSelect1">
              <option>Actions</option>
              <option>Edit</option>
              <option>Delete</option>
              <option>View</option>
            </select>
          </base-input> 
        </div>
        <img src="@/assets/images/burger.jpg">
        <div class="footer-rward-card footer-reward-card-burger d-flex pdng-r pdng-l pdng-t pdng-b">
          <div class="footer-reward-texts">
            <h3>Burger</h3>
            <p>24 Points</p>
          </div>
          <div @click="toggleClass('toggle2')" id="toggle2" class="toggle-btn activated-reward">
            <div class="inner-circle"> </div>
          </div>
        </div>
      </div>
      <div class="reward-overlay topOverlay">
        <h3>Free Burger</h3>
      </div>
      <div class="reward-overlay leftOverlay">
        <!-- <h3></h3> -->
      </div>
      <div class="reward-overlay rightOverlay">
        <!-- <h3></h3> -->
      </div>
      <div class="reward-overlay bottomOverlay">
        <p>Points : <span class="point-nubmber">24</span></p>
      </div>
    </div>
    <div v-if="false" class="reward-card">
      <div class="content-reward-card content-headphones-reward-card d-flex">
        <div class="left-content-reward">
          <div class="header-left-content-reward">
            <h3>Headphones</h3>
          </div>
          <div class="footer-left-content-reward">
            <p>100 Points</p>
          </div>
        </div>
        <div class="right-content-reward">
          <div class="header-right-content-reward">
            <base-input class="base-input">
            <select class="form-control" id="exampleFormControlSelect1">
              <option>Actions</option>
              <option>Edit</option>
              <option>Delete</option>
              <option>View</option>
            </select>
          </base-input>
          </div>
          <img src="@/assets/images/headphones.jpg">
          <div class="footer-right-content-reward">
            <div @click="toggleClass('toggle3')" id="toggle3" class="toggle-btn activated-reward">
              <div class="inner-circle"> </div>
            </div>
          </div>
        </div>
      </div>
      <div class="reward-overlay topOverlay">
        <h3>Free Headphones</h3>
      </div>
      <div class="reward-overlay leftOverlay">
        <!-- <h3></h3> -->
      </div>
      <div class="reward-overlay rightOverlay">
        <!-- <h3></h3> -->
      </div>
      <div class="reward-overlay bottomOverlay">
        <p>Points : <span class="point-nubmber">24</span></p>
      </div>
    </div>

    <div class="rewards-loop-comp" v-for="(rwd,index) in rewards" :key="index">
      <reward-template 
       :templateName="'COFFE'" :isCustomize="true" :isFromApi="true"
        v-if="rwd.templateId.route == 'COFFE'" :config="rwd.templateId.config" 
        :show="show" :tab="tab" :reward="rwd" :selectedStore="selectedStore" >
      </reward-template>

      <food-template 
      :templateName="'FOOD'" :isCustomize="true" :isFromApi="true" 
      v-if="rwd.templateId.route == 'FOOD'" :config="rwd.templateId.config" 
      :show="show" :tab="tab" :reward="rwd" :selectedStore="selectedStore" >
      </food-template>

      <electro-template
      :templateName="'ELECTRO'" :isCustomize="true" :isFromApi="true" 
      v-if="rwd.templateId.route == 'ELECTRO'" :config="rwd.templateId.config" 
      :show="show" :tab="tab" :reward="rwd" :selectedStore="selectedStore" >
      </electro-template>
    </div>

  </div>
</div>
</template>

<script>

import Card from "@/components/Cards/Card";
import BaseInput from "@/components/Inputs/BaseInput";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import RewardTemplate from "@/components/Cards/RewardTemplate.vue";
import FoodTemplate from "@/components/Cards/FoodTemplate.vue";
import ElectroTemplate from "@/components/Cards/ElectroTemplate.vue";
import NotificationTemplate from '@/pages/Notifications/NotificationTemplate';

export default {
  components: { 
    Card,
    BaseInput,
    BaseCheckbox,
    RewardTemplate,
    FoodTemplate,
    ElectroTemplate,
    NotificationTemplate
  },
  data(){
    return{
      selectedStore : 'select_store',
      tab : 0,
      show:{
        isActions : true,
        isCheck : true,
        overlay : true,
        isOverlayCheck: false,
        isAllOverlay: false
      },
      isFromApi : false,
      reward:{
        template:{
          title: 'Coffe',
          descrption: '6 Points',
          route: 'coffe',
          image: '',
          storeId: '',
          config:{
            headerBackground:'#e0b876',
            footerBackground: '#fff',
            titleColor: '#fff',
            descrptionColor: '#e0b876',
            image: '#'
          }
        },
        name : '',
        description: '',
        points: 0,
        storeId: ''
      },
    }
  },
  async mounted(){ 
    await this.$store.dispatch('loadStores');
    let selectedStoreIndex = localStorage.getItem("selectedStoreIndex");
    console.log('selected store index_____',selectedStoreIndex);
    if(selectedStoreIndex == 'all') this.selectedStore = 'all';
    else this.selectedStore = this.stores[selectedStoreIndex]._id;
  },
  computed:{
    rewards(){
      return this.$store.state.rewards;
    },
    stores(){
      return this.$store.state.stores 
    },
  },
  watch:{
    async selectedStore(newVal,oldVal){
      for (let index = 0; index < this.stores.length; index++) {
        const id_store = this.stores[index]._id;
        if(id_store == newVal) localStorage.setItem("selectedStoreIndex", index);
        if(newVal == 'all') localStorage.setItem("selectedStoreIndex", 'all');
      }
      let storeId = newVal;
      await this.$store.dispatch('loadRewards',storeId);
    }
  },
  methods:{
    toggleClass(id){
      document.getElementById(id).classList.toggle('active');
    },
    chooseTemplate(val){
      this.templateName = val;
    },
  }
}
</script>

<style>

  .card-create-reward{
    margin-right : 10px;
  }

  .reward-card{
    /*background: #fff;*/
    height:100%;
    width: 32%;
    color: black;
    position: relative;
    cursor: pointer;
    margin-right: 10px !important;
    margin-bottom: 30px;
  }

  .reward-card:nth-child(2){
    margin-right: 10px !important;
  }

  .reward-card .content-reward-card img{
    border-radius: 0.2857rem;
  }

  .reward-card .reward-overlay{
    position: absolute;
    transition: all .4s ease;
    /* opacity: 0; */
    background-color: rgba(0, 0, 0, 0);
  }

  .reward-card:hover .reward-overlay{
    background-color: rgba(0, 0, 0, 0.4);
  }

  .reward-card .leftOverlay{
    height: 100%;
    width: 0;
    top: 0;
    left: 0;
  }

  .reward-card .rightOverlay{
    height: 100%;
    width: 0;
    top: 0;
    right: 0;
  }

  .reward-card .topOverlay{
    height: 0;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    padding-left: 4px;
    padding-top: 1px;
  }

  .reward-card .bottomOverlay{
    height: 0;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 4px;
    padding-top: 1px;
  }

  .reward-card .topOverlay h3{
    opacity: 0;
  }

  .reward-card:hover .leftOverlay{
    width: 50%;
  }

  .reward-card:hover .rightOverlay{
    width: 50%;
  }

  .reward-card:hover .topOverlay{
    height: 20%;
    z-index: 1;
  }

  .reward-card:hover .bottomOverlay{
    height: 20%;
    z-index: 1;
  }

  .reward-card .bottomOverlay p{
    opacity: 0;
  }

  .reward-card:hover .bottomOverlay p{
    opacity: 1;
  }

  .reward-card:hover .bottomOverlay p .point-nubmber{
    color: #0984e3;
  }

  .reward-card .content-reward-card{
    position: relative;
    width: 100% !important;
    height: 100%;
  }

  .reward-card .content-reward-card img{
    width: 100% !important;
    height: 100%;
    object-fit: cover;
  }

  .reward-card .header-reward-card{
    top: 0;
    width: 100%;
    position: absolute;
    padding: 1px 4px;
    border: 2px solid transparent;
    border-radius: 6px 6px 0px 0px;
  }

  .reward-card .header-reward-card.header-reward-coffe{
    background: #e0b876;
  }

  .reward-card .header-reward-card h3{
    margin: 0;
  }

  .reward-card .header-reward-card .base-input{
    position: absolute;
    right: 2px;
    z-index: 3;
  }

  .reward-card .footer-rward-card .activated-reward{
    position: absolute;
    right: 2px;
    z-index: 3;
    bottom: 3px;
  }

  .reward-card .header-reward-card .form-control{
    padding: 5px 0px 5px 1px;
    border-color: #e14eca;
    height: calc(2.25rem + -5px);
    color: #ba54f5;
    background-color: #1e1e29;
  }

  .reward-card:hover .header-reward-card h3{
    opacity: 0;
  }

  .reward-card:hover .topOverlay h3{
    opacity: 1;
  }

  .reward-card .footer-rward-card{
    width: 100%;
    bottom: 0;
    position: absolute;
    background: #fff;
    border: 2px solid transparent;
    border-radius: 0px 0px 6px 6px;
  }

  .reward-card .footer-rward-card h3{
    margin: 0;
    color: #e0b876;
  }

  .tim-icons{
    color: #fff;
  }

  .icon-bag-40{
    font-size: 40px;
  }

  .icn-pointer{
    cursor: pointer !important;
  }

  .mb-icn{
    margin-bottom: 10px;
  }

  .title-new{
    margin-left: 10px;
  }

  .this-mt{
    margin-top: 4px;
  }

  .thisCard{
    padding: 25px;
    cursor: pointer;
  }


  .footer-reward-card-burger{
    background-color: #e51a21 !important;
  }

  .footer-reward-card-burger .footer-reward-texts h3{
    color: #fff;
    font-weight: 300;
    font-size: 18px;
  }

  .footer-reward-card-burger .footer-reward-texts p{
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .header-haedphones{
    background: none !important;
  }

  .reward-card .content-reward-card.content-headphones-reward-card .left-content-reward{
    width: 40%;
    background: #fa48a6;
    position: relative;
    border-radius: 6px 0px 0px 6px;
  }

  .reward-card .content-reward-card.content-headphones-reward-card .right-content-reward{
    width: 60%;
    border-radius: 0px 6px 6px 0px;
    position: relative;
  }

  .reward-card .content-reward-card.content-headphones-reward-card .right-content-reward .form-group.base-input{
    position: absolute;
    right: 2px;
    z-index: 3;
  }

  .reward-card .content-reward-card.content-headphones-reward-card .right-content-reward .footer-right-content-reward .toggle-btn{
    position: absolute;
    right: 2px;
    z-index: 3;
    bottom: 3px;
  }

  .reward-card .content-reward-card.content-headphones-reward-card .right-content-reward .form-group.base-input .form-control{
    padding: 5px 0px 5px 1px;
    border-color: #e14eca;
    height: calc(2.25rem + -5px);
    color: #ba54f5;
    background-color: #1e1e29;
  }

  .reward-card .content-reward-card.content-headphones-reward-card .right-content-reward img{
    border-radius: 0px 6px 6px 0px;
  }

  .reward-card .content-reward-card.content-headphones-reward-card .left-content-reward .header-left-content-reward{
    padding: 10px 10px;
    position: absolute;
    top: 0;
  }

  .reward-card .content-reward-card.content-headphones-reward-card .left-content-reward .header-left-content-reward h3{
    font-size: 14px;
  }

  .reward-card .content-reward-card.content-headphones-reward-card .left-content-reward .footer-left-content-reward{
    position: absolute;
    bottom: 0;
  }

  .reward-card .content-reward-card.content-headphones-reward-card .left-content-reward .footer-left-content-reward p{
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
  }

  .str-filters{
    width: 28%;
  }

  .filters-bar{
    margin-bottom: 21px;
  }

  .str-filters span{
    margin-bottom: 5px;
    margin-right: 25px;
    color: #fff;
  }

  .icon-to-create-reward{
    width: 23%;
    fill: #fff;
    margin-bottom: 16px;
  }

  .rewards-loop-comp{
    width: 32%;
    height: 100%;
    position: relative;
    cursor: pointer;
    margin-right: 10px !important;
    margin-bottom: 30px;
  }

  .rewards-loop-comp .reward-card{
    width: 100%;
    height: 100%;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
  }

</style>
