<template>
  <div style="position:relative" class="reward-card">
    <svg v-if="((templateName !== 'FOOD' && isCustomize == false && show.isOverlayCheck) || (isToChoose == true && reward._id !== redeemId) )" @click="emitChooseTemplate()" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus-square" class="svg-inline--fa fa-plus-square fa-w-14 checked-card" role="img" viewBox="0 0 448 512"><path fill="#ba54f5" d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"/></svg>
    <svg v-if="((templateName == 'FOOD' && isCustomize == false && show.isOverlayCheck) || (isToChoose == true && reward._id == redeemId ))" @click="emitChooseTemplate()" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle" class="svg-inline--fa fa-check-circle fa-w-16 checked-card" role="img" viewBox="0 0 512 512"><path fill="#ba54f5" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"/></svg>
      <div class="content-reward-card">
        <div class="header-reward-card d-flex">
          <base-input v-if="show.isActions" class="base-input">
            <select v-model="action" class="form-control" id="exampleFormControlSelect1">
              <option value="actions">Actions</option>
              <option value="edit">Edit</option>
              <option value="delete">Delete</option> 
              <!-- <option value="view">View</option> -->
            </select>
          </base-input>
        </div>
        <img v-if="config.image != '' && config.image != '#'" :src="`${imageUrl}`">
        <img v-else src="@/assets/images/no-image.jpg">
        <div :style="footerBackground" class="footer-rward-card footer-reward-card-burger d-flex pdng-r pdng-l pdng-t pdng-b">
          <div class="footer-reward-texts">
            <h3 :style="titleColor">
              <span v-if="reward && reward.name && reward.name !== '' ">{{reward.name}}</span>
              <span v-else>Reward name</span>
            </h3>
            <p :style="descrptionColor">
              <span v-if="reward && reward.points">{{ reward.points }}</span>
              <span v-else>__</span> 
              Points
            </p>
            
          </div>
          <div v-if="show.isCheck && reward._id" @click="toggleClass('toggle'+reward._id)" :id="'toggle'+reward._id" class="toggle-btn activated-reward">
            <div class="inner-circle"> </div>
          </div>
        </div>
      </div>
      <div :style="overlay" v-if="tab != 2 && show.isAllOverlay" class="reward-overlay all-overlay"></div>
      <div v-if="show.overlay" class="reward-overlay topOverlay">
        <h3>
          <span v-if="reward && reward.description && reward.description !== '' ">{{reward.description}}</span>
          <span v-else>Reward name</span>
        </h3>
      </div>
      <div v-if="show.overlay" class="reward-overlay leftOverlay">
        <!-- <h3></h3> -->
      </div>
      <div v-if="show.overlay" class="reward-overlay rightOverlay">
        <!-- <h3></h3> -->
      </div>
      <div v-if="show.overlay" class="reward-overlay bottomOverlay">
        <p> 
          Points : 
          <span v-if="reward && reward.points" class="point-nubmber">{{ reward.points }}</span>
          <span v-else class="point-nubmber">6</span>
        </p>
      </div>
    </div>
</template>

<script>

import NotificationTemplate from '@/pages/Notifications/NotificationTemplate';


export default {
    components: { 
      NotificationTemplate
    },
    data() {
      return {
        action:'actions'
        //src : http://localhost:5000/uploads/2022-01-25T05:45:33.127Zburger2.jpg
      }
    },
    props:{
      show:{
        type : Object
      },
      tab:{
        type: Number
      },
      templateName:{
        type: String
      },
      isCustomize:{
        type: Boolean
      },
      config:{
        type: Object,
        default:{
          headerBackground:"#e0b876",
          footerBackground: "#e51a21",
          titleColor: "#ffffff",
          descrptionColor: "#ffffff",
          image: "#"
        }
      },
      reward:{
        type:Object
      },
      isFromApi:{
        type:Boolean,
        default: false
      },
      isToChoose:{
        type: Boolean,
        default: false
      },
      redeemId:{
        type: String
      },
      selectedStore: {
        type: String
      },
    },
    mounted(){
      if(!this.isFromApi){
        this.config.footerBackground = "#e51a21";
        this.config.titleColor = "#ffffff";
        this.config.descrptionColor = "#ffffff";
      }
    },
    computed:{
      imageUrl() {
        const baseUrl = window.location.hostname === 'admin.rewardisfy.com'
        ? 'https://api-admin.rewardisfy.com/'
        : 'http://localhost:5000/';

        const imagePath = this.config.image.replace('/var/www', '');
        
        return `${baseUrl}${imagePath}`;
      },
      footerBackground(){
        if(this.isCustomize){
          return{
            "background": `${this.config.footerBackground} !important`,
            "color": "#fff !important"
          }
        }else{
          return ''
        }
      },
      titleColor(){
        if(this.isCustomize){
          return{
            "color": this.config.titleColor,
          }
        }else{
          return ''
        }
      },
      descrptionColor(){
        if(this.isCustomize){
          return{
            "color": this.config.descrptionColor,
          }
        }else{
          return ''
        }
      },
      overlay(){
        if(this.templateName == 'FOOD'){
          return{
            'height': '100%',
            'width': '100%',
            'left': '0',
            'top': '0',
            'bottom': '0',
            'position': 'absolute !important',
            'transition': 'all .7s ease !important',
            'background-color': 'rgba(0, 0, 0, 0.4) !important'
          }
        }

      }
    },
    watch:{
      config:{
        handler:async function(val, oldVal){
          console.log('old val ---->',oldVal);
          console.log('new val ---->', val);
          this.$emit('emitConfig', val); 
        },
        deep: true
      },
      action(val, oldVal){
        const storeId = this.selectedStore;
        const id = this.reward._id;
        console.log('Reward action ==___==',val);
        if(this.action == 'edit'){
          this.$router.push({ path: `/rewards/update/${this.reward._id}`})
        }
        if (this.action == 'delete'){
          this.$swal({
            title: 'Are you sure?',
            text: 'Are you sure you want to delete this reward',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, proceed!',
            cancelButtonText: 'Cancel',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              // User confirmed, perform the action
              let res = await this.$store.dispatch('removeReward',id);
              if(res.status == 200){
                this.notifyVue('bottom', 'center', 'Reward deleted successfuly', 'success');
                await this.$store.dispatch('loadRewards',storeId);
              }else{
                this.notifyVue('bottom', 'center', 'Error delete reward', 'danger');
              }
            } else {
              this.action = 'actions';
              //document.getElementById("exampleFormControlSelect1"+index).selectedIndex = 0;
              //this.$swal('Action Cancelled', 'Your action has been cancelled', 'info');
            }
          });
          
        }
      } 
    },
    methods:{
      notifyVue(verticalAlign, horizontalAlign, msg, type) {
        const color = Math.floor(Math.random() * 4 + 1);
        this.$notify({
            component: NotificationTemplate,
            message: msg,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: type, //this.type[color],
            timeout: 3000
        });
      },
      emitChooseTemplate(){
        console.log('if there is reward ===>',this.reward);
        if(this.isFromApi){
          this.$emit('emitChoosingReward', this.reward);
        }else{
          this.$emit('emitChoosingTemp', this.templateName = 'FOOD');
        }
      },
      toggleClass(id){
        document.getElementById(id).classList.toggle('active');
      },
      confirmAction() {
        this.$swal({
          title: 'Are you sure?',
          text: 'This action cannot be undone!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed!',
          cancelButtonText: 'Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            // User confirmed, perform the action
            this.performAction();
          } else {
            // User cancelled, handle accordingly
            this.$swal('Action Cancelled', 'Your action has been cancelled', 'info');
          }
        });
      },
    }
}
</script>

<style>

</style>