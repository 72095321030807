import axios from 'axios'

export default () =>{
  let baseURL;

  console.log('NODE_ENV:', process.env.NODE_ENV); // Should show 'development' or 'production'
  console.log('VUE_APP_API_BASE_URL_DEV:', process.env.VUE_APP_API_BASE_URL_DEV); // Should not be undefined
  console.log('VUE_APP_API_BASE_URL_PROD:', process.env.VUE_APP_API_BASE_URL_PROD); 

  if (process.env.NODE_ENV === 'production') {
    baseURL = process.env.VUE_APP_API_BASE_URL_PROD;
  } else {
    baseURL = process.env.VUE_APP_API_BASE_URL_DEV;
  }
  //baseURL = 'http://localhost:5000/api';
  return axios.create({
    baseURL: baseURL,
    withCredentials : false,
    headers:{ 
      Accept: "application/json",
      "Content-type" : "application/json",
      // Authorization: currentUser && currentUser.token
    }
  })
}