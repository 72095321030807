<template>
  <div>
    <Signup /> 
  </div>
</template>

<script>

import Signup from "@/components/Signup.vue";

export default {
  components: { Signup },

}
</script>

<style>

</style>