<template>
    <div style="position:relative;">
        <div class="row globalTabs">
                <!--<div class="col-md-6">
                    <h3><small>Tabs with Icons on Card</small></h3>

                    <div class="card card-nav-tabs">
                        <div class="card-header card-header-primary">
                            <div class="nav-tabs-navigation">
                                <div class="nav-tabs-wrapper">
                                    <ul class="nav nav-tabs" data-tabs="tabs">
                                        <li class="nav-item">
                                            <a class="nav-link active" href="#profile" data-toggle="tab">
                                                <i class="material-icons">face</i>
                                                Profile
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#messages" data-toggle="tab">
                                                <i class="material-icons">chat</i>
                                                Messages
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#settings" data-toggle="tab">
                                                <i class="material-icons">build</i>
                                                Settings
                                            </a>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div><div class="card-body ">
                            <div class="tab-content text-center">
                                <div class="tab-pane active" id="profile">
                                    <p> I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at. </p>
                                </div>
                                <div class="tab-pane" id="messages">
                                    <p> I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at. I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at.</p>
                                </div>
                                <div class="tab-pane" id="settings">
                                    <p>I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at. So when you get something that has the name Kanye West on it, it’s supposed to be pushing the furthest possibilities. I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus.</p>
                                </div>
                            </div>
                        </div></div>

                </div>-->
                <div class="col-md-12">
                    <h3 class="heading-reward-info-title" v-if="!isEdit">Add New Reward</h3>
                    <h3 class="heading-reward-info-title" v-if="isEdit">Edit Reward</h3>
                    <div class="card card-nav-tabs card-plain">
                        <div class="card-header card-header-danger">
                            <div class="nav-tabs-navigation">
                                <div class="nav-tabs-wrapper">
                                    <ul class="nav nav-tabs" data-tabs="tabs">
                                        <li @click="tabs(0)" class="nav-item">
                                            <span class="nav-link" :class="{active : tab == 0}" data-toggle="tab">Reward Details</span>
                                        </li>
                                        <li @click="tabs(1)" class="nav-item" >
                                            <span class="nav-link" :class="{active : tab == 1}"  data-toggle="tab">Choose Template</span>
                                        </li>
                                        <li @click="tabs(2)" class="nav-item">
                                            <span class="nav-link" :class="{active : tab == 2}" data-toggle="tab">Customize Template</span>
                                        </li>
                                        <!-- <li class="nav-item">
                                            <span class="nav-link" data-toggle="tab">Coupon Email Notification</span>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <div v-if="tab == 0" class="newStroe row">
            <div class="col-sm-12">
                <card>
                    <form>
                        <div class="form-row">
                            <div class="col">
                                <p class="error-msg">{{ rewardErrors.rewardStoreError }}</p>
                                <BaseInput label="Select Store *">
                                    <select v-model="reward.storeId" class="form-control stores-reward-dropdown" id="exampleFormControlSelect1" >
                                        <option value="select_store">Select store</option>
                                        <option :value="store._id" v-for="(store,index) in stores" :key="index">{{store.name}}</option>
                                    </select>
                                </BaseInput>
                                <!-- <BaseInput  type="text" label="Select Store" placeholder="store name"/> -->
                                <p class="error-msg">{{ rewardErrors.rewardNameError }}</p>
                                <BaseInput type="text" v-model="reward.name" label="Reward name *" placeholder="reward name"/>

                                <p class="error-msg" v-if="false">{{ rewardErrors.rewardDescriptionError }}</p>
                                <BaseInput type="text" v-if="false" v-model="reward.description" label="Reward description" placeholder="description"/>
                                
                                <BaseInput type="number" v-model="points" label="Reward Points *" placeholder="points"/>
                            </div>
                            <div class="col">
                                <!--<BaseInput type="text" label="Customer Redeem Validity" placeholder="instant"/> -->
                            </div>
                        </div>
                    </form>
                </card>
            </div>
        </div>
        <div class="row templates" v-if="tab == 1">
            <reward-template :templateName="templateName" :isFromApi="false" v-on:emitChoosingTemp="chooseTemplate($event)" :show="show"  :isCustomize="false"  class="chs-template"></reward-template>
            <!-- <input class="planCheck" type="radio" name="pack" @change="onChange()" :value="pack.price" v-model="priceApp"> :config="reward.template.config" -->
            <!-- <reward-template :templateName="templateName" v-on:emitChoosingTemp="chooseTemplate($event)" :isCustomize="false" :show="show" :config="reward.template.config" class="chs-template"></reward-template> -->
            <food-template :templateName="templateName" :isFromApi="false" v-on:emitChoosingTemp="chooseTemplate($event)" :show="show" :isCustomize="false" class="chs-template"></food-template>
            <electro-template :templateName="templateName" :isFromApi="false" v-on:emitChoosingTemp="chooseTemplate($event)" :show="show" :isCustomize="false" class="chs-template"></electro-template>
        </div>
        <div v-if="tab == 2" class="newStroe row">
            <div class="col-sm-12">
                <card>
                    <form>
                        <div class="form-row">
                            <div class="col-12 col-md-6">
                                <div class="row">
                                    <BaseInput class="col-9 col-md-10 input-code-color" v-model="reward.template.config.titleColor" type="text" label="Reward Title Color" placeholder="reward name"/>
                                    <input class="col-3 col-md-2 input-color" v-model="reward.template.config.titleColor" type="color">
                                </div>
                                <div v-if="templateName == 'COFFE' || templateName == 'ELECTRO'" class="row">
                                    <BaseInput v-model="reward.template.config.headerBackground" class="col-9 col-md-10" type="text" label="Background1 color" placeholder="background 1"/>
                                    <input class="col-3 col-md-2 input-color" v-model="reward.template.config.headerBackground" type="color">
                                </div>
                                
                                
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="row">
                                    <BaseInput v-model="reward.template.config.descrptionColor" class="col-9 col-md-10" type="text" label="Reward Points Color" placeholder="points color"/>
                                    <input class="col-3 col-md-2 input-color" v-model="reward.template.config.descrptionColor" type="color">
                                </div>
                                <div v-if="templateName == 'COFFE' || templateName == 'FOOD'" class="row">
                                    <BaseInput v-model="reward.template.config.footerBackground" class="col-9 col-md-10" type="text"  label="Background2 Color" placeholder="background 2"/>
                                    <input class="col-3 col-md-2 input-color" v-model="reward.template.config.footerBackground" type="color">
                                </div>
                            </div>  
                        </div>
                        <div class="file-img">
                            <!-- <BaseInput v-model="reward.template.config.image" type="text" label="Reward Image" placeholder="reward image"/> -->
                            <!-- <input v-model="reward.template.config.image" type="file" label="Reward Image" placeholder="reward image"/> -->
                            <label class="label-img">Choose image</label>
                            <input @change="filesContent($event)" type="file" accept="image/png, image/jpeg, image/jpg" id="imageInput">
                            <div v-if="uploadInProgress" class="progress-container">
                                <div class="progress-bar-wrapper">
                                    <div class="progress-bar" :style="{ width: uploadProgress + '%' }"></div>
                                </div>
                                <span class="progress-counter">{{ uploadProgress }}%</span>
                            </div>
                        </div>
                        <div class="choosing-template-custmz">
                            <reward-template v-if="templateName == 'COFFE'" :templateName="templateName" :isFromApi="isFromApi" :reward="reward" v-on:emitConfig="updateConfigValues($event)" :isCustomize="true" isEdit :config="reward.template.config" :show="show" :tab="tab" class="chs-template"></reward-template>
                            <food-template v-if="templateName == 'FOOD'" :templateName="templateName" :isFromApi="isFromApi" :isCustomize="true" :reward="reward" v-on:emitConfig="updateConfigValues($event)" :config="reward.template.config" :show="show" :tab="tab" class="chs-template"></food-template>
                            <electro-template v-if="templateName == 'ELECTRO'" :templateName="templateName" :isFromApi="isFromApi" :isCustomize="true" :reward="reward" v-on:emitConfig="updateConfigValues($event)" :config="reward.template.config" :show="show" :tab="tab" class="chs-template"></electro-template>
                        </div>
                    </form>
                </card>
            </div>
        </div>
        <div>
            <div class="content-btns">                
                <BaseButton v-if="tab > 0 && !isEdit" @click="previous()"  class="btn-prev" type="primary">Previous</BaseButton>
                <BaseButton v-if="tab < 2 && !isEdit" @click="next()" class="btn-next" type="primary">Next</BaseButton>
                <BaseButton v-if="tab == 2 || isEdit" @click="saveReward()"  type="primary">Save</BaseButton>
            </div>
        </div>
    </div>
  
</template>

<script>

import BaseButton from  "@/components/BaseButton.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import RewardTemplate from "@/components/Cards/RewardTemplate.vue";
import ElectroTemplate from "@/components/Cards/ElectroTemplate.vue"
import FoodTemplate from "@/components/Cards/FoodTemplate.vue"
import NotificationTemplate from '@/pages/Notifications/NotificationTemplate';

import { BaseAlert } from '@/components';

export default {
    components:{
      BaseButton,
      BaseInput,
      BaseCheckbox,
      RewardTemplate,
      ElectroTemplate,
      FoodTemplate,
      BaseAlert,
      NotificationTemplate
    },
    data() {
        return {
            isEdit :false,
            isFromApi : false,
            tab : 0,
            points : 0,
            type: ["", "info", "success", "warning", "danger"],
            textNotification:'',
            notifications: {
                topCenter: false
            },
            show:{
                isActions : false,
                isCheck : false,
                overlay : false,
                isOverlayCheck : true,
                isAllOverlay: true
            },
            idTemplate:'',
            template:{
                title: "Coffe",
                descrption: "6 Points",
                route: "coffe",
                storeId: "61a948ceceb04239d7031490",
                image: "",
                config:{
                    headerBackground:"#e0b876",
                    footerBackground: "#ffffff",
                    titleColor: "#ffffff",
                    descrptionColor: "#e0b876",
                    image: ""
                }
            },
            reward:{
                template:{
                    title: 'Coffe',
                    descrption: '6 Points',
                    route: 'COFFE',
                    image: '',
                    storeId: '',
                    config:{
                        headerBackground:'#e0b876',
                        footerBackground: '#ffffff',
                        titleColor: '#ffffff',
                        descrptionColor: '#e0b876',
                        image: '#'
                    }
                },
                name : '',
                description: '',
                points: 0,
                storeId: 'select_store'
            },
            isCustomize : true,
            templateName :'COFFE',
            rewardErrors:{
                rewardNameError: '',
                rewardDescriptionError: '',
                rewardStoreError: ''
            },
            uploadInProgress: false,
        }
    },
    async mounted(){
        await this.getData();
        await this.$store.dispatch('loadStores');
        this.$store.commit('SET_UPLOAD_PROGRESS', 0);
        this.fixFFF();
        if(!this.isEdit){
            this.$store.commit('SET_IMAGE', {});
        }
        this.uploadInProgress = false;
    },
    computed:{
        stores(){
            return this.$store.state.stores 
        },
        rewardItem(){
            return this.$store.state.reward;
        },
        uploadProgress() {
            return this.$store.state.uploadProgress;
        }
    },
    methods:{
        async getData(){
            if(this.$route.params.hasOwnProperty('id')){ 
                console.log('id reward _______',this.$route.params.id);
                let id = this.$route.params.id;
                this.isEdit = true;
                this.isFromApi = true;
                await this.$store.dispatch('getReward',id);
                console.log('reward to update ===>',this.$store.state.reward );
                this.reward.name = this.$store.state.reward.name;
                this.reward.description = this.$store.state.reward.description;
                this.reward.points = this.$store.state.reward.points;
                this.points = this.reward.points;
                this.reward.storeId = this.$store.state.reward.storeId._id;

                this.reward.template.title = this.$store.state.reward.templateId.title;
                if(this.$store.state.reward.templateId.description) this.reward.template.descrption = this.$store.state.reward.templateId.description;
                this.reward.template.route = this.$store.state.reward.templateId.route;
                this.reward.template.storeId = this.$store.state.reward.templateId.storeId;
                if(this.$store.state.reward.templateId.image) this.reward.template.image = this.$store.state.reward.templateId.image;
                else this.reward.template.image = this.$store.state.reward.templateId.config.image;
                this.reward.template.config = {};
                this.reward.template.config = this.$store.state.reward.templateId.config;
                console.log('conig edit', this.reward.template.config);
                this.templateName = this.$store.state.reward.templateId.route;
                this.idTemplate = this.$store.state.reward.templateId._id;
            }
        },
        notifyVue(verticalAlign, horizontalAlign, msg, type) {
            const color = Math.floor(Math.random() * 4 + 1);
            this.$notify({
                component: NotificationTemplate,
                message: msg,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: type, //this.type[color],
                timeout: 3000
            });
        },
        fixFFF(){
            if(this.reward.template.config.headerBackground == '#fff') this.reward.template.config.headerBackground = "#ffffff";
            if(this.reward.template.config.footerBackground == '#fff') this.reward.template.config.footerBackground = "#ffffff";
            if(this.reward.template.config.titleColor == '#fff') this.reward.template.config.titleColor = "#ffffff";
            if(this.reward.template.config.descrptionColor == '#fff') this.reward.template.config.descrptionColor = "#ffffff";
        },
        tabs(tab){
            if(!this.isEdit){
                //|| this.reward.description == ''
                if( (tab == 1 || tab == 2 ) && (this.reward.name == ''  || this.reward.storeId == 'select_store')){
                    if(this.reward.name == '') this.rewardErrors.rewardNameError = 'Reward Name Cannot be empty';
                    if(this.reward.name !== '') this.rewardErrors.rewardNameError = '';

                    if(this.reward.storeId == '' || this.reward.storeId == 'select_store') this.rewardErrors.rewardStoreError = 'Please select a Store';
                    if(this.reward.storeId !== '' && this.reward.storeId !== 'select_store') this.rewardErrors.rewardStoreError = '';
                    this.notifyVue('bottom', 'center', 'Elements cannot be empty', 'danger');
                }else{
                    this.rewardErrors.rewardNameError = '';
                    this.rewardErrors.rewardStoreError = '';
                    this.tab = tab;
                }
            }else this.tab = tab;
        },
        async next(){
            if(this.tab < 2){
                //|| this.reward.description == ''
                if(this.reward.name == '' || this.reward.storeId == '' || this.reward.storeId == 'select_store'){
                    if(this.reward.name == '') this.rewardErrors.rewardNameError = 'Reward Name Cannot be empty';
                    if(this.reward.name !== '') this.rewardErrors.rewardNameError = '';

                    if(this.reward.storeId == '' || this.reward.storeId == 'select_store') this.rewardErrors.rewardStoreError = 'Please select a Store';
                    if(this.reward.storeId !== '' && this.reward.storeId !== 'select_store') this.rewardErrors.rewardStoreError = '';
                    this.notifyVue('bottom', 'center', 'Elements cannot be empty', 'danger');
                    return;
                }
                this.rewardErrors.rewardNameError = '';
                this.rewardErrors.rewardStoreError = '';
                this.tab++;   
            }
        },
        previous(){
            if(this.tab > 0) this.tab --;
        },
        chooseTemplate(val){
            this.templateName = val;
        },
        async filesContent(e){
            console.log('event file ____',e.target.files[0]);
            let image = e.target.files[0];
            this.uploadInProgress = true;
            await this.$store.dispatch('uploadImage',image)
            console.log('path when upload image ----->',this.$store.state.image.path);
            this.reward.template.config.image = this.$store.state.image.path;
            //this.uploadInProgress = false;
        },
        updateConfigValues(val){
            console.log(' val come from emit ----> ', val);
            this.reward.template.config.headerBackground = val.headerBackground;
            this.reward.template.config.footerBackground = val.footerBackground;
            this.reward.template.config.titleColor = val.titleColor;
            this.reward.template.config.descrptionColor = val.descrptionColor;
            this.reward.template.config.image = val.image;
        },
        async submitUpdateReward(){
            if(this.reward.name == '' || this.reward.storeId == '' || this.reward.storeId == 'select_store' ){
                if(this.reward.name == '') this.rewardErrors.rewardNameError = 'Reward Name Cannot be empty';
                if(this.reward.name !== '') this.rewardErrors.rewardNameError = '';

                if(this.reward.storeId == '' || this.reward.storeId == 'select_store') this.rewardErrors.rewardStoreError = 'Please select a Store';
                if(this.reward.storeId !== '' && this.reward.storeId !== 'select_store') this.rewardErrors.rewardStoreError = '';
                this.notifyVue('bottom', 'center', 'Some Elements cannot be empty', 'danger');
                return;
            }
            if(this.templateName == 'COFFE'){
                this.reward.template.route = 'COFFE';
                this.reward.template.title = 'Coffe';
            }
            if(this.templateName == 'FOOD'){
                this.reward.template.route = 'FOOD';
                this.reward.template.title = 'Food';
            }
            if(this.templateName == 'ELECTRO'){
                this.reward.template.route = 'ELECTRO';
                this.reward.template.title = 'Electro';
            }
            this.reward.points = parseInt(this.points);
            this.reward.template._id = this.idTemplate;
            const id = this.$route.params.id;
            this.reward.description = this.reward.name;
            const reward = this.reward;
            let updResp = await this.$store.dispatch('updateReward',{id,reward});
            console.log('update resp ===>', updResp);
            if(updResp.status == 200){
                this.rewardErrors.rewardNameError = '';
                this.rewardErrors.rewardStoreError = '';
                this.notifyVue('bottom', 'center', 'Reward updated successfuly', 'success');
            }else{
                if(this.reward.name !== '') this.rewardErrors.rewardNameError = '';
                if(this.reward.storeId !== '' && this.reward.storeId !== 'select_store') this.rewardErrors.rewardStoreError = '';
                this.notifyVue('bottom', 'center', 'Error Update reward', 'danger');
            }
        },
        async saveReward(){
            if(!this.isEdit){
                //|| this.reward.description == ''
                if(this.reward.name == '' || this.reward.storeId == '' || this.reward.storeId == 'select_store' ){
                    if(this.reward.name == '') this.rewardErrors.rewardNameError = 'Reward Name Cannot be empty';
                    if(this.reward.name !== '') this.rewardErrors.rewardNameError = '';

                    if(this.reward.storeId == '' || this.reward.storeId == 'select_store') this.rewardErrors.rewardStoreError = 'Please select a Store';
                    if(this.reward.storeId !== '' && this.reward.storeId !== 'select_store') this.rewardErrors.rewardStoreError = '';
                    this.notifyVue('bottom', 'center', 'Some Elements cannot be empty', 'danger');
                    return;
                }
                console.log('store state image',this.$store.state.image);
                //this.reward.template.image = this.$store.state.image.path;
                if(this.$store.state.image.path != '' && this.$store.state.image.path != null){
                    this.reward.template.config.image = this.$store.state.image.path;
                }else{
                    this.reward.template.config.image = '';
                }
                this.reward.template.storeId = this.reward.storeId;
                if(this.templateName == 'COFFE'){
                    this.reward.template.route = 'COFFE';
                    this.reward.template.title = 'Coffe';
                }
                if(this.templateName == 'FOOD'){
                    this.reward.template.route = 'FOOD';
                    this.reward.template.title = 'Food';
                }
                if(this.templateName == 'ELECTRO'){
                    this.reward.template.route = 'ELECTRO';
                    this.reward.template.title = 'Electro';
                }
                this.reward.points = parseInt(this.points);
                console.log('store id in reward =====> ',this.reward.storeId);
                this.reward.description = this.reward.name;
                let reward = this.reward;
                console.log('reward',reward);
                let resp = {};
                resp = await this.$store.dispatch('createNewReward',reward);
                if(resp.status == 200){
                    this.notifyVue('bottom', 'center', 'Reward created successfuly', 'success');
                    this.$router.push({ name: 'rewards' });
                }
                else this.notifyVue('bottom', 'center', 'Error Create reward', 'danger');
            }else{
                this.submitUpdateReward();
            } 
            
        }
    }

}
</script>

<style >

  .mbNewStore{
    margin-bottom: 15px;
  }

  .globalTabs h3{
    font-size: 25px !important;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.4em !important;
}

.globalTabs  p {
    font-size: 14px;
    margin: 0 0 10px !important;
    font-weight: 300;
}

.globalTabs  small {
    font-size: 75%;
    color: #777;
    font-weight: 400;
}

.globalTabs .container .title{
    color: #3c4858;
    text-decoration: none;
    margin-top: 30px;
    margin-bottom: 25px;
    min-height: 32px;
}

.globalTabs .container .title h3{
    font-size: 25px;
    font-weight: 300;
}

.globalTabs div.card {
    border: 0;
    /* margin-bottom: 30px; */
    margin-top: 30px;
    border-radius: 6px;
    color: rgba(0,0,0,.87);
    background: #fff;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}

.globalTabs div.card.card-plain {
    background: transparent;
    box-shadow: none;
}

.globalTabs div.card .card-header {
    border-radius: 3px;
    padding: 1rem 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -30px;
    border: 0;
    background: linear-gradient(60deg,#eee,#bdbdbd);
}

.globalTabs .card-plain .card-header:not(.card-avatar) {
    margin-left: 0;
    margin-right: 0;
}

.globalTabs .div.card .card-body{
    padding: 15px 30px;
}

.globalTabs div.card .card-header-primary {
    background: linear-gradient(60deg,#ab47bc,#7b1fa2);
    box-shadow: 0 5px 20px 0 rgba(0,0,0,.2), 0 13px 24px -11px rgba(156,39,176,.6);
}

.globalTabs div.card .card-header-danger {
    /* background: linear-gradient(60deg,#ef5350,#d32f2f); */
    background: #27293d;
    box-shadow: 0 1px 20px 0px rgb(0 0 0 / 10%);
    /* box-shadow: 0 5px 20px 0 rgba(0,0,0,.2), 0 13px 24px -11px rgba(244,67,54,.6); */
}


.globalTabs .card-nav-tabs .card-header {
    margin-top: -30px!important;
}

.globalTabs .card .card-header .nav-tabs {
    padding: 0;
}

.globalTabs .nav-tabs {
    border: 0;
    border-radius: 3px;
    padding: 0 15px;
}

.globalTabs .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.globalTabs .nav-tabs .nav-item {
    margin-bottom: -1px;
}

.globalTabs .nav-tabs .nav-item .nav-link.active {
    background-color: hsla(0,0%,100%,.2);
    transition: background-color .3s .2s;
}

.globalTabs .nav-tabs .nav-item .nav-link{
    border: 0!important;
    color: #fff!important;
    font-weight: 500;
}

.globalTabs .nav-tabs .nav-item .nav-link {
    color: #fff;
    border: 0;
    margin: 0;
    border-radius: 3px;
    line-height: 24px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px 15px;
    background-color: transparent;
    transition: background-color .3s 0s;
}

.globalTabs .nav-link{
    display: block;
}

.globalTabs .nav-tabs .nav-item .material-icons {
    margin: -1px 5px 0 0;
    vertical-align: middle;
}

.globalTabs .nav .nav-item {
    position: relative;
}
.globalTabs footer{
    margin-top:100px;
    color: #555;
    background: #fff;
    padding: 25px;
    font-weight: 300;

}
.globalTabs .footer p{
    margin-bottom: 0;
    font-size: 14px;
    margin: 0 0 10px;
    font-weight: 300;
}
.globalTabs footer p a{
    color: #555;
    font-weight: 400;
}

.globalTabs footer p a:hover {
    color: #9f26aa;
    text-decoration: none;
}

.card-nav-tabs .card-header .nav-tabs-navigation .nav-tabs-wrapper .nav-tabs .nav-link{
  cursor: pointer;
}


.templates .reward-card{
    height: auto;
    min-height: 266px;
}

.chs-template{
    margin-bottom: 20px;
    max-height: 266px;
    

    
}

.chs-template .content-reward-card{
    box-shadow: 0 1px 20px 0px rgb(0 0 0 / 10%) !important;
    transition: .6s ease-out !important;
}

.chs-template .content-reward-card:hover{
    box-shadow: #ad5389 0px 0px 16px 1px !important;
    transform: translateY(15px) !important  ;
}

.chs-template.reward-card .reward-overlay{
    position: absolute !important;
    transition: all .7s ease !important;
    /* opacity: 0; */
    background-color: rgba(0, 0, 0, 0) !important;
}

.chs-template.reward-card:hover .reward-overlay{
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.chs-template.reward-card:hover .reward-overlay.all-overlay{
    height: 100%;
    width:100%;
    left: 0;
    top: 0;
    left: 0;
    bottom: 0;
}

.choosing-template-custmz{
    height: 200px;
}

.input-color{
    padding-right: 0;
    padding-left: 0;
    margin-top: 33px;
}

.file-img{
    margin-bottom: 25px;
}

.label-img{
    margin-bottom: 13px !important;
    display: block;
}

.content-btns{
    margin-left: auto;
    width: 30%;
    display: flex;
    justify-content: right;
    position: fixed;
    left: 67%;
    bottom: 3px;
}

.content-btns .btn-prev{
    margin-right: 20px;
}

.stores-reward-dropdown option{
    color: #000;
}

.heading-reward-info-title{
    margin-bottom: 63px !important;

}

.progress-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.progress-bar-wrapper {
  width: 150px;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 9px;
}

/* Progress bar fill */
.progress-bar {
  height: 100%;
  background-color: #ba54f5;
  border-radius: 5px;
  transition: width 0.3s ease;
}

.progress-counter {
  font-size: 14px;
  color: #fff;
  margin-top: 12px;
}

</style>
