<template>
  <div class="newStroe redeem-details row">
    <div v-if="showPopup" class="vb-popup-comp">
      <!-- <p style="color: #fff;">{{ redeem }}</p> -->
      <div class="vb-popup-background"></div>
      <div class="vb-popup">
        <div @click="showPopup = false" class="close-popup-rewards">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style="&#10;    width: 25px;&#10;    height: 25px;&#10; margin-top: 7px;"><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
        </div>
        <header class="vs-popup-header">
          <div class="vb-popup-title">
            <h3>Select a reward</h3>
          </div>
        </header>
        <div class="vb-popup-content">
          <div id="rewards-selector">
            <div class="container-rewards-selctor">
              <div class="d-flex justify-content-between mb-4">
                <h6 class="text secondary">Rewards</h6>
                <!-- <a href="#">Add reward</a> -->
              </div>
              <div v-if="false" class="w-100 mt-1 mb-4">
                <div class="vs-input vs-con-input-label w-100">
                  <div class="vs-con-input">
                    <input type="text"  class="vs-inputx" >
                    <span class="span-placeholder">Search</span>
                    <i class="vs-icon"></i>
                  </div>
                  <span></span>
                </div>
              </div>
              <div> 
                <div class="d-flex rewards-items flex-wrap">
                  <div v-for="(rwd,index) in rewards" :key="index" class="item-reward">
                    <reward-template v-if="rwd.templateId.route == 'COFFE' " :isFromApi="true" :config="rwd.templateId.config" :reward="rwd" :templateName="rwd.templateId.route" :redeemId="redeem.rewardId._id" v-on:emitChoosingTemp="chooseTemplate($event)" v-on:emitChoosingReward="chooseReward($event)" :show="shows[1]" :isCustomize="true" :isToChoose="true" ></reward-template>
                    <food-template v-if="rwd.templateId.route == 'FOOD' " :isFromApi="true" :config="rwd.templateId.config" :reward="rwd" :templateName="rwd.templateId.route" :redeemId="redeem.rewardId._id" v-on:emitChoosingTemp="chooseTemplate($event)" v-on:emitChoosingReward="chooseReward($event)" :show="shows[1]" :isCustomize="true" :isToChoose="true" ></food-template>
                    <electro-template v-if="rwd.templateId.route == 'ELECTRO' " :isFromApi="true" :config="rwd.templateId.config" :reward="rwd" :templateName="rwd.templateId.route" :redeemId="redeem.rewardId._id" v-on:emitChoosingTemp="chooseTemplate($event)" v-on:emitChoosingReward="chooseReward($event)" :show="shows[1]" :isCustomize="true" :isToChoose="true"></electro-template>
                  </div>
                </div>
              </div>
              <div v-if="false" class="show-more-in-popup">
                <span class="show-more-text">Show more</span>
              </div>
            </div>
          </div>
          <div class="buttom-popup">
            <!-- <base-button @click="showPopup = false" class=""  type="info">Cancel</base-button> -->
            <base-button @click="saveChoosingReward" class="ml-4 save-checked-reward"  type="primary">Save</base-button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <h4>
        <span>Edit Redeem</span>
      </h4>
    </div>
    <div class="col-sm-8">
      <card>
        <form @submit.prevent>
            <base-input label="Phone number"
                        type="text"
                        placeholder="phone number"
                        :value="redeem.customerId.phone"
                        disabled
                        >
            </base-input>
            <base-input label="Firstname"
                        type="text"
                        placeholder="firstname"
                        :value="redeem.customerId.firstname"
                        disabled
                        >
            </base-input>
            <base-input label="Lastname"
                        type="text"
                        placeholder="lastname"
                        :value="redeem.customerId.lastname"
                        disabled
                        >
            </base-input>

            <div>
              <base-input label="Select status" class="base-input">
                  <select v-model="redeem.status" style="cursor:pointer;" class="form-control" id="exampleFormControlSelect1">
                    <option style="color: #000;" value="PENDING">PENDING</option>
                    <option style="color: #000;" value="DELIVERED">DELIVERED</option>
                    <option style="color: #000;" value="FALLBACK">FALLBACK</option>
                  </select>
              </base-input>
            </div>

            <div class=" d-flex">
              <label class="label-change-reward"> Reward </label>
              <button @click="showPopup = true" class="btn-change-reward">Choose reward</button>
            </div>
            <div class="redeem-contain-reward">
              <reward-template  v-if="redeem.rewardId.templateId.route == 'COFFE'" :isFromApi="true" :config="redeem.rewardId.templateId.config" :reward="redeem.rewardId" :templateName="redeem.rewardId.templateId.route" :redeemId="redeem.rewardId._id"  :show="shows[0]"  :isCustomize="true"  :tab="tab"  ></reward-template>
              <food-template v-if="redeem.rewardId.templateId.route == 'FOOD'" :isFromApi="true" :config="redeem.rewardId.templateId.config" :reward="redeem.rewardId" :templateName="redeem.rewardId.templateId.route" :redeemId="redeem.rewardId._id" :show="shows[0]" :isCustomize="true"  :tab="tab" ></food-template>
              <electro-template v-if="redeem.rewardId.templateId.route == 'ELECTRO' " :isFromApi="true" :config="redeem.rewardId.templateId.config" :reward="redeem.rewardId" :templateName="redeem.rewardId.templateId.route" :redeemId="redeem.rewardId._id" :show="shows[0]" :isCustomize="true" :tab="tab"  ></electro-template>
              
              
              
              <!-- <food-template v-if="rwd.templateId.route == 'FOOD' " :isFromApi="true" :config="rwd.templateId.config" :reward="rwd" :templateName="rwd.templateId.route" :redeemId="redeem.rewardId._id" v-on:emitChoosingTemp="chooseTemplate($event)" v-on:emitChoosingReward="chooseReward($event)" :show="shows[1]" :isCustomize="true" :isToChoose="true" ></food-template>
              <electro-template v-if="rwd.templateId.route == 'ELECTRO' " :isFromApi="true" :config="rwd.templateId.config" :reward="rwd" :templateName="rwd.templateId.route" :redeemId="redeem.rewardId._id" v-on:emitChoosingTemp="chooseTemplate($event)" v-on:emitChoosingReward="chooseReward($event)" :show="shows[1]" :isCustomize="true" :isToChoose="true"></electro-template> -->
            </div>
            <base-button native-type="submit" @click="saveChoosingReward()" type="primary">Save</base-button>
        </form>
      </card>
  </div>
  </div>
</template>

<script>

import NotificationTemplate from '@/pages/Notifications/NotificationTemplate'; 
import RewardTemplate from "@/components/Cards/RewardTemplate.vue";
import FoodTemplate from "@/components/Cards/FoodTemplate.vue"
import ElectroTemplate from "@/components/Cards/ElectroTemplate.vue";

export default {
  components:{
    NotificationTemplate,
    RewardTemplate,
    FoodTemplate,
    ElectroTemplate
  },
  data(){
    return{
      isEdit: false,
      shows:[
        {
          isActions : false,
          isCheck : false,
          overlay : true,
          isOverlayCheck: true,
          isAllOverlay: false
        },
        {
          isActions : false,
          isCheck : false,
          overlay : true,
          isOverlayCheck: true,
          isAllOverlay: true
        }
      ],
      show:{
        isActions : false,
        isCheck : false,
        overlay : true,
        isOverlayCheck: true,
        isAllOverlay: true
      },
      isFromApi : false,
      tab:0,
      showPopup:false,
      oldRewardId: '',
      oldReward:{},
      customerId:'',
      redeem:{
        // customer:{
          
        // },
        // reward:{

        // }
      },
      templateName: "COFFE",
      selectedStore : 'select_store',
      saveRedeemErrors:{
        pointsError: '',
      },
      wallet:{

      }
    }
  },
  async mounted(){
    await this.getRedeemData();
    await this.$store.dispatch('loadStores');
    this.selectedStore = this.redeem.rewardId.storeId._id;
    this.oldRewardId = this.redeem.rewardId._id;
    this.oldReward = this.redeem.rewardId;
    this.customerId = this.redeem.customerId._id;
    let customerId = this.customerId;
    let storeId = this.selectedStore;
    await this.$store.dispatch('loadRewards',storeId);
    let res = await this.$store.dispatch('getWallet',{customerId, storeId});
    this.wallet = res.data;
    console.log('wallet in redeem ____',this.wallet);
  },
  computed:{
    rewards(){
      return this.$store.state.rewards;
    },
    stores(){
      return this.$store.state.stores;
    }
  },
  methods:{
    notifyVue(verticalAlign, horizontalAlign, msg, type) {
      const color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
        component: NotificationTemplate,
        message: msg,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: type, //this.type[color],
        timeout: 3000
      });
    },
    async getRedeemData(){
      let id = this.$route.params.id;
      await this.$store.dispatch('getRedeem',id);
      let redeem = this.$store.state.redeem;
      console.log("redeem in getRedeemDataà() ====>", redeem);
      this.redeem = redeem;
    },
    chooseTemplate(val){
      console.log("value to choose", val);
      this.templateName = val;
    },
    chooseReward(reward){
      console.log("reward value to choose", reward);
      this.redeem.rewardId = reward;
    },
    async saveChoosingReward(){
      let wallet = this.wallet;
      let oldReward = this.oldReward;
      if(Object.keys(wallet).length > 0){
        if(Object.keys(oldReward).length > 0){
          if(oldReward._id == this.redeem.rewardId._id){
            let data = {
              customerId : this.redeem.customerId._id,
              rewardId: this.redeem.rewardId._id,
              storeId: this.redeem.rewardId.storeId._id,
              status : this.redeem.status
            };
            let id = this.$route.params.id;
            let res = await this.$store.dispatch('updateRedeem',{id,data});
            console.log('COMP: res update redeem and wallet with points:::', res);
            if(res.status == 200){
              this.notifyVue('bottom', 'center', 'Redeem uppdated successfully', 'success');
              this.$router.push({ name: 'redeems' });
            }else{
              this.notifyVue('bottom', 'center', 'Error', 'danger');
            }
          }else{
            let sumPoints = oldReward.points + wallet.points;
            if(sumPoints >= this.redeem.rewardId.points){
              let data = {
                customerId : this.redeem.customerId._id,
                rewardId: this.redeem.rewardId._id,
                storeId: this.redeem.rewardId.storeId._id,
                status : this.redeem.status
              };
              let id = this.$route.params.id;
              let res = await this.$store.dispatch('updateRedeem',{id,data});
              console.log('COMP: res update redeem and wallet with points:::', res);
              if(res.status == 200){
                this.notifyVue('bottom', 'center', 'Redeem uppdated successfully', 'success');
                this.$router.push({ name: 'redeems' });
              }else{
                this.notifyVue('bottom', 'center', 'Error', 'danger');
              }
            }else{
              this.notifyVue('bottom', 'center', 'Insufficient points to redeem this Reward', 'danger');
            }
          }
        }
        
      }
      
    }
  }
}
</script>

<style> 
    

    .redeem-contain-reward .reward-card{
      width: 50% !important;
    }

    .redeem-details .label-change-reward{
      margin-right: 124px;
    }

    .btn-change-reward{
      border-color: #e14eca;
      color: #ba54f5;
      background-color: #1e1e29;
      border-radius: 0.4285rem;
      font-size: 13px;
      cursor: pointer;
      margin-bottom: 20px;
    }

    .vb-popup-comp{
      position: fixed;
      z-index: 53000;
      left: 0;
      transition: all .2s;
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      opacity: 1;
    }

    .vb-popup-comp .vb-popup-background{
      left: 0;
      width: 100%;
      background: rgba(0,0,0,.4);
      position: absolute;
      top: 0;
      height: 100%;
      z-index: 10;
      transition: all .25s ease;
      opacity: 1;
    }

    .vb-popup-content .buttom-popup{
      position: fixed;
      bottom: 0;
      padding: 20px 0;
      /* margin-bottom: 30px; */
      /* bottom: 10px; */
      text-align: right;
      right: 29%;
      bottom: 10%;
    }

    .vb-popup-content .buttom-popup .save-checked-reward{
      /* position: absolute;
      right: 0; */
      /* position: fixed;
      bottom: 2%; */
    }

    .vb-popup-comp .vb-popup{
      background: #1e1e2e;
      transition: all .2s;
      z-index: 100;
      width: 600px;
      margin: 10px;
      max-width: calc(100% - 30px);
      max-height: calc(100% - 140px );
      border-radius: 6px;
      box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
      background: #1e1e2e;
      animation: rebound .3s;
      position: relative;
    }

    .vb-popup-comp .vb-popup .vs-popup-heade{
      width: 100%; 
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      justify-content: space-between;
      background: rgba(0,0,0,.05);
      border-radius: 5px 5px;
      position: relative;
    }

    .vb-popup-comp .vb-popup .vs-popup-header .vb-popup-title{
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 5px 5px 0 0;
    }

    .vb-popup-comp .vb-popup .vs-popup-header .vb-popup-title h3{
      margin-bottom: 0;
      transition: all .23s ease .1s;
      padding: 8px;
      padding-left: 12px;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.2;
      color: #fff;
    }

    .vb-popup-comp .vb-popup .vb-popup-content{
      padding: 1rem;
      font-size: 1rem;
      margin-right: 5px;
      padding: 13px;
      transition: all .23s ease .1s;
      overflow: auto;
      max-height: calc(100vh - 190px);
      margin-top: 5px;
      margin-bottom: 5px;
      width: calc(100% - 5px);
    }

    .vb-popup-comp .vb-popup .vb-popup-content #rewards-selector .container-rewards-selctor{
      padding: 0!important;
      margin-bottom: 5rem!important;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
    }

    .vb-popup-comp .vb-popup .vb-popup-content
     #rewards-selector .container-rewards-selctor h6{
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
    }

    /* .container-rewards-selctor .reward-card{
      width: 90%!important;
    }  */
    
    .container-rewards-selctor .rewards-items .item-reward{
      width: 48%;
      margin-right: 10px;
      margin-bottom: 17px;
    }

    .container-rewards-selctor .reward-card{
      width: 100%!important;
    }

    .container-rewards-selctor .vs-con-input-label{
      position: relative;
      transition: all .3s ease;
    }

    .container-rewards-selctor .vs-con-input-label .vs-con-input{
      display: flex;
      -webkit-box-align: start;
      align-items: flex-start;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      position: relative;
      -webkit-box-pack: center;
      justify-content: center;
    }

    .container-rewards-selctor .vs-con-input-label .vs-con-input .vs-inputx{
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 0.7rem!important;
      font-size: 1rem!important;
      color: inherit;
      position: relative;
      border-radius: 5px;
      box-sizing: border-box;
      box-shadow: 0 0 0 0 rgb(0 0 0 / 15%);
      transition: all .3s ease;
      width: 100%;
    }

    .container-rewards-selctor .vs-con-input-label .vs-con-input .span-placeholder{
      padding: 0.7rem!important;
      padding-left: 3rem!important;
      left: 0;
      top: 0;
      transition: all .2s ease;
      position: absolute;
      border-radius: 5px;
      padding: 0.4rem;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      font-size: .85rem;
      color: rgba(0,0,0,.4);
      white-space: nowrap;
      cursor: text;
      user-select: none;
      pointer-events: none;
    }

    .show-more-in-popup{
      text-align: center;
      margin-top: 70px;
    }
    
    .show-more-in-popup .show-more-text{
      color: #d250da;
      cursor: pointer;
    }

    .close-popup-rewards{
      width: 7%;
      height: 9%;
      background: #dfe6e9;
      text-align: center;
      position: absolute;
      top: -20px;
      z-index: 1200;
      right: -17px;
      border-radius: 6px;
      cursor: pointer;
    }

</style>