<template>
  <div>
    <h1>Hello</h1>
    <div>
      <button style="cursor:pointer" @click="timeApproach()">Time approach</button>
    </div>
  </div>
</template>

<script>
export default { 
  data(){
    return{
      
    }
  },
  methods:{

    timeApproach(){
      var events = [
        {
          event: "Event1",
          times:[
            200000, //ms
            1200000 //ms
          ]
        },
        {
          event: "Event2",
          times:[
            700000, //ms
            1300000 //ms
          ]
        },
        {
          event: "Event3",
          times:[
            900000
          ]
        },
        {
          event: "Event4",
          times:[
            800000
          ]
        },
        {
          event: "Event5",
          times:[
            1000000
          ]
        },
        {
          event: "Event6",
          times:[
            1100000
          ]
        },
        {
          event: "Event7",
          times:[
            100000
          ]
        }
      ];

      var commands = [
        {
          command: 'command1',
          dateTimes:[
            '2022-04-05T23:07:00',
            '2022-04-05T23:12:00'
          ]
        },
        {
          command: 'command2',
          dateTimes:[
            '2022-04-05T23:15:00'
          ]
        },
        {
          command: 'command3',
          dateTimes:[
            '2022-04-05T23:17:00'
          ]
        },
        {
          command: 'command4',
          dateTimes:[
            '2022-04-05T23:08:00'
          ]
        },
        {
          command: 'command5',
          dateTimes:[
            '2022-04-05T23:09:00'
          ]
        },
        {
          command: 'command6',
          dateTimes:[
            '2022-04-05T23:19:00'
          ]
        },
        {
          command: 'command7',
          dateTimes:[
            '2022-04-05T23:22:00'
          ]
        },
        {
          command: 'command8',
          dateTimes:[
            '2022-04-05T23:25:00'
          ]
        },
      ]

      console.log('Wait 6 minutes for commands');
      //check Commands will be called every 5 minutes
      setInterval(() => {
        var dateNow = new Date();
        var closeCommandsLessThanTen = [];
        console.log("is checking now ....wait for commands ...");
        setInterval(() => {
          dateNow = new Date();
          for(let p=0;p<commands.length;p++){
            for(let t=0; t<commands[p].dateTimes.length; t++){
              var dateNowPlusTen = new Date(dateNow.getTime() + 10*60000);
              if(new Date(commands[p].dateTimes[t]).getTime() > dateNow.getTime() &&  
              new Date(commands[p].dateTimes[t]).getTime() < dateNowPlusTen.getTime() &&
               dateNow.getFullYear() == new Date(commands[p].dateTimes[t]).getFullYear() && 
                dateNow.getMonth() == new Date(commands[p].dateTimes[t]).getMonth() &&
                dateNow.getHours() == new Date(commands[p].dateTimes[t]).getHours() && 
                dateNow.getDate() == new Date(commands[p].dateTimes[t]).getDate() ) {
                  console.log('checking...');
                  closeCommandsLessThanTen.push(commands[p]);
                  console.log('1___Commands less than 10 min ====>',closeCommandsLessThanTen);
                  //after 2 minutes
                  setTimeout(() => {
                    dateNow = new Date();
                    dateNowPlusTen = new Date(dateNow.getTime() + 10*60000);
                    console.log('checking...');
                    //3 minutes, it checks every minute.
                    setInterval(() => {
                      dateNow = new Date();
                      dateNowPlusTen = new Date(dateNow.getTime() + 10*60000);
                      console.log('checking...');
                      //every min
                      setInterval(() => {
                        var timeOver = 0;
                        dateNow = new Date();
                        dateNowPlusTen = new Date(dateNow.getTime() + 10*60000);
                        console.log('checking...');
                        for(let s=0; s<commands.length; s++){
                          for(let e=0; e<commands[s].dateTimes.length; e++){
                            if(new Date(commands[s].dateTimes[e]).getTime() > dateNow.getTime() &&  
                            new Date(commands[s].dateTimes[e]).getTime() < dateNowPlusTen.getTime() &&
                            dateNow.getFullYear() == new Date(commands[s].dateTimes[e]).getFullYear() && 
                              dateNow.getMonth() == new Date(commands[s].dateTimes[e]).getMonth() &&
                              dateNow.getHours() == new Date(commands[s].dateTimes[e]).getHours() && 
                              dateNow.getDate() == new Date(commands[s].dateTimes[e]).getDate() ){
                                closeCommandsLessThanTen.push(commands[s]);

                                console.log('2___Commands less than 10 min ====>',closeCommandsLessThanTen);
                              }
                          }
                        }
                        timeOver = timeOver + 60000;
                        if(timeOver == 120000){
                          dateNow = new Date();
                          dateNowPlusTen = new Date(dateNow.getTime() + 10*60000);
                          //every 10 sec
                          setInterval(() => {
                            console.log('checking...');
                            dateNow = new Date();
                            dateNowPlusTen = new Date(dateNow.getTime() + 10*60000);
                            for(let g=0; g<commands.length; g++){
                              for(let h=0; h<commands[g].dateTimes.length; h++){
                                if(new Date(commands[g].dateTimes[h]).getTime() > dateNow.getTime() &&  
                                new Date(commands[g].dateTimes[e]).getTime() < dateNowPlusTen.getTime() &&
                                dateNow.getFullYear() == new Date(commands[g].dateTimes[h]).getFullYear() && 
                                  dateNow.getMonth() == new Date(commands[g].dateTimes[h]).getMonth() &&
                                  dateNow.getHours() == new Date(commands[g].dateTimes[h]).getHours() && 
                                  dateNow.getDate() == new Date(commands[g].dateTimes[h]).getDate() ){
                                    closeCommandsLessThanTen.push(commands[g]);
                                    console.log('3___Commands less than 10 min ====>',closeCommandsLessThanTen);
                                  }
                              }
                            }
                          }, 10000);
                        }
                      }, 60000);
                    }, 240000);
                    
                  }, 120000);
                }
            }
          }
        }, 60000);
      }, 300000);

      console.log('Wait for 9 minutes events');

      //This is the events will be called in their time
      setTimeout(()=>{
        setTimeout(()=>{
          console.log('event 1 called',events[0].event);
        },events[0].times[0]);

        setTimeout(()=>{
          console.log('event 2 called',events[1].event);
        },events[1].times[0]);

        setTimeout(()=>{
          console.log('event 3 called',events[2].event);
        },events[2].times[0]);

        setTimeout(()=>{
          console.log('event 4 called',events[3].event);
        },events[3].times[0]);

        setTimeout(()=>{
          console.log('event 5 called',events[4].event);
        },events[4].times[0]);

        setTimeout(()=>{
          console.log('event 6 called',events[5].event);
        },events[5].times[0]);

        setTimeout(()=>{
          console.log('second event 1 called',events[0].event);
        },events[0].times[1]);

        setTimeout(()=>{
          console.log('second event 2 called',events[1].event);
        },events[1].times[1]);

        setTimeout(()=>{
          console.log(' event 7 called',events[6].event);
        },events[6].times[0]);
      },500000);


      //check events will be called every 5 minutes
      var oldTime = 0;
      var toIncreaseTime = 1000000;
      setInterval(function() {
          //events will be called less than 10min
          var eventsWillBeCalled = [];
          //check every minute to see if there is an event to be called in less than 10 minutes
          setInterval(function() {
              for(let i=0; i<events.length; i++){
                for(let j=0; j<events[i].times.length;j++){
                  if(events[i].times[j] < toIncreaseTime && events[i].times[j] > oldTime){
                    eventsWillBeCalled = [];
                    eventsWillBeCalled.push(events[i]);
                    console.log('events will be called less than 10 min is 1 ========>',eventsWillBeCalled );

                    //after 2 minutes
                    setTimeout(()=>{
                      oldTime = oldTime + 200000;
                      toIncreaseTime = toIncreaseTime + 200000;
                      //3 minutes, it checks every minute.
                      setInterval(function() {
                        var timeOver = 0;
                        //every minute.
                        setInterval(function() {
                          for(let k=0; k<events.length; k++){
                            for(let l=0; l<events[k].times.length;l++){
                              if(events[k].times[l] < toIncreaseTime && events[k].times[l] > oldTime){
                                eventsWillBeCalled = [];
                                eventsWillBeCalled.push(events[k]);
                                console.log('events will be called less than 10 min is 2 ========>',eventsWillBeCalled );
                              }
                            }
                          }
                          oldTime = oldTime + 100000;
                          toIncreaseTime = toIncreaseTime + 100000;
                          timeOver = timeOver + 100000;
                          // when there is a bit over 1 minute, it checks every 10 seconds
                          if(timeOver == 200000){
                            setInterval(function() {
                              for(let m=0; m<events.length; m++){
                                for(let n=0; n<events[m].times.length;n++){
                                  eventsWillBeCalled = [];
                                  eventsWillBeCalled.push(events[m]);
                                  console.log('events will be called less than 10 min is 3 ========>',eventsWillBeCalled );
                                }
                              }
                              oldTime = oldTime + 10000;
                              toIncreaseTime = toIncreaseTime + 10000;
                            },10000);
                          }
                        }, 100000);
                        
                      }, 300000);
                    },200000);
                  }
                }
              }
              oldTime = oldTime + 100000;
              toIncreaseTime = toIncreaseTime + 100000;
          }, 100000);
          console.log('do something');
      }, 500000);

      //500000

    },
  }

}
</script>

<style>

</style>