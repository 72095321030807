<template>
  <div>
    <div v-if="isLoading" class="loading-abs">
      <main ref="canvasContainer"></main>
    </div>
    <div>
      <h1 v-if="isSuccess">Payment has been processed successfully</h1>
      <h1 v-else>Payment Error</h1>
    </div>
  </div>
</template>

<script>
import * as p5 from 'p5';

export default {
  data() {
    return {
      spinnerSize: 120,
      spinnerSpeed: 10,
      spinnerColor: null,
      isLoading: true,
      isSuccess: false
    };
  },
  async mounted() {
    this.isLoading = true;
    this.setupCanvas();
    this.startAnimation();
    window.addEventListener('resize', this.windowResized);
    const fullUrl = this.$route.fullPath;

    // Extract the query string portion
    const queryString = fullUrl.split('?')[1] || null;
    console.log('Query params ====>', queryString);
    let res = await this.$store.dispatch('loadSuccessSubscription',queryString);
    if(res.status == 200){
      this.isLoading = false;
      this.isSuccess = true;
      this.$store.dispatch('expiredPopup', false );
    }else{
      this.isLoading = false;
      this.isSuccess = false;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResized);
  },
  methods: {
    setupCanvas() {
      this.canvas = this.$refs.canvasContainer;
      this.p5 = new p5(this.sketch, this.canvas);
      this.spinnerColor = this.p5.color(33, 150, 243);
    },
    startAnimation() {
      this.p5.loop();
    },
    stopAnimation() {
      this.p5.noLoop();
    },
    windowResized() {
      this.p5.resizeCanvas(this.p5.windowWidth, this.p5.windowHeight);
    },
    sketch(p) {
      p.setup = () => {
        p.createCanvas(p.windowWidth, p.windowHeight);
      };

      p.draw = () => {
        p.background(255);

        let step = p.frameCount % (this.spinnerSpeed * 7.25);
        let angle = p.map(step, 0, this.spinnerSpeed * 7.25, 0, p.TWO_PI);

        p.push();
        p.translate(p.width / 2, p.height / 2);
        p.rotate(angle);
        p.noFill();
        p.stroke(this.spinnerColor);
        p.strokeWeight(this.spinnerSize / 10);
        p.strokeCap(p.SQUARE);
        p.arc(0, 0, this.spinnerSize - (this.spinnerSize / 20), this.spinnerSize - (this.spinnerSize / 20), 0, p.PI + p.HALF_PI, p.OPEN);
        p.pop();
      };

      p.windowResized = () => {
        p.resizeCanvas(p.windowWidth, p.windowHeight);
      };
    },
  },
};
</script>

<style scoped>
    .loading-abs{
        position: absolute;
        background-color: #fff;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10000;
    }

    html,
    body {
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    canvas {
        display: block;
    }
</style>