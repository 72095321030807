<template>
  <div class="newStroe row">
    <div class="col-sm-4">
      <h4>
        <span v-if="!isEdit">New point Range</span> 
        <span v-if="isEdit">Edit point Range</span> 
      </h4>
    </div>
    <div class="col-sm-8">
      <card>
        <form @submit.prevent>
          <div>
            <base-input label="Select Store " class="base-input">
                <select v-model="interval.storeId" @change="changeStore()" style="cursor:pointer;" class="form-control point-stores" id="exampleFormControlSelect1">
                  <option :value="store._id" v-for="(store,index) in stores" :key="index">{{store.name}}</option>
                </select>
            </base-input>
          </div>
          <p class="error-msg">{{ pointsErrors.minError }}</p>
          <base-input label="Minimum"
                      type="number"
                      step="any"
                      v-model="interval.minPrice"
                      placeholder="min"
                      >
          </base-input>

          <p class="error-msg">{{ pointsErrors.maxError }}</p>
          <base-input label="Maximum"
                      type="number"
                      step="any"
                      v-model="interval.maxPrice"
                      placeholder="max"
                      >
          </base-input>
          <base-input label="Points"
                      type="number"
                      v-model="interval.points"
                      placeholder="point"
                      >
          </base-input>
           
        <base-button native-type="submit" @click="saveInterval()" type="primary"><span v-if="!isEdit">Add</span><span v-else>Update</span></base-button>
        </form>
      </card>
  </div>
  </div>
</template>

<script>

import NotificationTemplate from '@/pages/Notifications/NotificationTemplate'; 

export default {
    components:{
      NotificationTemplate
    },
    data(){
      return{
        isEdit: false,
        isThisRangeIsMax: false,
        isRangeAvailable: true,
        interval:{
          storeId : '',
          minPrice:0,
          maxPrice:0,
          points:0
        },
        maximum:0,
        pointsErrors:{
          minError: '',     
          maxError: ''       
        },
      }
    },
    async mounted() {
      await this.$store.dispatch('loadStores');
      if(this.$route.params.hasOwnProperty('id')) this.isEdit = true;
      if(!this.isEdit) {
        let selectedStoreIndex = localStorage.getItem("selectedStoreIndex");
        if(selectedStoreIndex == 'all') this.interval.storeId = this.stores[0]._id;
        else this.interval.storeId = this.stores[selectedStoreIndex]._id;
      }
      if(this.isEdit) await this.setData();
      this.getGreater();
      const storeId = this.interval.storeId;
      await this.$store.dispatch('loadPoints',{
        storeId: storeId
      });
    },
    computed:{
      stores(){
        return this.$store.state.stores 
      },
      points(){
        return this.$store.state.points;
      }
    },
    methods:{
      notifyVue(verticalAlign, horizontalAlign, msg, type) {
        const color = Math.floor(Math.random() * 4 + 1);
        this.$notify({
            component: NotificationTemplate,
            message: msg,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: type, //this.type[color],
            timeout: 3000
        });
      },
      async getGreater(){
        const storeId = this.interval.storeId;
        console.log('storedid greater ===>', storeId);
        const res = await this.$store.dispatch('getGreaterMax',storeId);
        if(res.data.greater_point !== null){
          if(this.isEdit){
            const id = this.$route.params.id;
            if(res.data.greater_point._id == id ) this.isThisRangeIsMax = true;
          }
          this.maximum = res.data.greater_point.maxPrice;
          if(!this.isEdit) this.interval.minPrice = this.maximum + 0.1;
        }else{
          //if(!this.isEdit) 
          this.maximum = 0;
          this.interval.minPrice = this.maximum + 0.1;
        }
      },
      async changeStore(){
        const storeId = this.interval.storeId;
        await this.$store.dispatch('loadPoints',{
          storeId: storeId
        });
        this.getGreater();
        //if(!this.isEdit) 
      },
      async checkInterval(){
        this.isRangeAvailable = true;
        const id = this.$route.params.id;
        let min = parseFloat(this.interval.minPrice);
        let max = parseFloat(this.interval.maxPrice);
        if(this.points.length > 0){
          if(!this.isEdit){
            this.points.forEach(point => {
              if((min >= point.minPrice && min <= point.maxPrice) || (max >= point.minPrice && max <= point.maxPrice ) ){
                this.isRangeAvailable = false;
              }
            });
          }else{
            this.points.forEach(point => {
              if(point._id !== id ){
                console.log('ponts id ===>', point.id, 'ID params ===>', id);
                if((min >= point.minPrice && min <= point.maxPrice) || (max >= point.minPrice && max <= point.maxPrice ) ){
                  this.isRangeAvailable = false;
                }
              }
            });
          }
        }
        
      },
      async setData(){
        const id = this.$route.params.id;
        const res = await this.$store.dispatch('getPointInterval',id);
        console.log('res setData',res);
        this.interval.storeId = res.data.storeId._id;
        this.interval.minPrice = res.data.minPrice;
        this.interval.maxPrice = res.data.maxPrice;
        this.interval.points = res.data.points;
      },
      async createPoint(){
        this.checkInterval();
        this.pointsErrors.minError = '';
        this.pointsErrors.maxError = '';
        //if(this.interval.minPrice >this.maximum && this.interval.maxPrice >= this.interval.minPrice ){
        if(this.isRangeAvailable && parseFloat(this.interval.maxPrice) > parseFloat(this.interval.minPrice)){
          console.log('before create interval ===>',this.interval)
          let interv = {
            minPrice: parseFloat(this.interval.minPrice), 
            maxPrice: parseFloat(this.interval.maxPrice),
            storeId: this.interval.storeId,
            points: parseInt(this.interval.points)
          };
          let res = await this.$store.dispatch('createPointInterval', interv);
          if(res.status == 200){
            this.notifyVue('bottom', 'center', 'Point interval created successfuly', 'success');
            this.$router.push({ name: 'points' });
          }
          else this.notifyVue('bottom', 'center', 'Error create points range', 'danger');
        }else{
          if(parseFloat(this.interval.maxPrice) < parseFloat(this.interval.minPrice)){
            this.notifyVue('bottom', 'center', 'Invalid Range', 'danger');
            this.pointsErrors.maxError = 'Max price must be greater than the Minimum Price or equal';
          }else{
            this.notifyVue('bottom', 'center', 'This range is already exist', 'danger');
            //this.pointsErrors.minError = 'This Min price has already been set in other range';
          }
          // if(this.interval.minPrice == this.maximum || this.interval.minPrice < this.maximum){
          //   this.pointsErrors.minError = 'This Min price has already been set in other range';
          // }
          // if(this.interval.maxPrice < this.interval.minPrice){
          //   this.pointsErrors.maxError = 'Max price must be greater than the Minimum Price or equal';
          // }
        }
      },
      async updatePoint(){
        this.checkInterval();
        this.pointsErrors.minError = '';
        this.pointsErrors.maxError = '';
        const id = this.$route.params.id;
        const interval = this.interval;
        let interv = {
          minPrice: parseFloat(this.interval.minPrice),
          maxPrice: parseFloat(this.interval.maxPrice),
          storeId: this.interval.storeId,
          points: parseInt(this.interval.points)
        };
        //if(this.isThisRangeIsMax && (this.interval.minPrice >=this.maximum || this.interval.minPrice <=this.maximum) && this.interval.maxPrice >= this.interval.minPrice ){
        if(this.isRangeAvailable && parseFloat(this.interval.maxPrice) > parseFloat(this.interval.minPrice)){
          let res = await this.$store.dispatch('updatePointInterval', {id,interv});
          if(res.status == 200 ) this.notifyVue('bottom', 'center', 'Point interval updated successfuly', 'success');
          else this.notifyVue('bottom', 'center', 'Error update points range', 'danger');
        }else{
          if(parseFloat(this.interval.maxPrice) < parseFloat(this.interval.minPrice)){
            console.log('interval ===>', parseFloat(this.interval.minPrice));
            this.notifyVue('bottom', 'center', 'Invalid Range', 'danger');
            this.pointsErrors.maxError = 'Max price must be greater than the Minimum Price or equal';
          }else{
            this.notifyVue('bottom', 'center', 'this range is already reserved', 'danger');
            //this.pointsErrors.minError = 'This Min price has already been set in other range';
          }
        }
        //if(!this.isThisRangeIsMax)
      },
      saveInterval(){
        if(!this.isEdit) this.createPoint();
        else this.updatePoint();
      },
    }
}
</script>

<style>
  .point-stores option{
    color: #000;
  }

  .error-msg{
    margin-bottom: 5px;
    font-size: 11px;
    color: #d74343 !important;
  }

</style>