<template>
  <div class="stores">
    <card style="width: 20rem;">
      <div class="text-center thisCard" >
        <router-link  :to="{ path: 'giftcards/addGiftCard' }">
          <i class="tim-icons icon-bag-16 icon-bag-40 mb-3 mb-icn icn-pointer"></i>
        </router-link >
        <div class="d-flex justify-content-center">
          <i class="tim-icons tim-icons-16 icon-simple-add this-mt"></i>
          <h4  class="card-title title-new">Create New Gift Card</h4>
        </div>
      </div>
    </card>
  </div>
</template>

<script>

import Card from "@/components/Cards/Card";

export default {
  components: {
    Card
  }
}
</script>

<style>
  .tim-icons{
    color: #fff;
  }

  .icon-bag-40{
    font-size: 40px;
  }

  .icn-pointer{
    cursor: pointer !important;
  }

  .mb-icn{
    margin-bottom: 10px;
  }

  .title-new{
    margin-left: 10px;
  }

  .this-mt{
    margin-top: 4px;
  }

  .thisCard{
    padding: 25px;
    cursor: pointer;
  }
</style>
