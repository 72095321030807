<template>
  <div style="height: 100%;">
    <notifications v-if="isLoggedIn && routeName!='signup'"></notifications>
    <router-view v-if="isLoggedIn " :key="$route.fullPath"></router-view>
    
    <Login v-if="!isLoggedIn && routeName!='signup'" /> 
    <Signup v-if="routeName=='signup'" /> 
  </div>
  
</template> 

<script>

import Login from "@/components/Login.vue";
import BaseButton from  "@/components/BaseButton.vue";
import Signup from '@/components/Signup.vue';

  export default {
    components: {
      Login,
      BaseButton,
      Signup
    },
    computed:{
      isLoggedIn(){
        return this.$store.getters.isLoggedIn
      },
      isValidToken(){
        return this.$store.state.isValidToken;
      },
      routeName(){
        return this.$route.name
      },
      me(){
        return this.$store.state.me
      },
    },
    methods: {
      logout(){
        //if(window.location.pathname !== '/signup'){
        window.localStorage.clear();
        // Check if the hostname is 'admin.rewardisfy.com'
        if (window.location.hostname === 'admin.rewardisfy.com') {
          window.location.replace("https://admin.rewardisfy.com/login");
        } else {
          window.location.replace("http://localhost:8080/login");
        }
        //}
      },
      disableRTL() {
        if (!this.$rtl.isRTL) {
          this.$rtl.disableRTL();
        }
      },
      toggleNavOpen() {
        let root = document.getElementsByTagName('html')[0];
        root.classList.toggle('nav-open');
      }
    },
    async mounted() {

      let token = '';
      token = localStorage.getItem('loyalty-auth-token') || '';
      //check token
      let data = {
        token : token
      }
      const path = window.location.pathname;

      console.log('PATH =======',path );
      if(path !== '/login' && path !== '/signup'){
        if(token !== '' ){
          await this.$store.dispatch('validateToken', data);
          if(!this.isValidToken){
            this.logout();
          }
        }else{
          this.logout();
        }
      }
      this.$store.dispatch('setSelectedStore');
      await this.$store.dispatch('loadMe');
      let accountId = this.me.accountId;
      await this.$store.dispatch('getAccount', accountId);
      //console.log('route ===>',this.$router.history.current.path);

      this.$watch('$route', this.disableRTL, { immediate: true });
      this.$watch('$sidebar.showSidebar', this.toggleNavOpen)
    }
  };
</script>

<style lang="scss">

html,body{
    height: 100%;
}

.filters-bar .str-filters select option{
  color: #000;
}

.iti__country-name{
    color:#000 !important;
  } 

.iti--allow-dropdown{
  width: 100%;
}

</style>
