<template>
  <div style="position:realative;">
    <div v-if="showAddOrder" class="order-popup">
      <div @click="showAddOrder = false" class="close-popup">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style="&#10;    width: 25px;&#10;    height: 25px;&#10; margin-top: 7px;"><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
      </div>
      <card>
        <form @submit.prevent> 
          <div class="d-flex">
            <h4 class="mr-2 order-for-title">Order for:</h4>
            <span class="pt-1 order-for-value">{{ selectedCustomer.phone }}</span>
          </div>
          <base-input label="amount"
            type="number"
            v-model="amount"
            placeholder="amount"
            >
          </base-input>
          <base-button @click="createOrder()" native-type="submit" type="primary">Save</base-button>
        </form>
      </card>
    </div>
    <div class="filters-bar">
      <div>
        <div class="d-flex ml-auto align-items-center str-filters">
          <span>Select Store :</span>
          <base-input class="base-input">
            <select style="cursor:pointer;" v-model="selectedStore" class="form-control" id="exampleFormControlSelect1">
              <option value="all">All</option>
              <option :value="store._id" v-for="(store,index) in stores" :key="index">{{store.name}}</option>
            </select>
          </base-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card style="position: relative;" title="Customers">
          <i class="fa-solid fa-filter fa-lg" @click="isFilters = !isFilters"
          style="color: #e14eca; position: absolute; position: absolute;
          right: 2%;
          top: 7%;
          cursor: pointer;">
             </i>
          <div v-if="isFilters" class="form">
            <div class="row">
              <div class="col-8">
                <base-input label="Search" v-model="searchQuery" type="text" placeholder="name, email, phone number"/>
              </div>
              <div class="col-4 pt-3">
                <base-button @click="searchBy()" simple type="primary">Apply</base-button>
              </div>
            </div>
          </div>
          <div class="d-flex actions-bar">
            <base-button @click="newCustomer()" class="ml-auto" type="primary" >New customer</base-button>
          </div>
          <div class="table-responsive">
            <base-table :data="table1.data"
                        :columns="table1.columns"
                        :showAddOrder="showAddOrder"
                        :selectedCustomer="selectedCustomer"
                        v-on:emitToEdit="editCustomer($event)"
                        v-on:emitToDelete="deleteCustomer($event)"
                        v-on:emitShowOrderPopup="shopOrderPopup($event)"
                        thead-classes="text-primary">
            </base-table>
            <Pagination
              :currentPage="pagination.currentPage"
              :totalPages="pagination.totalPages"
              :totalRecords="pagination.totalRecords"
              @updatePage="handlePageChange"
            />
          </div>
        </card>
      </div>

      <!--<div class="col-12">
        <card class="card-plain">
          <div class="table-full-width table-responsive">
            <base-table :title="table2.title" :sub-title="table2.subTitle" :data="table2.data"
                         :columns="table2.columns">

            </base-table>
          </div>
        </card>
      </div>-->

    </div>
  </div>
    
</template>
<script>
import { BaseTable } from "@/components";
import NotificationTemplate from '@/pages/Notifications/NotificationTemplate';
import Pagination from "@/components/Pagination.vue";
const tableColumns = ["firstname","lastname", "email", "phone", "points", "button" , "actions"];
const tableData = [
  {
    id: 1,
    name: "Dakota Rice",
    email: "$36.738",
    phone: "Niger",
    points: "Oud-Turnhout",
  },
  {
    id: 2,
    name: "Minerva Hooper",
    email: "$23,789",
    phone: "Curaçao",
    points: "Sinaai-Waas"
  },
  {
    id: 3,
    name: "Sage Rodriguez",
    email: "$56,142",
    phone: "Netherlands",
    points: "Baileux"
  },
  {
    id: 4,
    name: "Philip Chaney",
    email: "$38,735",
    phone: "Korea, South",
    points: "Overland Park"
  },
  {
    id: 5,
    name: "Doris Greene",
    email: "$63,542",
    phone: "Malawi",
    points: "Feldkirchen in Kärnten"
  },
  {
    id: 6,
    name: 'Mason Porter',
    email: '$98,615',
    phone: 'Chile',
    points: 'Gloucester'
  },
  {
    id: 7,
    name: 'Jon Porter',
    email: '$78,615',
    phone: 'Portugal',
    points: 'Gloucester'
  }
];

export default {
  components: {
    BaseTable,
    NotificationTemplate,
    Pagination
  },
  data() {
    return {
      isFilters:false,
      searchQuery: '',
      limit:10,
      page:1,
      pagination: {
        currentPage: 1,
        totalPages: 1,
        totalRecords: 0
      },
      table1: {
        title: "Simple Table",
        columns: [...tableColumns],
        data: [] //[...tableData]
      },
      table2: {
        title: "Table on Plain Background",
        columns: [...tableColumns],
        data: [...tableData]
      },
      customersWallet:[
        
      ],
      selectedStore : 'select_store',
      amount: 0,
      showAddOrder : false,
      selectedCustomer:{}
    };
  },
  async mounted() {
    await this.$store.dispatch('loadStores');
    let selectedStoreIndex = localStorage.getItem("selectedStoreIndex");
    console.log('selected store index_____',selectedStoreIndex);
    if(selectedStoreIndex == 'all') this.selectedStore = 'all';
    else this.selectedStore = this.stores[selectedStoreIndex]._id;
  },
  computed:{
    stores(){
      return this.$store.state.stores 
    },
    ourCustomers(){
      return this.$store.state.customers;
    },
    customers_info(){
      return this.$store.state.customers_info;
    }
  },
   watch:{
    async selectedStore(newVal,oldVal){
      for (let index = 0; index < this.stores.length; index++) {
        const id_store = this.stores[index]._id;
        if(id_store == newVal) localStorage.setItem("selectedStoreIndex", index);
        if(newVal == 'all') localStorage.setItem("selectedStoreIndex", 'all');
      }
      let storeId = newVal;
      await this.$store.dispatch('loadCustomers', {
        storeId: storeId,
        page: this.page,
        limit: this.limit,
        searchQuery: this.searchQuery 
      });
      this.getData();
    }
  }, 
  methods:{
    notifyVue(verticalAlign, horizontalAlign, msg, type) {
        const color = Math.floor(Math.random() * 4 + 1);
        this.$notify({
            component: NotificationTemplate,
            message: msg,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: type, //this.type[color],
            timeout: 3000
        });
    },
    async searchBy(){
      let storeId = this.selectedStore;
      await this.$store.dispatch('loadCustomers', {
        storeId: storeId,
        searchQuery: this.searchQuery,
        page: this.page,
        limit: this.limit,
      });
      this.getData();
    },
    shopOrderPopup(val){
      console.log(val);
      this.selectedCustomer = val.selectedCustomer;
      console.log('this selected Custromer', this.selectedCustomer);
      this.showAddOrder = val.showOrderPop;
    },
    async createOrder(){
      let order = {
        price : this.amount,
        storeId: this.selectedCustomer.stores._id,
        customerId : this.selectedCustomer._id,
        currency: 'USD'
      }
      let res = await this.$store.dispatch('createNewOrder', order);
      let storeId = this.selectedCustomer.stores._id;
      await this.$store.dispatch('loadCustomers', {
        storeId: storeId,
        searchQuery: this.searchQuery,
        page: this.page,
        limit: this.limit
      });
      this.getData();
      this.showAddOrder = false;
      this.amount = 0; 
      if(res.status == 200){
        this.notifyVue('bottom', 'center', 'Order created successfuly', 'success');
      }
      else this.notifyVue('bottom', 'center', 'Error create order', 'danger');
    },
    editCustomer(id){
      console.log('to edit customer',id);
      this.$router.push(`/customers/update/${id}`);
    },
    async deleteCustomer(customerId){
      const res = await this.$store.dispatch('getCustomerWallet',customerId)
      const storeId = res.data.storeId;
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to delete this customer',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed!',
        cancelButtonText: 'Cancel',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          // User confirmed, perform the action
          let res = await this.$store.dispatch('removeCustomerWallet',{customerId,storeId});
          if(res.status == 200){
            this.notifyVue('bottom', 'center', 'Customer deleted successfuly', 'success');
            await this.$store.dispatch('loadCustomers', {
              storeId: this.selectedStore,
              searchQuery: this.searchQuery,
              page: this.page,
              limit: this.limit,
            });
            this.getData();
          }else{
            this.notifyVue('bottom', 'center', 'Error delete Customer', 'danger');
          }
        }
      });
    },
    newCustomer(){
      this.$router.push('/customers/create')
    },
    async getData(){
      //await this.$store.dispatch('loadCustomers');
      this.formData();
    },
    async formData(){
      this.table1.data = [];
      this.customersWallet = [];
      //if(this.customers && this.customers.length > 0){
        console.log('our customers :::',this.ourCustomers);
        this.ourCustomers.forEach(customer => {
              console.log('to put');
              // if(customer.customerId && customer.customerId._id){
                let cust = {
                  _id: (customer.customerId &&  customer.customerId._id ) ?   customer.customerId._id : '',
                  firstname:  (customer.customerId && customer.customerId.firstname ) ?  customer.customerId.firstname : '',
                  lastname: (customer.customerId &&  customer.customerId.lastname ) ?   customer.customerId.lastname : '', 
                  email:  (customer.customerId &&  customer.customerId.email ) ?   customer.customerId.email : '',
                  phone:  (customer.customerId &&  customer.customerId.phone ) ?   customer.customerId.phone : '',
                  stores: (customer.customerId &&  customer.customerId.stores ) ?   customer.customerId.stores[0] : '' ,
                  points:customer.points
                }
                this.customersWallet.push(cust);
              // }
        });
        this.table1.data = [...this.customersWallet]
        this.pagination.currentPage = this.customers_info.currentPage;
        this.pagination.totalPages = this.customers_info.totalPages;
        this.pagination.totalRecords = this.customers_info.totalRecords;
     // }
      
    },
    async handlePageChange(page) {
      let storeId = this.selectedStore;
      await this.$store.dispatch('loadCustomers', {
        storeId: storeId,
        searchQuery: this.searchQuery,
        page: page,
        limit: this.limit,
      });
      this.formData();
    }
  }
};
</script>
<style>

.actions-bar{
  margin-bottom: 29px;
}

.order-popup{
  position: absolute;
  z-index: 1000;
  left: 36%;
  top: 171px;
  width: 400px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 5px;
}

.close-popup{
  width: 40px;
  height: 40px;
  background: #dfe6e9;
  text-align: center;
  position: absolute;
  top: -20px;
  z-index: 1200;
  right: -17px;
  border-radius: 6px;
  cursor: pointer;
}

.order-popup .form-group .control-label{
  color:#1b1919 !important;
}

.order-popup .form-group .form-control{
  color: #000;
}

.order-popup .card-body{
  background:#fff;
}

.order-popup .order-for-value{
  color: #827e7e;
}

.order-for-title{
  color:#1b1919 !important;
}

</style>
