
import Vuex from 'vuex';
import Vue from "vue";
import Api from "../services/api";
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        name : 'aaaaa',
        stores: [],
        store:{},
        image:{},
        uploadProgress: 0,
        reward:{},
        rewards:[],
        customers:[],
        customers_info:{},
        customer: {},
        wallet:{},
        points:[],
        points_info:{},
        point:{},
        orders:[],
        orders_info:{},
        countRewards:0,
        countPoints:0,
        countStores:0,
        countCustomers:0,
        token: localStorage.getItem('loyalty-auth-token') || '',
        user:{},
        status:'',
        redeems: [],
        redeems_info:{},
        redeem:{},
        account:{},
        team:{},
        signupUser:{},
        me:{},
        myAccount:{},
        users:[],
        toUpdateUser: {},
        paymentSubs:{},
        paymentCard:{},
        payments: [],
        analytics:{},
        greaterMax: 0,
        showExpirePopup: false,
        isValidToken : false
    },
    getters:{
        isLoggedIn:function(state){
            if(state.token != '') return true;
            else return false
        },
        authState:state => state.status,
        user: state => state.user
    },
    mutations: {
        SET_VALID_TOKEN(state,isValidToken){
            state.isValidToken = isValidToken;
        },
        SET_SHOW_POPUP(state,showExpirePopup){
            state.showExpirePopup = showExpirePopup;
        },
        SET_STORES(state, stores){
            state.stores = stores;
        },
        SET_STORE(state,store){
            state.store = store;
        },
        SET_IMAGE(state,image){
            state.image = image;
        },
        SET_UPLOAD_PROGRESS(state, progress) {
            state.uploadProgress = progress;
        },
        SET_REWARD(state,reward){
            state.reward = reward;
        },
        SET_REWARDS(state,rewards){
            state.rewards = rewards;
        },
        SET_CUSTOMERS(state,customers){
            state.customers = customers;
        },
        SET_CUSTOMERS_INFO(state,customers_info){
            state.customers_info = customers_info;
        },
        SET_CUSTOMER(state, customer){
            state.customer = customer;
        },
        SET_WALLET(state,wallet){
            state.wallet = wallet;
        },
        SET_INTERVAL(state,point){
            state.point = point;
        },
        SET_POINTS(state,points){
            state.points = points;
        },
        SET_POINTS_INFO(state,points_info){
            state.points_info = points_info;
        },

        SET_ORDERS(state,orders){
            state.orders = orders;
        },
        SET_ORDERS_INFO(state,orders_info){
            state.orders_info = orders_info;
        },
        SET_ORDER(state,order){
            state.order = order;
        },
        SET_COUNT_REWARDS(state,count){
            state.countRewards = count;
        },
        SET_COUNT_ACTIVE_POINTS(state,count){
            state.countPoints = count;
        },
        SET_COUNT_STORES(state,count){
            state.countStores = count;
        },
        SET_COUNT_CUSTOMERS(state,count){
            state.countCustomers = count;
        },
        AUTH_SUCCESS(state,token,userInfo){
            state.token = token;
            state.user = userInfo;
            state.status = 'success'
        },
        SET_REDEEMS(state, redeems){
            state.redeems = redeems;
        },
        SET_REDEEMS_INFO(state, redeems_info){
            state.redeems_info = redeems_info;
        },
        SET_REDEEM(state, redeem){
            state.redeem = redeem;
        },
        SET_ACCOUNT(state,account){
            state.account = account;
        },
        SET_TEAM(state,team){
            state.team = team;
        },
        SET_SIGNUP_USER(state,user){
            state.user = user;
        },
        SET_UPDATE_USER(state,user){
            state.user = user;
        },
        SET_ME(state,me){
            state.me = me;
        },
        SET_MyAccount(state,myAccount){
            state.myAccount = myAccount;
        },
        SET_USERS(state,users){
            state.users = users;
        },
        SET_USER_TO_Update(state, toUpdateUser){
            state.toUpdateUser = toUpdateUser;
        },
        SET_PAY_SUBSCRIPTION(state, paymentSubs){
            state.paymentSubs = paymentSubs;
        },
        SET_PAY_BY_CARD(state, paymentCard){
            state.paymentCard = paymentCard;
        },
        SET_PAYMENTS(state, payments){
            state.payments = payments;
        },
        SET_ANALYTICS(state, analytics){
            state.analytics = analytics;
        },
        SET_GREATER_MAX(state, greaterMax){
            state.greaterMax = greaterMax;
        }
    },
    actions:{
        async login({commit},user){
            try{
                let response = await Api().post('/users/login',user);
                console.log('response login user ===>',response);
                const token = response.data.token;
                const userInfo = response.data.user;
                localStorage.setItem("loyalty-auth-token", token);
                axios.defaults.headers.common['Authorization'] = token;
                commit('AUTH_SUCCESS', token, userInfo);
                return response.data;
            }catch(error){
                console.log('error login user___',error);
                return error.response.data;
            } 
        },
        async validateToken({commit}, data){
            try{
                let response = await Api().post(`/validate-token`,data);
                console.log('response token ==>', response.data);
                commit('SET_VALID_TOKEN', response.data.success);
                return response.data;
            }catch(error){
                console.log('error token',error.response);
                commit('SET_VALID_TOKEN', error.response.data.success);
            }
        },
        setSelectedStore(){
            if (!localStorage.getItem("selectedStoreIndex")) {
                localStorage.setItem("selectedStoreIndex", 0);
            }
        },
        async loadStores({commit}){
            try{
                let response = await Api().get(`/stores/search`);
                commit('SET_STORES', response.data);
                console.log('response get stores ====>',response);
            }catch(error){
                console.log('error get stores ====>', error);
            }
        },
        async getOneStore({commit},id){
            try{
                let response = await Api().get(`/stores/get/${id}`);
                console.log('store is ====>',response.data );
                commit('SET_STORE', response.data);
                return response;
            }catch(error){
                console.log('error get one store', error);
            }
        },
        async loadRewards({commit},storeId){
            try{
                let response = await Api().get(`/rewards/search?storeId=${storeId}`);
                console.log('Rewards ====>',response.data );
                commit('SET_REWARDS', response.data);
                return response.data;
            }catch(error){
                console.log('error get rewards', error);
            }
        },
        async createNewStore({commit},store){
            console.log('store data in vuex', store);
            try{
                let response = await Api().post('/stores/create', store);
                //commit('SET_STORES', response.data);
                console.log('response create new store ====>',response);
                return response;
            }catch(error){
                console.log('error get stores ====>', error);
            }
        },
        async addNewStore({commit},store){
            try{
                let response = await Api().post('/stores/add', store);
                console.log('response add new store ====>',response);
                return response;
            }catch(error){
                console.log('error add stores ====>', error);
                return error.response;
            }
        },
        async updateStore({commit}, {id,data}){
            try{
                let response = await Api().patch(`/stores/update/${id}`,data);
                console.log('response status update store ===>', response);
                console.log('updated store',response.data);
                commit('SET_STORE', response.data);
                return response
            }catch(error){
                console.log('error update reward', error); 
            }
        },
        async deleteStore({commit}, id){
            try{
                let response = await Api().delete(`/stores/delete/${id}`);
                console.log('response delete store ===>', response);
                return response
            }catch(error){
                console.log('error delete store', error); 
                return error.response;
            }
        },
        async uploadImage({ commit }, image) {
            try {
              console.log('data body upload image ____', image);
              const formData = new FormData();
              formData.append('file', image);
          
              let response = await Api().post('/templates/upload', formData, {
                onUploadProgress: (progressEvent) => {
                  const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  commit('SET_UPLOAD_PROGRESS', progress);  // Commit the progress to Vuex state
                }
              });
          
              console.log('response upload image____', response);
              commit('SET_IMAGE', response.data);
            } catch (error) {
              console.log('error upload image ____', error);
            }
          },
          
        // async uploadImage({commit},image){
        //     try{
        //         console.log('data body upload image ____',image);
        //         const formData = new FormData();
        //         formData.append('file', image);
        //         let response = await Api().post('/templates/upload',formData);
        //         console.log('response upload image____', response)
        //         commit('SET_IMAGE', response.data);
        //     }catch(error){
        //         console.log('error upload image ____',error);
        //     }
        // },
        async createNewReward({commit}, reward){
            try{
                let response = await Api().post('/rewards/create',reward);
                console.log('response create reward ===>', response);
                commit('SET_REWARD', response.data);
                return {data:response.data,status:response.status};
            }catch(error){
                console.log('error create reward ====>', error);
            }
        },
        async getReward({commit}, id){
            try{
                let response = await Api().get(`/rewards/get/${id}`);
                console.log('Reward is ====>',response.data );
                commit('SET_REWARD', response.data);
                return response.data;
            }catch(error){
                console.log('error get one reward', error);
            }
        },
        async updateReward({commit}, {id,reward}){
            try{
                let response = await Api().patch(`/rewards/update/${id}`,reward);
                console.log('response status update reward ===>', response);
                console.log('updated reward____',response.data);
                commit('SET_REWARD', response.data);
                return {data:response.data,status:response.status};
            }catch(error){
                console.log('error update reward', error); 
            }
        },
        async removeReward({commit}, rewardId){
            try{
                let response = await Api().delete(`/rewards/delete/${rewardId}`);
                console.log('reward when it is deletetd ====>',response.data );
                return response;
            }catch(error){
                console.log('error delete reward', error);
                return error.response;
            }
        },
        async loadCustomers({commit}, { storeId, searchQuery, page, limit }){
            try{
                let response = await Api().get(`/wallets/search?stores=${storeId}&search=${searchQuery}&page=${page}&limit=${limit}`);
                console.log('response customers___',response);
                commit('SET_CUSTOMERS', response.data.wallets);
                commit('SET_CUSTOMERS_INFO', response.data);
            }catch(error){
                console.log('error get customers', error); 
            }
        },
        async createNewCustomer({commit}, customer){
            try{
                let response = await Api().post('/customers/create',customer);
                console.log('response create customer ===>', response);
                commit('SET_CUSTOMER', response.data);
                return response;
            }catch(error){
                console.log('error create customer ====>', error);
            }
        },
        async getCustomer({commit}, id){
            try{
                let response = await Api().get(`/customers/get/${id}`);
                console.log('Customer is ====>',response.data );
                commit('SET_CUSTOMER', response.data);
                return response;
            }catch(error){
                console.log('error get one customer', error.response);
                return error.response;
            }
        },
        async updateCustomer({commit}, {id,customer}){
            try{
                let response = await Api().patch(`/customers/update/${id}`,customer);
                console.log('response status update customer ===>', response);
                commit('SET_CUSTOMER', response.data);
                return response;
            }catch(error){
                console.log('error update customer', error); 
            }
        },
        
        async removeCustomer({commit}, customerId){
            try{
                let response = await Api().delete(`/customers/delete/${customerId}`);
                console.log('Customers when delete ====>',response.data );
                return response;
            }catch(error){
                console.log('error delete customer', error);
            }
        },
        async createNewWallet({commit}, wallet){
            try{
                let response = await Api().post('/wallets/create',wallet);
                console.log('response create wallet ===>', response);
                commit('SET_WALLET', response.data);
                return response;
            }catch(error){
                console.log('error create wallet ====>', error);
            }
        },
        async updateCustomerWallet({commit}, {id,wallet}){
            try{
                let response = await Api().patch(`/wallets/update?customerId=${id}`,wallet);
                console.log('response update customer wallet ===>', response);
                commit('SET_WALLET', response.data);
                return response;
            }catch(error){
                console.log('error update customer wallet', error); 
            }
        },
        async updateCustomerWithWallet({commit}, {id,oldStore,walletCustomer}){
            try{
                let response = await Api().patch(`/wallets/update?customerId=${id}&storeId=${oldStore}`,walletCustomer);
                console.log('response update customer wallet ===>', response);
                commit('SET_WALLET', response.data);
                return response;
            }catch(error){
                console.log('error update customer wallet', error); 
            }
        },
        async getCustomerWallet({commit}, id){
            try{
                let response = await Api().get(`/wallets/get?customerId=${id}`);
                console.log('Customer wallet is ====>',response.data );
                commit('SET_WALLET', response.data);
                return response;
            }catch(error){
                console.log('error get one customer wallet', error);
            }
        },
        async getWallet({commit}, {customerId, storeId}){
            try{
                let response = await Api().get(`/wallets/get?customerId=${customerId}&storeId=${storeId}`);
                console.log('Wallet is ====>',response.data );
                commit('SET_WALLET', response.data);
                return response;
            }catch(error){
                console.log('error get wallet', error);
            }
        },
        async customerQuery({commit}, phone){
            try{
                let response = await Api().get(`/customers/get?phone=${phone}`);
                console.log('Customer is ====>',response.data );
                return response;
            }catch(error){
                console.log('error get one customer', error.response);
                return error.response;
            }
        },
        async removeCustomerWallet({commit}, {customerId,storeId}){
            try{
                let response = await Api().delete(`/wallets/delete?customerId=${customerId}&storeId=${storeId}`);
                console.log('Customer wallet when delete is ====>',response.data );
                return response;
            }catch(error){
                console.log('error delete customer wallet', error);
            }
        },
        async loadPoints({commit}, {storeId, page, limit}){
            try{
                let response = await Api().get(`/points/search?storeId=${storeId}&page=${page}&limit=${limit}`);
                console.log('response points___',response);
                commit('SET_POINTS', response.data.points);
                commit('SET_POINTS_INFO', response.data);
                return response;
            }catch(error){
                console.log('error get pointd', error); 
            }
        },
        async createPointInterval({commit},point){
            try{
                let response = await Api().post('/points/create', point);
                console.log('response create new interval ====>',response);
                commit('SET_INTERVAL', response.data);
                return response;
            }catch(error){
                console.log('error create interval ====>', error);
            }
        },
        async updatePointInterval({commit}, {id,interval}){
            try{
                let response = await Api().patch(`/points/update/${id}`,interval);
                console.log('response status update interval ===>', response);
                commit('SET_INTERVAL', response.data);
                return response;
            }catch(error){
                console.log('error update interval', error); 
            }
        },
        async getPointInterval({commit}, id){
            try{
                let response = await Api().get(`/points/get/${id}`);
                console.log('Point interval is ====>',response.data );
                commit('SET_INTERVAL', response.data);
                return response;
            }catch(error){
                console.log('error get point', error);
            }
        },
        async removePoints({commit}, pointId){
            try{
                let response = await Api().delete(`/points/delete/${pointId}`);
                console.log('pointId when it is deletetd ====>',response.data );
                return response;
            }catch(error){
                console.log('error delete point', error);
                return error.response;
            }
        },
        
        async loadOrders({commit}, { storeId, searchQuery, page, limit }){
            try{
                let response = await Api().get(`/orders/search?storeId=${storeId}&search=${searchQuery}&page=${page}&limit=${limit}`);
                console.log('response orders___',response);
                commit('SET_ORDERS', response.data.orders);
                commit('SET_ORDERS_INFO', response.data);
                return response;
            }catch(error){
                console.log('error get ordes', error); 
            }
        },
        async createNewOrder({commit},order){
            try{
                let response = await Api().post('/orders/create', order);
                console.log('response create new order ====>',response);
                commit('SET_ORDER', response.data);
                return response;
            }catch(error){
                console.log('error create order ====>', error.response);
            }
        },
        async updateTheOrder({commit}, {id,order}){
            try{
                let response = await Api().patch(`/orders/update/${id}`,order);
                console.log('response status update order ===>', response);
                commit('SET_ORDER', response.data);
                return response;
            }catch(error){
                console.log('error update order', error); 
            }
        },
        async getOrder({commit}, id){
            try{
                let response = await Api().get(`/orders/get/${id}`);
                console.log('order is ====>',response.data);
                commit('SET_ORDER', response.data);
                return response;
            }catch(error){
                console.log('error get status order', error.response.data);
            }
        },
        async removeOrder({commit}, id){
            try{
                let response = await Api().delete(`/orders/delete/${id}`);
                console.log('order is ====>',response.data);
                return response;
            }catch(error){
                console.log('error get status order', error.response.data);
            }
        },
        async loadRedeemRequests({commit}, { storeId, searchQuery, page, limit }){
            try{
                let response = await Api().get(`/redeems/search?storeId=${storeId}&search=${searchQuery}&page=${page}&limit=${limit}`);
                console.log('response redeems______',response);
                commit('SET_REDEEMS', response.data.redeems);
                commit('SET_REDEEMS_INFO', response.data);
                return response;
            }catch(error){
                console.log('error redeems___', error);
            }
        },
        async updateRedeem({commit}, {id,data}){
            try{
                let response = await Api().patch(`/redeems/update/${id}`, data);
                console.log('response update redeem:', response);
                commit('SET_REDEEM', response.data);
                return response;
            }catch(error){
                console.log('error update redeem :', error);
            }
        },
        async removeRedeem({commit}, id){
            try{
                let response = await Api().delete(`/redeems/delete/${id}`);
                console.log('response delete redeem:', response);
                return response;
            }catch(error){
                console.log('error delete redeem :', error);
            }
        },
        async countStoresCreated({commit}){
            try{
                let response = await Api().get(`/stores/count`);
                console.log('response count stores______',response);
                commit('SET_COUNT_STORES', response.data);
                return response;
            }catch(error){
                console.log('error get count stores', error); 
            }
        },
        async getCountCustomers({commit}){
            try{
                let response = await Api().get(`/customers/count`);
                console.log('response count customers______',response);
                commit('SET_COUNT_CUSTOMERS', response.data);
                return response;
            }catch(error){
                console.log('error get count customers', error); 
            }
        },
        async getCountRewards({commit}){
            try{
                let response = await Api().get(`/rewards/count`);
                console.log('response count rewards______',response);
                commit('SET_COUNT_REWARDS', response.data);
                return response;
            }catch(error){
                console.log('error get count rewards', error); 
            }
        },
        async countActiveLoyaltyPoints({commit}){
            try{
                let response = await Api().get(`/wallets/count`);
                console.log('response count active points______',response);
                commit('SET_COUNT_ACTIVE_POINTS', response.data);
                return response;
            }catch(error){
                console.log('error get count active points', error); 
            }
        },
        async getRedeem({commit},redeemId){
            try{
                let response = await Api().get(`/redeems/get?_id=${redeemId}`);
                console.log('REDEEM IS ====>',response.data );
                commit('SET_REDEEM', response.data);
                return response;
            }catch(error){
                console.log('error get REDEEM', error);
            }
        },
        async createAccount({commit},account){
            try{
                let response = await Api().post('/accounts/create', account);
                console.log('response create new account ====>',response);
                commit('SET_ACCOUNT', response.data);
                return response;
            }catch(error){
                console.log('error create ACCOUNT ====>', error.response);
                return error.response;
            }
        },
        async createTeam({commit},team){
            try{
                let response = await Api().post('/teams/create', team);
                console.log('response create new team ====>',response);
                commit('SET_TEAM', response.data);
                return response;
            }catch(error){
                console.log('error create TEAM ====>', error.response);
            }
        },
        async getOneUser({commit},id){
            try{
                let response = await Api().get(`/users/get/${id}`);
                console.log('user is ====>',response.data );
                commit('SET_USER_TO_Update', response.data);
                return response.data;
            }catch(error){
                console.log('error get one user', error);
            }
        },
        async createUser({commit},user){
            try{
                let response = await Api().post('/users/create', user);
                console.log('response create new user ====>',response);
                commit('SET_SIGNUP_USER', response.data);
                return response;
            }catch(error){
                console.log('error create USER ====>', error.response);
                return error.response;
            }
        },
        async deleteUser({commit}, userId){
            try{
                let response = await Api().delete(`/users/delete/${userId}`);
                console.log('users when delete ====>',response.data );
                return response;
            }catch(error){
                console.log('error delete user', error);
                return error.response;
            }
        },
        
        async updateUser({commit},{id,userUpd}){
            try{
                let response = await Api().patch(`/users/update/${id}`, userUpd);
                console.log('response update user ====>',response);
                commit('SET_UPDATE_USER', response.data);
                return response;
            }catch(error){
                console.log('error UPDATE USER ====>', error.response);
                return error.response;
            }
        },
        async findStore({commit},subdomain){
            
            try{
                let response = await Api().get(`/stores/getByQuery?url=${subdomain}`);
                console.log('store ====>',response );
                //commit('SET_STORE', response.data);
                return response;
            }catch(error){
                console.log('error get store', error.response);
                return error.response;
            }
        },
        async loadMe({commit},userId){
            try{
                let response = await Api().get(`/users/me`);
                console.log('user ====>',response.data );
                commit('SET_ME', response.data);
                return response.data;
            }catch(error){
                console.log('error get me', error);
            }
        },
        async getAccount({commit}, accountId){
            try{
                let response = await Api().get(`/accounts/get/${accountId}`);
                console.log('account is ====>',response.data);
                commit('SET_MyAccount', response.data);
                return response;
            }catch(error){
                console.log('error get account', error);
            }
        },
        async updateAccount({commit}, {accountId, data}){
            try{
                let response = await Api().patch(`/accounts/update/${accountId}`, data);
                console.log('account is ====>',response.data);
                return response;
            }catch(error){
                console.log('error get account', error);
            }
        },
        async loadUsers({commit}){
            try{
                let response = await Api().get(`/users/search`);
                commit('SET_USERS', response.data);
                console.log('response search users ====>',response);
                return response;
            }catch(error){
                console.log('error search users ====>', error);
            }
        },
        async paySubscription({commit},pay){
            try{
                let response = await Api().post('/payments/pay', pay);
                console.log('response subscription paypal payment ====>',response);
                commit('SET_PAY_SUBSCRIPTION', response.data);
                return response.data;
            }catch(error){
                console.log('error pay subscription  ====>', error.response);
                return error.response;
            }
        },
        async payByCard({commit},pay){
            try{
                let response = await Api().post('/payments/card', pay);
                console.log('response card payment ====>',response);
                commit('SET_PAY_BY_CARD', response.data);
                return response.data;
            }catch(error){
                console.log('error pay bt card  ====>', error.response);
                return error.response;
            }
        },
        async loadSuccessSubscription({commit},paramsString){
            try{
                let response = await Api().get(`/payments/success?${paramsString}`);
                console.log('success pay responsee ====>',response );
                //commit('SET_ME', response.data);
                return response;
            }catch(error){
                console.log('error get success pay', error);
                return error.response;
            }
        },
        async loadPayments({commit} ){
            try{
                let response = await Api().get(`/payments/search`);
                console.log('load payments responsee ====>',response );
                commit('SET_PAYMENTS', response.data);
                return response;
            }catch(error){
                console.log('error load payments', error);
                return error.response;
            }
        },
        async loadAnalytics({commit}, storeId){
            try{
                let response = await Api().get(`/accounts/analytics?stores=${storeId}`);
                console.log('response analytics___',response);
                commit('SET_ANALYTICS', response.data);
                return response;
            }catch(error){
                console.log('error get analytics', error); 
            }
        },
        async getGreaterMax({commit}, storeId){
            try{
                let response = await Api().get(`/points/getGreater?storeId=${storeId}`);
                console.log('response greater MAX___',response);
                commit('SET_GREATER_MAX', response.data);
                return response;
            }catch(error){
                console.log('error get greater max', error); 
            }
        },
        expiredPopup({commit}, isShow){
            commit('SET_SHOW_POPUP', isShow );
        }

    }
})