<template>
  <card>
    <h5 slot="header" class="title">Edit Profile</h5>
    <div class="row">
      <div class="col-md-6 pr-md-1">
        <base-input label="Fullname"
                  v-model="model.fullname"
                  placeholder="Fullname">
        </base-input>
      </div>
      <div class="col-md-6 pl-md-1">
        <base-input label="Email"
                  type="email"
                  v-model="model.email"
                  placeholder="mike@email.com">
        </base-input>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-md-5 pr-md-1">
        <base-input label="Company (disabled)"
                  placeholder="Company"
                  v-model="model.company"
                  disabled>
        </base-input> 
      </div> -->
      <div class="col-md-6 pr-md-1">
        <base-input label="Phone number"
                  placeholder="Phone number"
                  v-model="model.phone">
        </base-input>
      </div>
      <div class="col-md-6 pl-md-1">
        <base-input label="Address"
                  v-model="model.address"
                  placeholder="Home Address">
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 pr-md-1">
        <base-input label="Current passwrod"
                  v-model="model.currentPassword"
                  placeholder="Current password">
        </base-input>
      </div>
      <div class="col-md-6 pl-md-1">
        <base-input label="New passwrod"
                  v-model="model.newPassword"
                  placeholder="New password">
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 pr-md-1">
        <base-input label="City"
                  v-model="model.city"
                  placeholder="City">
        </base-input>
      </div>
      <div class="col-md-4 px-md-1">
        <base-input label="Country"
                  v-model="model.country"
                  placeholder="Country">
        </base-input>
      </div>
      <div class="col-md-4 pl-md-1">
        <base-input label="Postal Code"
                  placeholder="ZIP Code">
        </base-input>
      </div>
    </div>
    <div v-if="false" class="row">
      <div class="col-md-8">
        <base-input>
          <label>About Me</label>
          <textarea rows="4" cols="80"
                    class="form-control"
                    placeholder="Here can be your description"
                    v-model="model.about">

              </textarea>
        </base-input>
      </div>
    </div>
    <base-button slot="footer" type="primary" fill>Save</base-button>
  </card>
</template>
<script>
  export default {
    props: {
      model: {
        type: Object,
        default: () => {
          return {};
        }
      }
    }
  }
</script>
<style>
</style>
