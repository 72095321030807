<template>
  <table  class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">

    <tr>
      <slot name="columns">
        <th v-for="column in translatedColumns" :key="column">{{column}} </th>
      </slot>
    </tr>
    </thead>
    <tbody v-if="data && data.length > 0" :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <td v-for="(column, index2) in columns"
            :key="index2"
            v-if="hasValue(item, column)"
            >
          <span v-if="column != 'status' && column != 'date'"> {{itemValue(item, column)}} </span>
          <span v-if="column == 'date'"> {{itemValue(item, column) | formatDate}} </span>
          <div v-if="column == 'button'">
            <button class="btn-add-order" @click="showPopupOrder(item)"> Add order </button>
          </div>
          <div v-if="column == 'actions'">
            <svg @click="emitEdit(item._id)" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit w-5 h-5 hover:text-primary stroke-current"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
            <svg @click="emitDelete(item._id)" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash w-5 h-5 hover:text-danger stroke-current"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
          </div>
          <!--:class="{active : isActive}"-->
          <div ref="select" :id="'status'+index" class="dropdown" @click="activeStatusDropdown('status'+index,'status'+index+'option',index)" :class="{active:isActives[index] && isActives[index].isActive && isActives.length > 0}"  v-if="column == 'status'">
            <input type="text" class="textBox" :value="item.status" placeholder="Dropdown Menu" readonly>
            <div :id="'status'+index+'option'" class="option">
              <!-- <div @click="show('CREATED',item, column)">CREATED</div> -->
              <div @click="show('PENDING',item, column)">PENDING</div>
              <!-- <div @click="show('APPROVED',item, column)">APPROVED</div> -->
              <div @click="show('DELIVERED',item, column)">DELIVERED</div>
              <div @click="show('FALLBACK',item, column)">FALLBACK</div>
            </div>
          </div>
        </td>
        <!-- <td v-for="(column, index) in columns"
            :key="index"
            v-if="hasValue(item, column)">
          {{itemValue(item, column)}}
        </td> -->
      </slot>
    </tr>
    </tbody>
    <!-- <div v-for="clm in translatedColumns" :key="clm"> {{clm}} </div> -->
  </table>
</template>
<script>
  export default {
    name: 'base-table',
    data(){
      return{
        status : '',
        isActives: []
      }
    },
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      showAddOrder:{
        type: Boolean
      },
      selectedCustomer:{
        type: Object
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    mounted() {
      if(this.data && this.data.length > 0){
        console.log('data table:', this.data);
        for(let i=0; i<this.data.length; i++){
          this.isActives.push({isActive : false});
          console.log('actives:', this.isActives)
        }
      }
      document.addEventListener('click', this.close)
    },
    beforeDestroy () {
      document.removeEventListener('click',this.close);
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      },
      translatedColumns(){
        let columns = [];
        this.columns.forEach(clm => {
          let cl = this.$t(`table.${clm}`);
          columns.push(cl);
        });
       
        return columns;
      }
    },
    methods: {
      close (e) {
        for(let i=0; i<this.isActives.length; i++){
          var element = document.getElementById('status'+i);
          if (!element.contains(e.target)) {
            this.isActives[i].isActive = false;
            console.log('faaaaalse');
          }
        }
      },
      hideSelect(){
        console.log('To hide select');
      },
      hasValue(item, column) {
        return item[column.toLowerCase()] !== "undefined";
      },
      itemValue(item, column) {
        return item[column.toLowerCase()];
      },
      emitEdit(id){
        this.$emit('emitToEdit',id);
      },
      emitDelete(id){
        this.$emit('emitToDelete', id);
      },  
      emitStatus(id,status,item){
        this.$emit('emitChangeStatus',{id: id, status: status,item:item})
      },
      showPopupOrder(item){
        this.showAddOrder = true;
        this.selectedCustomer = item;
        this.$emit('emitShowOrderPopup', { showOrderPop :this.showAddOrder, selectedCustomer : this.selectedCustomer});
      },
      activeStatusDropdown(id,idOption,index){
        //isActive = !isActive
        
        console.log('id option', idOption);
        let el = document.getElementById(id);
        let topDistance = el.getBoundingClientRect().top;
        document.getElementById(idOption).style.top = topDistance + 50 + "px";
        this.isActives[index].isActive = !this.isActives[index].isActive
        for(let i=0; i<this.isActives.length; i++){
          if(i !== index) this.isActives[i].isActive = false
        }
        console.log('active',this.isActives[index].isActive);
        console.log(topDistance);
      },
      show(value,item,column){
        item.status = value;
        this.emitStatus(item._id,item.status,item);
        //this.setStatus(item,column);
        //this.status = value;
      },
      setStatus(item, column){
        if(column == 'status') {
          this.status = item.status;
          //console.log('status from item ===>', this.status);
          return this.status;
        }
      }
    }
  };
</script>
<style>

  .btn-add-order{
    border-color: #e14eca;
    color: #ba54f5;
    background-color: #1e1e29;
    border-radius: 0.4285rem;
    font-size: 0.75rem;
    cursor: pointer;
  }

</style>
