<template>
  <div class="newStroe row">
    <div class="col-sm-4">
      <h4>
        <span v-if="!isEdit">Add new Customer</span>
        <span v-if="isEdit">Edit Customer</span>
      </h4>
    </div>
    <div class="col-sm-8">
      <card>
        <form @submit.prevent>
            <base-input label="Firstname"
                        type="text"
                        v-model="customer.firstname"
                        placeholder="firstname"
                        >
            </base-input>
            <base-input label="Lastname"
                        type="text"
                        v-model="customer.lastname"
                        placeholder="lastname"
                        >
            </base-input>

            <p class="error-msg">{{ customerErrors.emailError }}</p>
            <base-input label="Email"
                        type="email"
                        v-model="customer.email"
                        placeholder="email"
                        >
            </base-input>

            <p class="error-msg">{{ customerErrors.phoneNumberError }}</p>
            <label for="phone">Phone number</label>
            <base-input 
            type="text"
            id="phone"
            name="phone"
            v-model="customer.phone"
            
            >
            </base-input>
            
            <!--<base-input label="Points"
            type="number"
            v-model="wallet.points"
            placeholder="points"
            >
            </base-input>-->
            <!-- <h2 style="color:#fff;">Store:{{ storeId }}</h2> -->
            <div>
              <p class="error-msg">{{ customerErrors.storeError }}</p>
              <base-input label="Select Store :" class="base-input">
                  <select v-model="storeId" style="cursor:pointer;" class="form-control select-in-form" id="exampleFormControlSelect1">
                    <option :value="store._id" v-for="(store,index) in stores" :key="index">{{store.name}}</option>
                  </select>
              </base-input>
            </div>
            
            <!-- <div>{{ customer.phone }}</div>
            <div>{{ customer.country }}</div>
            <button @click="submitPhone">Submit</button> -->
            <base-button native-type="submit" @click="saveCustomer()" type="primary">Save</base-button>
        </form>
      </card>
  </div>
  </div>
</template>

<script>

import NotificationTemplate from '@/pages/Notifications/NotificationTemplate';
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import utilsScript from 'intl-tel-input/build/js/utils.js';


export default {
  components:{
    NotificationTemplate,
  },
  data(){
    return{ 
      isEdit: false,
      iti: null,
      phone:'',
      oldStoreId: '',
      storeId:'',
      customer:{
        stores : [],
        firstname:'',
        lastname:'',
        email:'',
        phone:'',
        country: '' 
      },
      customerErrors:{
        firstnameError: '',
        lastnameError: '',
        emailError: '',
        phoneNumberError:'',
        storeError: '',
      },
      wallet:{
        points : 1,
        customerId:'',
      }
    }
  },
  async mounted(){
    await this.$store.dispatch('loadStores');
    if(this.$route.params.hasOwnProperty('id')) this.isEdit = true;
    //if(!this.isEdit) this.customer.storeId = this.stores[0]._id;
    if(this.isEdit) this.setData();
    const input = document.querySelector("#phone");
    this.iti = intlTelInput(input, {
      //initialCountry: "auto",
      utilsScript: utilsScript,
      nationalMode: true
    });
  },
  beforeDestroy() {
    if (this.iti) {
      this.iti.destroy(); // Clean up on component destroy
    }
  },
  computed:{
    stores(){
      return this.$store.state.stores 
    }
  },
  methods:{
     notifyVue(verticalAlign, horizontalAlign, msg, type) {
      const color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
        component: NotificationTemplate,
        message: msg,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: type, //this.type[color],
        timeout: 3000
      });
    },
    async setData(){
      const id = this.$route.params.id;
      const res = await this.$store.dispatch('getCustomerWallet',id)
      console.log('res setData',res);
      //this.storeId = res.data.customerId.stores[0];
      this.storeId = res.data.storeId;
      this.oldStoreId = res.data.storeId;
      this.customer.stores = res.data.customerId.stores;
      this.customer.firstname = res.data.customerId.firstname;
      this.customer.lastname = res.data.customerId.lastname;
      this.customer.email = res.data.customerId.email;
      this.customer.phone = res.data.customerId.phone;
      this.customer.country = res.data.customerId.country;
      let country = this.customer.country;
      if (this.iti && this.customer.phone) {
        // Set the country first using the saved country code (e.g., "ma" for Morocco)
        this.iti.setCountry(country);
        this.iti.setNumber(this.customer.phone);
      }
      //set points
      this.wallet.points = res.data.points;
      this.wallet.customerId = res.data.customerId._id;
    },
    async createCustomer(){
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(this.customer.email !== '' && this.storeId !== '' ){
        this.customerErrors.firstnameError= '',
        this.customerErrors.lastnameError= '',
        this.customerErrors.emailError = '';
        this.customerErrors.phoneNumberError = '';
        this.customerErrors.storeError = '';
        if(this.customer.phone !== ''){
          if(!this.iti.isValidNumber()){
            this.customerErrors.phoneNumberError = 'Please enter a valid phone number';
            return;
          }
          this.customer.country = this.iti.getSelectedCountryData().iso2;
        }
        if(this.customer.email !== ''){
          if (!emailPattern.test(this.customer.email)) {
            this.customerErrors.emailError = "Please enter a valid email.";
            return;
          }
          
        }
        
        this.customerErrors.emailError = '';
        this.customerErrors.phoneNumberError = '';
        this.customer.stores.push(this.storeId);
        if(this.customer.phone !== '' ){
          let formatPhone = this.customer.phone.replace(/\D/g, '');
          this.customer.phone = formatPhone;
        }
        let res = await this.$store.dispatch('createNewCustomer', this.customer);
        console.log('res createNewCustomer :::', res);
        if(res.status == 200){
          this.notifyVue('bottom', 'center', 'Customer  created successfuly', 'success');
          this.$router.push({ name: 'customers' });
        }
        else this.notifyVue('bottom', 'center', 'Error create customer', 'danger');
      }else{
        //if(this.customer.phone == '') this.customerErrors.phoneNumberError = 'Phone number Cannot be empty';
        //if(this.customer.phone !== '') this.customerErrors.phoneNumberError = '';

        if(this.customer.email == '') this.customerErrors.emailError = 'Email Cannot be empty';
        if(this.customer.email !== '') this.customerErrors.emailError = '';

        if(this.storeId == '') this.customerErrors.storeError = 'Please select a store';
        if(this.storeId !== '') this.customerErrors.storeError = '';
        this.notifyVue('bottom', 'center', 'Error', 'danger');
      }
    },
    async updateCustomer(){
      const id = this.$route.params.id;
      let customer = {};
      //this.customer.stores = [];
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      //this.customer.stores.push(this.storeId);
      if(this.customer.email !== '' && this.storeId !== '' ){
        this.customerErrors.phoneNumberError = '';
        this.customerErrors.emailError = '';
        this.customerErrors.storeError = '';
        if(this.customer.email !== ''){
          if(!emailPattern.test(this.customer.email)){
            this.customerErrors.emailError = 'Please enter a valid email address';
            return;
          }
        }
        if(this.customer.phone !== ''){
          if (!this.iti.isValidNumber()) {
            this.customerErrors.phoneNumberError = "Please enter a valid phone number.";
            return;
          }
          this.customer.country = this.iti.getSelectedCountryData().iso2;
        }
        this.customerErrors.phoneNumberError = '';
        this.customerErrors.emailError = '';
        if(this.storeId == this.oldStoreId){
          if(this.customer.phone !== ''){
            var formatPhone = this.customer.phone.replace(/\D/g, '');
            this.customer.phone = formatPhone;
          }
          customer = this.customer;
          let resCustomer = await this.$store.dispatch('updateCustomer', {id,customer});
          if(resCustomer.status == 200) this.notifyVue('bottom', 'center', 'Customer updated successfuly', 'success');
          else this.notifyVue('bottom', 'center', 'Error update customer', 'danger');
        }else{
          for (let index = 0; index < this.customer.stores.length; index++) {
            const storeElement = this.customer.stores[index];
            if(storeElement == this.oldStoreId){
              this.customer.stores[index] = this.storeId;
            }
          }
          if(this.customer.phone !== ''){
            var formatPhone = this.customer.phone.replace(/\D/g, '');
            this.customer.phone = formatPhone;
          }
          customer = this.customer;
          let resCustomer = await this.$store.dispatch('updateCustomer', {id,customer});
          let walletCustomer = {
            customerId: id,
            storeId: this.storeId,
          };
          const oldStore = this.oldStoreId;
          let resWalletCustomer = await this.$store.dispatch('updateCustomerWithWallet', {id,oldStore,walletCustomer});
          if(resCustomer.status == 200 && resWalletCustomer.status == 200) this.notifyVue('bottom', 'center', 'Customer updated successfuly', 'success');
          else this.notifyVue('bottom', 'center', 'Error update customer', 'danger');
        }
      }else{
        //if(this.customer.phone == '') this.customerErrors.phoneNumberError = 'Phone number Cannot be empty';
        //if(this.customer.phone !== '') this.customerErrors.phoneNumberError = '';

        if(this.customer.email == '') this.customerErrors.emailError = 'Email Cannot be empty';
        if(this.customer.email !== '') this.customerErrors.emailError = '';

        if(this.storeId == '') this.customerErrors.storeError = 'Please select a store';
        if(this.storeId !== '') this.customerErrors.storeError = '';
        this.notifyVue('bottom', 'center', 'Error', 'danger');
      }
      
    },
    saveCustomer(){
      if(!this.isEdit) this.createCustomer();
      else this.updateCustomer();
    },
    submitPhone(){
      this.customerErrors.phoneNumberError = '';
      if (this.iti.isValidNumber()) {
        this.customer.country = this.iti.getSelectedCountryData().iso2;
        alert("Phone number is valid!");
      } else {
        this.customerErrors.phoneNumberError = "Please enter a valid phone number.";
      }
    }
  }
}
</script>

<style scoped>

  .select-in-form option{
    color: #000;
  }

  .error-msg{
    margin-bottom: 5px;
    font-size: 11px;
    color: #d74343 !important;
  }

  

  /* .iti {
    --iti-path-flags-1x: url('path/to/flags.webp');
    --iti-path-flags-2x: url('path/to/flags@2x.webp');
    --iti-path-globe-1x: url('path/to/globe.webp');
    --iti-path-globe-2x: url('path/to/globe@2x.webp');
  } */

</style>