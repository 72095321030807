<template>
    <div>


    <div class="row globalTabs">
            <!--<div class="col-md-6">
                <h3><small>Tabs with Icons on Card</small></h3>

                <div class="card card-nav-tabs">
                    <div class="card-header card-header-primary">
                        <div class="nav-tabs-navigation">
                            <div class="nav-tabs-wrapper">
                                <ul class="nav nav-tabs" data-tabs="tabs">
                                    <li class="nav-item">
                                        <a class="nav-link active" href="#profile" data-toggle="tab">
                                            <i class="material-icons">face</i>
                                            Profile
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#messages" data-toggle="tab">
                                            <i class="material-icons">chat</i>
                                            Messages
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#settings" data-toggle="tab">
                                            <i class="material-icons">build</i>
                                            Settings
                                        </a>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div><div class="card-body ">
                        <div class="tab-content text-center">
                            <div class="tab-pane active" id="profile">
                                <p> I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at. </p>
                            </div>
                            <div class="tab-pane" id="messages">
                                <p> I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at. I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at.</p>
                            </div>
                            <div class="tab-pane" id="settings">
                                <p>I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at. So when you get something that has the name Kanye West on it, it’s supposed to be pushing the furthest possibilities. I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus.</p>
                            </div>
                        </div>
                    </div></div>

            </div>-->
            <div class="col-md-12">
                <h3>Add New Gift Card</h3>


                <div class="card card-nav-tabs card-plain">
                    <div class="card-header card-header-danger">

                        <div class="nav-tabs-navigation">
                            <div class="nav-tabs-wrapper">
                                <ul class="nav nav-tabs" data-tabs="tabs">
                                    <li class="nav-item">
                                        <a class="nav-link active" href="#home" data-toggle="tab">Gift Cards Details</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#updates" data-toggle="tab">Variation</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#updates" data-toggle="tab">Choose Template</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#history" data-toggle="tab">Customize Template</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#history" data-toggle="tab">Email Notification</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div></div>
            </div>
        </div>

    <div class="newStroe row">
        <div class="col-sm-12">
            <card>
                <form>
                    <div class="form-row">
                        <div class="col">
                            <BaseInput type="text" label="Select Store" placeholder="store name"/>
                            <BaseInput type="text" label="Gift Card Name" placeholder="gift card  name"/>
                            <BaseInput type="text" label="Gift Card Description" placeholder="description"/>
                            <BaseInput type="text" label="Default Currency" placeholder="default currency "/>
                        </div>
                        <div class="col">
                            <BaseInput type="text" label="Terms" placeholder="footer description"/>
                        </div>
                    </div>
                </form>
            </card>
        </div>
    </div>
  </div>
</template>

<script>

import BaseButton from  "@/components/BaseButton.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";

export default {
  components:{
    BaseButton,
    BaseInput,
    BaseCheckbox
  }
}
</script>

<style scoped>

  .mbNewStore{
    margin-bottom: 15px;
  }





  .globalTabs h3{
    font-size: 25px !important;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.4em !important;
}

.globalTabs  p {
    font-size: 14px;
    margin: 0 0 10px !important;
    font-weight: 300;
}

.globalTabs  small {
    font-size: 75%;
    color: #777;
    font-weight: 400;
}

.globalTabs .container .title{
    color: #3c4858;
    text-decoration: none;
    margin-top: 30px;
    margin-bottom: 25px;
    min-height: 32px;
}

.globalTabs .container .title h3{
    font-size: 25px;
    font-weight: 300;
}

.globalTabs div.card {
    border: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    border-radius: 6px;
    color: rgba(0,0,0,.87);
    background: #fff;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}

.globalTabs div.card.card-plain {
    background: transparent;
    box-shadow: none;
}
.globalTabs div.card .card-header {
    border-radius: 3px;
    padding: 1rem 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -30px;
    border: 0;
    background: linear-gradient(60deg,#eee,#bdbdbd);
}

.globalTabs .card-plain .card-header:not(.card-avatar) {
    margin-left: 0;
    margin-right: 0;
}

.globalTabs .div.card .card-body{
    padding: 15px 30px;
}

.globalTabs div.card .card-header-primary {
    background: linear-gradient(60deg,#ab47bc,#7b1fa2);
    box-shadow: 0 5px 20px 0 rgba(0,0,0,.2), 0 13px 24px -11px rgba(156,39,176,.6);
}

.globalTabs div.card .card-header-danger {
    /* background: linear-gradient(60deg,#ef5350,#d32f2f); */
    background: #27293d;
    box-shadow: 0 1px 20px 0px rgb(0 0 0 / 10%);
    /* box-shadow: 0 5px 20px 0 rgba(0,0,0,.2), 0 13px 24px -11px rgba(244,67,54,.6); */
}


.globalTabs .card-nav-tabs .card-header {
    margin-top: -30px!important;
}

.globalTabs .card .card-header .nav-tabs {
    padding: 0;
}

.globalTabs .nav-tabs {
    border: 0;
    border-radius: 3px;
    padding: 0 15px;
}

.globalTabs .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.globalTabs .nav-tabs .nav-item {
    margin-bottom: -1px;
}

.globalTabs .nav-tabs .nav-item .nav-link.active {
    background-color: hsla(0,0%,100%,.2);
    transition: background-color .3s .2s;
}

.globalTabs .nav-tabs .nav-item .nav-link{
    border: 0!important;
    color: #fff!important;
    font-weight: 500;
}

.globalTabs .nav-tabs .nav-item .nav-link {
    color: #fff;
    border: 0;
    margin: 0;
    border-radius: 3px;
    line-height: 24px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px 15px;
    background-color: transparent;
    transition: background-color .3s 0s;
}

.globalTabs .nav-link{
    display: block;
}

.globalTabs .nav-tabs .nav-item .material-icons {
    margin: -1px 5px 0 0;
    vertical-align: middle;
}

.globalTabs .nav .nav-item {
    position: relative;
}
.globalTabs footer{
    margin-top:100px;
    color: #555;
    background: #fff;
    padding: 25px;
    font-weight: 300;

}
.globalTabs .footer p{
    margin-bottom: 0;
    font-size: 14px;
    margin: 0 0 10px;
    font-weight: 300;
}
.globalTabs footer p a{
    color: #555;
    font-weight: 400;
}

.globalTabs footer p a:hover {
    color: #9f26aa;
    text-decoration: none;
}

</style>
