<template>
  <div class="newStroe row">
    <div class="col-sm-4">
      <h4>Craete new store</h4>
      <p>Enter The Information Of The New Store</p>
    </div>
    <div class="col-sm-8">
      <card>
        <form @submit.prevent>
          <p class="error-msg">{{ storeErrors.storeNameError }}</p>
          <BaseInput label="Store Name *"
                      type="text"
                      placeholder="Enter the name of store"
                      v-model="store.name"
                      >
          </BaseInput>
          <p class="error-msg">{{ storeErrors.storeLocationError }}</p>
          <BaseInput label="Store Location"
                      type="text"
                      placeholder="Enter the location of stroe "
                      v-model="store.country"
                      >
          </BaseInput>
          
          <!-- <BaseInput label="Store Url"
                      type="text"
                      placeholder="Enter the url of store"
                      v-model="store.url"
                      >
          </BaseInput> -->
          <div class="form-group">
            <p class="error-msg">{{ storeErrors.storeUrlError }}</p>
            <label class="control-label"> Store url *</label>
            <div class="custom-input-container">
              <div class="input-prefix">https://</div>
              <input type="text" v-model="store.bodyUrl" :disabled="isParamsId" :class="{ 'diabled-input': isParamsId }" class="input-middle" placeholder="Enter your store name">
              <div class="input-suffix">.rewardisfy.com</div>
            </div>
          </div>
          
          <!-- <BaseInput label="Time Zones">
            <select class="form-control" id="exampleFormControlSelect1">
              <option>Select time zone</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </BaseInput> -->
          <!-- <base-checkbox class="mbNewStore">
            Option one is this
           </base-checkbox> -->
        <BaseButton native-type="submit" @click="onSubmit()" type="primary">
          <span v-if="!isParamsId">Add</span>
          <span v-if="isParamsId">Update</span>
        </BaseButton>
        </form>
      </card>
  </div>
  </div>
</template>

<script>

import BaseButton from  "@/components/BaseButton.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import NotificationTemplate from '@/pages/Notifications/NotificationTemplate';

export default {
  components:{
    BaseButton,
    BaseInput,
    BaseCheckbox,
    NotificationTemplate
  },
  data(){
    return{
      store:{
        name : '',
        url: '',
        bodyUrl:'',
        address: '',
        country: '',
        city: '',
        // userId: ''
      },
      isParamsId : false,
      storeErrors:{
        storeNameError: '',
        storeLocationError: '',
        storeUrlError: ''
      }
    }
  },
  mounted(){
    if(this.$route.params.hasOwnProperty('id')) this.isParamsId = true;
    if(this.isParamsId) this.getData();
  },
  watch: {
    'store.name': function (newName) {
      if (!this.isParamsId) {
        this.store.bodyUrl = newName.trim().replace(/\s+/g, '-').toLowerCase();
      }
    },
    'store.bodyUrl': function (newUrl) {
      if (!this.isParamsId) {
        const formattedUrl = newUrl.trim().replace(/\s+/g, '-').toLowerCase();; // replace spaces with dashes
        if (formattedUrl !== '') {
          this.store.url = `https://${formattedUrl}.rewardisfy.com`;
        } else {
          this.store.url = formattedUrl;
        }
      }
    }
  },
  methods:{
    async getData(){
      const id = this.$route.params.id;
      let res = await this.$store.dispatch('getOneStore',id);
      this.store.name = res.data.name;
      this.store.url = res.data.url;
      const urlWithoutProtocol = this.store.url.replace(/^https?:\/\//, ''); // Remove the protocol
      const subdomain = urlWithoutProtocol.split('.')[0];
      console.log('subdomain ==>', subdomain);
      this.store.bodyUrl = subdomain;
      this.store.address = res.data.address;
      this.store.country = res.data.country;
      this.store.city = res.data.city;
    },
    notifyVue(verticalAlign, horizontalAlign, msg, type) {
      const color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
          component: NotificationTemplate,
          message: msg,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: type, //this.type[color],
          timeout: 3000
      });
    },
    onSubmit(){
      if(!this.isParamsId) this.createStore();
      else this.updateStore();
    },
    async createStore(){
      if(this.store.name !== '' && this.store.url !==''){
        this.storeErrors.storeNameError = '';
        this.storeErrors.storeUrlError = '';
        this.storeErrors.storeLocationError = '';
        let subdomain = this.store.url.toLowerCase();
        let resFindStore = await this.$store.dispatch('findStore',subdomain);
        if(resFindStore.data.message == 'store not found'){
          console.log('Create new store _________', this.store);
          let data = this.store;
          let res =await this.$store.dispatch('createNewStore',data);
          if(res.status == 200){
            this.notifyVue('bottom', 'center', 'Store created successfuly', 'success');
            this.$router.push({ name: 'stores' });
          }else this.notifyVue('bottom', 'center', 'Error In Create store', 'danger');
        }else{
          this.storeErrors.storeUrlError = "This Store's (url) already exist!!! try another name";
          this.notifyVue('bottom', 'center', 'Error ', 'danger');
        }
      }else{
        if(this.store.name == '') this.storeErrors.storeNameError = 'Store Name Cannot be empty';
        if(this.store.name !== '') this.storeErrors.storeNameError = '';
        //this.storeErrors.storeLocationError = '';
        if(this.store.url == '') this.storeErrors.storeUrlError = 'Url Cannot be empty';
        if(this.store.url !== '') this.storeErrors.storeUrlError = '';
        this.notifyVue('bottom', 'center', 'Error in create store', 'danger') 
      }
    },
    async updateStore(){
      if(this.store.name !== '' && this.store.url !== '' ){
        const id = this.$route.params.id;
        let data = this.store;
        delete data.url;
        let res = await this.$store.dispatch('updateStore',{id,data});
        if(res.status == 200){
          this.storeErrors.storeNameError = '';
          this.notifyVue('bottom', 'center', 'Store updated successfuly', 'success');
        }else this.notifyVue('bottom', 'center', 'Error Update store', 'danger');
      }else{
        this.storeErrors.storeNameError = 'Store Name Cannot be empty';
        this.notifyVue('bottom', 'center', 'Name Cannot be empty', 'danger') 
      }
    }
  }
}
</script>

<style>

  .mbNewStore{
    margin-bottom: 15px;
  }

  .custom-input-container {
      display: flex;
      align-items: center;
      max-width: 300px;
      margin: 20px;
    }

    .input-prefix,
    .input-suffix {
      padding: 10px;
      background-color: #f1f1f1;
      border: 1px solid #ddd;
      border-radius: 5px;
      user-select: none;
    }

    .input-middle {
      flex: 1;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
    }

    .error-msg{
      margin-bottom: 5px;
      font-size: 11px;
      color: #d74343 !important;
    }

    .diabled-input{
      background-color: #c5c0c0;
    }


</style>
