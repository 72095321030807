import { render, staticRenderFns } from "./RewardTemplate.vue?vue&type=template&id=67b2b424"
import script from "./RewardTemplate.vue?vue&type=script&lang=js"
export * from "./RewardTemplate.vue?vue&type=script&lang=js"
import style0 from "./RewardTemplate.vue?vue&type=style&index=0&id=67b2b424&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports