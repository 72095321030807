<template>
    <div class="container">
      <div class="paginate">
        <button :disabled="currentPage === 1" @click="prevPage" class="prevBtn">
          <span class="prevBtn-icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="24px" height="24px">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
          </span>
          <span class="prevBtn-text">Prev</span>
        </button>
        
        <div class="containerBtns">
          <div class="leftContainer">
            <button v-for="page in leftPages" :key="page" @click="goToPage(page)" :class="['numberBtn', { activeBtn: page === currentPage }]" :disabled="page === currentPage">
              {{ page }}
            </button>
          </div>
  
          <button class="activeBtn">{{ currentPage }}</button>
  
          <div class="rightContainer">
            <button v-for="page in rightPages" :key="page" @click="goToPage(page)" :class="['numberBtn', { activeBtn: page === currentPage }]" :disabled="page === currentPage">
              {{ page }}
            </button>
          </div>
        </div>
        
        <button :disabled="currentPage === totalPages" @click="nextPage" class="nextBtn">
          <span class="nextBtn-text">Next</span>
          <span class="nextBtn-icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="24px" height="24px">
              <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          </span>
        </button>
      </div>
      
      <div class="paginate-details">
        <span>-</span>
        <span class="count">{{ totalRecords }} items</span>
        <span>-</span>
      </div>
    </div>
  </template>

<script>
export default {
    props: {
        currentPage: {
            type: Number,
            required: true
        },
        totalPages: {
            type: Number,
            required: true
        },
        totalRecords: {
            type: Number,
            required: true
        }
    },
    computed: {
        // For left container buttons
        leftPages() {
            if (this.currentPage <= 5) {
                return Array.from({ length: this.currentPage - 1 }, (_, i) => i + 1);
            }
            return [1, 2, this.currentPage - 2, this.currentPage - 1];
        },
        // For right container buttons
        rightPages() {
            if (this.currentPage >= this.totalPages - 4) {
                return Array.from({ length: this.totalPages - this.currentPage }, (_, i) => this.currentPage + i + 1);
            }
            return [this.currentPage + 1, this.currentPage + 2, this.totalPages - 1, this.totalPages];
        }
    },
    methods: {
        prevPage() {
            this.$emit("updatePage", this.currentPage - 1);
        },
        nextPage() {
            this.$emit("updatePage", this.currentPage + 1);
        },
        goToPage(page) {
            this.$emit("updatePage", page);
        }
    }
}
</script>

<style scoped>
.container {
  padding: 10px;
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.paginate {
  padding: 4px 32px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 32px;
  border: 2px solid rgb(255 222 210);
}

.paginate-details {
  margin-top: 8px;
  font-weight: 500;
  text-align: center;
}

button {
  background-color: transparent;
  border: none;
}

.prevBtn,
.nextBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #2a3b52;
}

.prevBtn-icon,
.nextBtn-icon {
  display: flex;
}

.prevBtn svg {
  margin-right: 4px;
}

.nextBtn svg {
  margin-left: 4px;
}

button[disabled].prevBtn,
button[disabled].nextBtn {
  cursor: not-allowed;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.containerBtns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  width: 100px;
  justify-content: end;
}

.rightContainer {
  display: flex;
  width: 100px;
  justify-content: start;
}

.activeBtn {
  margin: 0 8px;
  padding: 4px;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5046e5;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  border: 2px solid #c9c6ff;
}

.numberBtn {
  cursor: pointer;
  margin: 0 4px;
  min-width: 24px;
  padding: 2px;
  font-size: 14px;
}

.numberBtn[disabled] {
  cursor: not-allowed;
}
</style>