<template>
  <div style="position : relative;" class="stores page-stores row">
    <card class="add-box mr-2 edit-lg-col-4 col-lg-4" > 
        <router-link  :to="{ path: 'stores/addStore' }">
        <div class="text-center thisCard" >
          <svg class="icon-to-create" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M547.6 103.8L490.3 13.1C485.2 5 476.1 0 466.4 0L109.6 0C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9c0 0 0 0-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3L448 384l-320 0 0-133.4c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3L64 384l0 64c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-64 0-131.4c-4 1-8 1.8-12.3 2.3z"/></svg>
          <!-- <i class="tim-icons icon-bag-16 icon-bag-40 mb-3 mb-icn icn-pointer"></i> -->
          <div class="d-flex justify-content-center">
            <i class="tim-icons tim-icons-16 icon-simple-add this-mt"></i>
            <h4  class="card-title title-new">Create new Store</h4>
          </div>
        </div>
      </router-link >
      </card>
    <card v-for="(store,index) in stores" :key="index" class="parent-card mr-2  col-lg-4">
      <div class="content-card">
        <div class="head-card d-flex">
          <div class="side-1 side">
            <h5>{{store.name}}</h5>
            <span>{{store.createdAt | formatDate2 }}</span> 
          </div>
          <div class="side-2 side">
            <base-input class="group-select">
              <select v-model="action[index]" @change="changeAction(store._id,action[index],index)" class="form-control" :id="'exampleFormControlSelect1'+index">
                <option value="actions">Actions</option>
                <option value="edit">Edit</option>
                <option value="delete">Delete</option>
                <!-- <option value="view">View</option> -->
              </select>
            </base-input>
          </div>
        </div>
        <div class="body-card">
          <img src="@/assets/images/store.png" width="50" height="50" alt="">
        </div>
        <div class="footer-card">
          <div class="d-flex">
            <!-- <BaseCheckbox  /> -->
            <div @click="toggleClass('toggle1')" id="toggle1" class="toggle-btn">
              <div class="inner-circle"> </div>
            </div>
            <!-- <span class="codenumber">2451</span> -->
            <span class="linkQrCode"> <a target="_blank" :href="store.url">Store Link</a> </span>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>

import Card from "@/components/Cards/Card";
import BaseInput from "@/components/Inputs/BaseInput";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import NotificationTemplate from '@/pages/Notifications/NotificationTemplate';

export default {
  components: {
    Card,
    BaseInput,
    BaseCheckbox,
    NotificationTemplate
  },
  data(){
    return{
      //action:'actions',
      action:[

      ],
      storeId : ''
    }
  },
  async mounted(){
    await this.$store.dispatch('loadStores');
  },
  computed:{
    stores(){ 
      console.log('stores in state ====> ',this.$store.state.stores);  
      this.$store.state.stores.forEach(store => {
        this.action.push('actions');
      });
      return this.$store.state.stores 
    }
  },
  watch:{
    // action(val, oldVal){
    //   if(this.action == 'edit'){
    //     this.$router.push({ path: `/stores/update/${this.storeId}`})
    //   }
    // }
  },
  methods:{
    notifyVue(verticalAlign, horizontalAlign, msg, type) {
      const color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
          component: NotificationTemplate,
          message: msg,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: type, //this.type[color],
          timeout: 3000
      });
    },
    toggleClass(id){
      document.getElementById(id).classList.toggle('active');
    },
    performAction() {
      // Perform the action when confirmed
      this.$swal('Action Confirmed', 'Your action has been successfully performed', 'success');
    },
    async changeAction(id,action,index){
      this.storeId = id;
      if(action == 'edit') this.$router.push({ path: `/stores/update/${id}`})
      if (action == 'delete'){
        this.$swal({
          title: 'Are you sure?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed!',
          cancelButtonText: 'Cancel',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // User confirmed, perform the action
            let res = await this.$store.dispatch('deleteStore',id);
            if(res.status == 200){
              this.notifyVue('bottom', 'center', 'Store deleted successfuly', 'success');
              await this.$store.dispatch('loadStores');
            }else{
              this.notifyVue('bottom', 'center', 'Error deelte store', 'danger');
            }
          } else {
            // User cancelled, handle accordingly
            this.action[index] = 'actions';
            console.log(this.action[index]);
            document.getElementById("exampleFormControlSelect1"+index).selectedIndex = 0;
            //this.$swal('Action Cancelled', 'Your action has been cancelled', 'info');
          }
        });
        
      }
    },
    confirmAction() {
      this.$swal({
        title: 'Are you sure?',
        text: 'This action cannot be undone!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed!',
        cancelButtonText: 'Cancel',
      })
      .then((result) => {
        if (result.isConfirmed) {
          // User confirmed, perform the action
          this.performAction();
        } else {
          // User cancelled, handle accordingly
          this.$swal('Action Cancelled', 'Your action has been cancelled', 'info');
        }
      });
    },
  }
} 
</script>

<style >
  .tim-icons{
    color: #fff;
  }

  .icon-bag-40{
    font-size: 40px;
  }

  .icn-pointer{
    cursor: pointer !important;
  }

  .mb-icn{
    margin-bottom: 10px;
  }

  .title-new{
    margin-left: 10px;
  }

  .this-mt{
    margin-top: 4px;
  }

  .parent-card:hover{
    box-shadow:  #ad5389 0px 0px 16px 1px
  }

  .parent-card{
    height: 200px;
  }

  .thisCard{
    padding: 25px;
    cursor: pointer;
  }

  .add-box{
    height: 200px;
  }

  .content-card{
    width: 100%;
    height: 100%;
    position: relative;
  }

  .head-card{
    position: absolute;
  }

  .head-card .side-1{
    margin-right: 91px;
  }

  .head-card .side-2 .form-control{
    /* border-color: #e14eca;
    color: #e14eca;
    background-color: transparent;
    -webkit-box-shadow: none; */

    padding: 5px 0px 5px 1px;
    border-color: #e14eca;
    height: calc(2.25rem + -5px);
    color: #ba54f5;
    background-color: #1e1e29;
    box-shadow: none;
  }

  .head-card h5{
    margin-bottom: 0;
  }

  .body-card{
    position: absolute;
    top: 46px;
    left: 35%;
  }

  .footer-card{
    position: absolute;
    top: 100px;
  }

  .footer-card .codenumber{
    margin-top: 20px;
        margin-left: 54px;
  }

  .footer-card .linkQrCode{
    /* margin-left: 35px; */
    margin-left: 102px;
    margin-top: 19px;
  }

  .footer-card .linkQrCode a{
    font-size: 11px;
  }

  @media (min-width: 992px){
      .page-stores.stores .col-lg-4{
        max-width: 31.33% !important;
      }
  }
  .page-stores.stores  .group-select{
    position: absolute;
    right: -52px;
    z-index: 3;
  }

  .icon-to-create{
    width: 28%;
    fill: #fff;
    margin-bottom: 16px;
  }

</style>
