<template>
    <div class="newStroe row">
        <div class="col-sm-4">
        <h4>
            <span v-if="!isEdit">Add new user</span>
            <span v-if="isEdit">Edit user</span>
        </h4>
        </div>
        <div class="col-sm-8">
            <card>
                <form @submit.prevent>
                    <p class="error-msg">{{ userErrors.fullnameError }}</p>
                    <base-input label="Fullname"
                                type="text"
                                v-model="user.fullname"
                                placeholder="fullname"
                                >
                    </base-input>

                    <p class="error-msg">{{ userErrors.emailError }}</p>
                    <base-input label="Email"
                                type="email"
                                v-model="user.email"
                                placeholder="email"
                                >
                    </base-input>

                    <p class="error-msg">{{ userErrors.phoneNumberError }}</p>
                    <label for="phone">Phone number</label>
                    <base-input 
                    type="text"
                    id="phone"
                    name="phone"
                    v-model="user.phone"
                    
                    >
                    </base-input>
                    
                    <div style="margin-top: 45px;" class="form-group ed-password">
                        <label class="control-label"> Check this if you want to edit your password? </label>
                        <input aria-describedby="addon-right addon-left" v-model="isNotDisabledPassword" type="checkbox" class="form-control" >
                    </div>
                    
                   
                    <!-- <base-input
                        class="ed-password"
                        label="Check this box to edit password?"
                        v-model="isDisabledPassword"
                        @change="handleCheckboxChange"
                        type="checkbox"
                    >
                    </base-input> -->
                    <p class="error-msg">{{ userErrors.oldPasswordError }}</p>
                    <div class="form-group">
                        <label class="control-label"> Old Password </label>
                        <input aria-describedby="addon-right addon-left" 
                        type="password"
                        :disabled="!isNotDisabledPassword"
                        v-model="oldPassword"
                        placeholder="old password"
                        class="form-control" >
                    </div>
                    <!-- <a href="#">Forget password?</a> -->

                    <p class="error-msg">{{ userErrors.newPasswordError }}</p>
                    <div class="form-group">
                        <label class="control-label">New Password </label>
                        <input aria-describedby="addon-right addon-left" 
                        type="password"
                        :disabled="!isNotDisabledPassword"
                        v-model="user.password"
                        placeholder="password"
                        class="form-control" >
                    </div>

                    <!-- <base-input label="Password"
                    type="password"
                    disabled
                    v-model="user.password"
                    placeholder="password"
                    >
                    </base-input> -->
                    
                    <p class="error-msg">{{ userErrors.confirmPasswordError }}</p>
                    <div class="form-group">
                        <label class="control-label">Confirm new password </label>
                        <input aria-describedby="addon-right addon-left" 
                        type="password"
                        :disabled="!isNotDisabledPassword"
                        v-model="confirmPassword"
                        placeholder="confirm password"
                        class="form-control" >
                    </div>

                    <!-- <base-input label="Confirm password"
                    type="password"
                    disabled
                    v-model="confirmPassword"
                    placeholder="confirm password"
                    >
                    </base-input> -->
                    
                    <base-button @click="save()" native-type="submit" type="primary">Save</base-button>
                </form>
            </card>
        </div>
    </div>
</template>

<script>

import NotificationTemplate from '@/pages/Notifications/NotificationTemplate';
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import utilsScript from 'intl-tel-input/build/js/utils.js';

export default {
    components:{
        NotificationTemplate
    },
    data(){
        return{
            isEdit: false,
            user:{
                fullname: '',
                email: '',
                password: '',
                phone: '',
                role: 'ADMIN',
                accountId: '',
                stores: [],
                dialCode: ''
            },
            confirmPassword: '',
            oldPassword:'',
            oldEmail: '',
            isNotDisabledPassword: false,
            userId: '',
            userErrors:{
                fullnameError: '',
                oldPasswordError: '',
                newPasswordError: '',
                confirmPasswordError: '',
                emailError: '',
                phoneNumberError:'',
            },
        }
    },
    async mounted(){
        await this.$store.dispatch('loadStores');
        if(this.$route.params.hasOwnProperty('id')) this.isEdit = true;
        if(this.isEdit){
            this.userId = this.$route.params.id;
            const id = this.$route.params.id;
            this.getUser(id);
        }
        const input = document.querySelector("#phone");
        this.iti = intlTelInput(input, {
            //initialCountry: "auto",
            utilsScript: utilsScript,
            nationalMode: true
        });
    },
    beforeDestroy() {
        if (this.iti) {
        this.iti.destroy(); // Clean up on component destroy
        }
    },
    watch: {
        isNotDisabledPassword: function (newVal) {
            console.log(newVal);
            if(newVal){

            }
        },
    },
    computed:{
        stores(){
            return this.$store.state.stores 
        },
        me(){
            return this.$store.state.me
        }
    },
    methods:{
       
        notifyVue(verticalAlign, horizontalAlign, msg, type) {
            const color = Math.floor(Math.random() * 4 + 1);
            this.$notify({
                component: NotificationTemplate,
                message: msg,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: type, //this.type[color],
                timeout: 3000
            });
        },
        async getUser(id){
            let resUser = await this.$store.dispatch('getOneUser',id); 
            console.log('getUser add || edit == ', resUser);
            this.user.fullname = resUser.fullname;
            this.user.email = resUser.email;
            this.user.phone = resUser.phone;
            this.user.role = resUser.role;
            this.user.accountId = resUser.accountId;
            this.user.stores = resUser.stores;
            this.user.dialCode = resUser.dialCode;
            this.oldEmail = resUser.email;
            console.log('this user====',this.user);
            let country = this.user.dialCode;
            if (this.iti && this.user.phone) {
                // Set the country first using the saved country code (e.g., "ma" for Morocco)
                this.iti.setCountry(country);
                this.iti.setNumber(this.user.phone);
            }
        },
        async addUser(){
            this.user.accountId = this.me.accountId;
            this.user.stores = this.me.stores;
            console.log('me stores $$$$',this.user.stores);
            if((this.user.password == this.confirmPassword) && (this.user.fullname != '' && this.confirmPassword != '' && this.user.password != '' )){
                let user = this.user;
                console.log(user);
                let resUser = await this.$store.dispatch('createUser',user);
                if(resUser.status == 200) this.notifyVue('bottom', 'center', 'User created successfuly', 'success');
                else this.notifyVue('bottom', 'center', 'Something went wrong', 'danger') 
            }else{
                this.notifyVue('bottom', 'center', 'Some fields are empty or the password does not match the confirmation password', 'danger');
            }
        },
        save(){
            if(!this.isEdit) this.addUser();
            else this.updateUser();
        },
        async updateUser(){
            this.userErrors.fullnameError = '';
            this.userErrors.oldPasswordError = ''; 
            this.userErrors.newPasswordError = '';
            this.userErrors.confirmPasswordError = '';
            this.userErrors.emailError = '';
            this.userErrors.phoneNumberError = '';
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if(!this.isNotDisabledPassword){
                if(this.user.fullname == '' || this.user.email == '' || this.user.phone == '' || !emailPattern.test(this.user.email) ||
                 !this.iti.isValidNumber()){
                    if(this.user.fullname == '') this.userErrors.fullnameError = 'Please enter your fullname';
                    if(this.user.email == '') this.userErrors.emailError = 'Please enter your email';
                    if(this.user.phone == '') this.userErrors.phoneNumberError = 'Please enter your phone number';
                    if(this.user.email !== '' && !emailPattern.test(this.user.email)) this.userErrors.emailError = 'Please enter a valid email address';
                    if (!this.iti.isValidNumber()) {
                        this.userErrors.phoneNumberError = "Please enter a valid phone number.";
                    }
                }
                else{
                    this.user.dialCode = this.iti.getSelectedCountryData().iso2;
                    let formatPhone = this.user.phone.replace(/\D/g, '');
                    this.user.phone = formatPhone;
                    let userUpd = {
                        fullname: this.user.fullname,
                        email: this.user.email,
                        phone: this.user.phone,
                        dialCode: this.user.dialCode
                    };
                    let id = this.userId;
                    let resUpdateUser = await this.$store.dispatch('updateUser',{id,userUpd});
                    if(resUpdateUser.status == 200 || resUpdateUser.status == 201){
                        this.oldEmail = this.user.email;
                        this.notifyVue('bottom', 'center', 'User updated successfuly', 'success');
                    }else this.notifyVue('bottom', 'center', resUpdateUser.data.msg, 'danger') 
                }
            }
            else{
                if(this.user.fullname == '' || this.user.email == '' || this.user.phone == '' 
                 || this.user.password == '' || this.confirmPassword == '' || this.oldPassword == ''
                 || !emailPattern.test(this.user.email) || this.user.password !== this.confirmPassword
                 || !this.iti.isValidNumber()){

                    if(this.user.fullname == '') this.userErrors.fullnameError = 'Please enter your fullname';
                    if(this.user.email == '') this.userErrors.emailError = 'Please enter your email';
                    if(this.user.phone == '') this.userErrors.phoneNumberError = 'Please enter your phone number';
                    if(this.user.email !== '' && !emailPattern.test(this.user.email)) this.userErrors.emailError = 'Please enter a valid email address';
                    if (!this.iti.isValidNumber()) {
                        this.userErrors.phoneNumberError = "Please enter a valid phone number.";
                    }

                    if(this.oldPassword == '') this.userErrors.oldPasswordError = 'Please enter your old password';
                    if(this.user.password == '') this.userErrors.newPasswordError = 'Please enter your new password';
                    if(this.confirmPassword == '') this.userErrors.confirmPasswordError = 'Please enter your confirm password';
                    if(this.user.password !== this.confirmPassword) this.userErrors.confirmPasswordError = 'The confirmation password does not match the new password ';

                }else{
                    this.user.dialCode = this.iti.getSelectedCountryData().iso2;
                    let formatPhone = this.user.phone.replace(/\D/g, '');
                    this.user.phone = formatPhone;
                    let userUpd = {
                        fullname: this.user.fullname,
                        email: this.user.email,
                        phone: this.user.phone,
                        dialCode: this.user.dialCode,
                        password: this.user.password
                    };
                    let id = this.userId;
                    let user = {
                        email : this.oldEmail,
                        password : this.oldPassword
                    }
                    let res = await this.$store.dispatch('login',user);
                    console.log('res submit login', res.data);
                    if(res.data.success){
                        let resUpdateUser = await this.$store.dispatch('updateUser',{id,userUpd});
                        if(resUpdateUser.status == 200 || resUpdateUser.status == 201){
                            this.oldPassword = this.user.password;
                            this.notifyVue('bottom', 'center', 'User updated successfuly', 'success');
                            let updatedUser = {
                                email : this.user.email,
                                password : this.user.password
                            }
                            let resUpdUserLogin = await this.$store.dispatch('login',updatedUser);
                            if(!resUpdUserLogin.data.success) this.notifyVue('bottom', 'center', 'Error when trying to login !!!', 'danger');
                        }else this.notifyVue('bottom', 'center', resUpdateUser.data.msg, 'danger') 
                    }else{
                        this.notifyVue('bottom', 'center', 'Incorrect email or the old password', 'danger') 
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

    .ed-password input{
        width: 7%;
        height: 24px;
        margin-top: 15px;
    }

    .error-msg{
        margin-bottom: 5px;
        font-size: 11px;
        color: #d74343 !important;
    }

</style>