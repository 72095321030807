<template> 
  <div v-if="isLoggedIn  && routeName!='signup'" style="background: #fff; position: relative;" class="wrapper">
    <div v-if="$store.state.showExpirePopup" class="background-of-popup">

    </div>
    <div v-if="$store.state.showExpirePopup" class="expired-popup">
      <div v-if="false" class="close-popup">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style="&#10;    width: 25px;&#10;    height: 25px;&#10; margin-top: 7px;"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
      </div>
      <card>
        <div style="text-align: center;">
          <i style="font-size: 50px;" class="fa-solid fa-hourglass-end"></i>
          <h2 style="margin-top: 20px;" class="color-text-px">Your Subscription has been expired. Please click continue to renew your subscription.</h2>
          <!-- <p class="color-text-px">Please click continue to renew your subscription</p> -->
          <base-button @click="goToPayment()" style="margin-top: 10px;"  native-type="button"  type="primary">Continue</base-button>
        </div>
      </card>
    </div>
    <side-bar >
      <template slot="links"> 
        <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-chart-pie-36"/>
        <sidebar-link to="/stores" :name="$t('sidebar.stores')" icon="fa-solid fa-store"/>
        <sidebar-link to="/rewards" :name="$t('sidebar.rewards')" icon="fa-solid fa-gift"/>
        <!-- <sidebar-link to="/giftcards" :name="$t('sidebar.giftcards')" icon="tim-icons icon-chart-pie-36"/> -->
        <sidebar-link to="/points" :name="$t('sidebar.points')" icon="tim-icons icon-wallet-43"/>
        <sidebar-link to="/customers" :name="$t('sidebar.customers')" icon="fa-regular fa-user"/>
        <sidebar-link to="/orders" :name="$t('sidebar.orders')" icon="tim-icons icon-bag-16"/>
        <sidebar-link to="/redeems" :name="$t('sidebar.redeems')" icon="fa-solid fa-list"/>
        <!-- <sidebar-link to="/icons" :name="$t('sidebar.icons')" icon="tim-icons icon-atom"/> -->
        <!-- <sidebar-link to="/maps" :name="$t('sidebar.maps')" icon="tim-icons icon-pin"/>
        <sidebar-link to="/notifications" :name="$t('sidebar.notifications')" icon="tim-icons icon-bell-55"/> -->
        <!-- <sidebar-link to="/profile" :name="$t('sidebar.userProfile')" icon="tim-icons icon-single-02"/> -->
        <!-- <sidebar-link to="/table-list" :name="$t('sidebar.tableList')" icon="tim-icons icon-puzzle-10"/>
        <sidebar-link to="/typography" :name="$t('sidebar.typography')" icon="tim-icons icon-align-center"/>
        <sidebar-link to="/dashboard?enableRTL=true" :name="$t('sidebar.rtlSupport')" icon="tim-icons icon-world"/> -->
      </template>
    </side-bar>
    <!-- <div v-if="isLoggedIn" class="main-panel" > -->
    <div class="main-panel" >
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar"> 
      </dashboard-content>
      <content-footer></content-footer>
    </div>
    <!-- <div v-if="!isLoggedIn">
       <router-view></router-view>
    </div> -->
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import Login from "../../components/Login.vue";
import moment from 'moment';
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    Login
  },
  data(){
    return{
      showElementsPage : true,
      showExpirePopup: false
    }
  },
  async mounted(){
    
    if(this.$route.name == 'login') {
      if (window.location.hostname === 'admin.rewardisfy.com') {
        window.location.replace("https://admin.rewardisfy.com/dashboard");
      } else {
        window.location.replace("http://localhost:8080/dashboard");
      }
    }
    await this.$store.dispatch('loadMe');
    console.log('Me _________',this.me);
    let accountId = this.me.accountId;
    await this.$store.dispatch('getAccount', accountId);
    console.log('myAccount______',this.myAccount);
    let stringDate = this.myAccount.experationDate;
    
    const currentDate = moment();
    const expiredDate = moment(stringDate);

    console.log('currentDate ===>', currentDate);
    
    if(currentDate.isAfter(expiredDate)) {
      if(this.myAccount.status == 'ACTIVE' || this.myAccount.status == 'TRIAL'){
        let data = {
          status : 'EXPIRED'
        }
        await this.$store.dispatch('updateAccount', {accountId,data});
      }
      console.log('path',this.$route.path)
      if (this.$route.path == '/manager/payments/add' || this.$route.path == '/manager' ||
       this.$route.path == '/manager/payments' || this.$route.path == '/manager/users'||
        this.$route.path == '/manager/account' ) {
        this.showExpirePopup = false;
        this.$store.dispatch('expiredPopup', this.showExpirePopup );
        
      }else{
        this.showExpirePopup = true;
        this.$store.dispatch('expiredPopup', this.showExpirePopup );
      }
    } else {
      this.showExpirePopup = false;
      this.$store.dispatch('expiredPopup', this.showExpirePopup );
      if(this.myAccount.status == 'EXPIRED'){
        let data = {
          status : 'ACTIVE'
        }
        await this.$store.dispatch('updateAccount', {accountId,data});
      }
    }
  },
  computed:{
    isLoggedIn(){
      return this.$store.getters.isLoggedIn
    },
    routeName(){
      return this.$route.name
    },
    me(){
      return this.$store.state.me
    },
    myAccount(){
      return this.$store.state.myAccount
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    goToPayment(){
      this.$router.push({ name: 'addPayment' });
    },
  }
};
</script>

<style lang="scss">

.background-of-popup{
  z-index: 999;
  background-color: #000;
  opacity: 0.7;
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.expired-popup{
  position: fixed;
  z-index: 1000;
  left: 36%;
  top: 171px;
  width: 400px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 5px;
}

.close-popup{
  width: 40px;
  height: 40px;
  background: #dfe6e9;
  text-align: center;
  position: absolute;
  top: -20px;
  z-index: 1200;
  right: -17px;
  border-radius: 6px;
  cursor: pointer;
}

.expired-popup .form-group .control-label{
  color:#1b1919 !important;
}

.expired-popup .form-group .form-control{
  color: #000;
}

.expired-popup .card-body{
  background:#fff;
}

.expired-popup .order-for-value{
  color: #827e7e;
}

.order-for-title{
  color:#1b1919 !important;
}

.color-text-px{
  color: #525f7f !important;
}

</style>
