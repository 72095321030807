<template>
  <div>
    <card>
      <div class="head-card-paym">
        <div class="d-flex">
          <div class="w-50 p-3">
            <div>
              <span class="white-clr">Account ID:</span>&nbsp;
              <span class="mv-clr">{{ myAccount._id }}</span>
            </div>
          </div>
          <div class="w-50 p-3">
            <div>
              <span class="white-clr">Pack & period</span>
            </div>
            <div class="gr-clr">
              <span>{{ pack }}</span> -
              <span>{{ subscription }}</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="my-4 white-clr"> Packs </div>
        <div class="row plans-holder">
          <div v-if="false" @click="pack = 'freeplan' " class="col-sm-3 P-3  text-center">
            <div :class=" pack == 'freeplan' ? 'active': '' " class="br-mv plan-cont white-clr position-relative">
              <i v-if="pack == 'freeplan' " class="fa-solid fa-circle-check check-ch" style="color: #38d219;"></i>
              FREE PLAN
            </div>
          </div>
          <div @click="pack = 'startup' " class="col-sm-3 P-3 text-center">
            <div :class=" pack == 'startup' ? 'active': '' " class="br-mv plan-cont white-clr position-relative">
              <i v-if="pack == 'startup'" class="fa-solid fa-circle-check check-ch" style="color: #38d219;"></i>
              STARTUP
            </div>
          </div>
          <div v-if="false" @click="pack = 'pro' " class="col-sm-3 P-3 text-center">
            <div :class=" pack == 'pro' ? 'active': '' " class="br-mv plan-cont white-clr position-relative">
              <i v-if="pack == 'pro'" class="fa-solid fa-circle-check check-ch" style="color: #38d219;"></i>
              PROFESSIONAL
            </div>
          </div>
          <div v-if="false" @click="pack = 'business' " class="col-sm-3 P-3 text-center">
            <div :class=" pack == 'business' ? 'active': '' " class="br-mv plan-cont white-clr position-relative">
              <i v-if="pack == 'business' " class="fa-solid fa-circle-check check-ch" style="color: #38d219;"></i>
              BUSINESS
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="my-4 white-clr"> Subscription type </div>
        <div class="row plans-holder">
          <div @click="subscription = 'monthly' " class="col-sm-3 P-3  text-center">
            <div :class=" subscription == 'monthly' ? 'active': '' " class="br-mv plan-cont sub-plan white-clr">
              <i v-if="subscription == 'monthly'" class="fa-solid fa-circle-check check-ch" style="color: #38d219;"></i>
              <div> MONTHLY </div>
              <div> 
                <span class="font-weight-bold gr-clr"> {{monthly}}$ </span> 
              </div>
            </div>
          </div>
          <div v-if="false" @click="subscription = 'quarterly' " class="col-sm-3 P-3 text-center">
            <div :class=" subscription == 'quarterly' ? 'active': '' " class="br-mv plan-cont sub-plan white-clr">
              <i v-if="subscription == 'quarterly'" class="fa-solid fa-circle-check check-ch" style="color: #38d219;"></i>
              <div> QUARTERLY </div>
              <div> <span class="font-weight-bold gr-clr"> {{quarterly}}$ </span> </div>
            </div>
          </div>
          <div v-if="false" @click="subscription = 'semesterly' " class="col-sm-3 P-3 text-center">
            <div :class=" subscription == 'semesterly' ? 'active': '' " class="br-mv plan-cont sub-plan white-clr">
              <i v-if="subscription == 'semesterly'" class="fa-solid fa-circle-check check-ch" style="color: #38d219;"></i>
              <div> SEMESTERLY </div>
              <div> <span class="font-weight-bold gr-clr"> {{semesterly}}$ </span> </div>
            </div>
          </div>
          <div v-if="false" @click="subscription = 'yearly' " class="col-sm-3 P-3 text-center">
            <div :class=" subscription == 'yearly' ? 'active': '' " class="br-mv plan-cont sub-plan white-clr">
              <i v-if="subscription == 'yearly'" class="fa-solid fa-circle-check check-ch" style="color: #38d219;"></i>
              <div> YEARLY </div>
              <div> <span class="font-weight-bold gr-clr"> {{yearly}}$ </span> </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="my-4 white-clr">Payments methods</div>
        <div class="d-flex">
          <div @click="paymentMethod = 'card' " :class=" paymentMethod == 'card' ? 'active': '' " class="item-method">
            <img src="@/assets/images/visa-and-mastercard-Card-international.png" alt="">
          </div>
          <div @click="paymentMethod = 'paypal' " :class=" paymentMethod == 'paypal' ? 'active': '' " class="item-method">
            <img src="@/assets/images/paypal.png" alt="">
          </div>
        </div>
      </div>
      <div class="footer-card-paym mt-5">
        <div class="d-flex">
          <div class="w-50 p-3">
            <div>
              <span class="white-clr">Pack & Period selected</span>
            </div>
            <div class="gr-clr">
              <span>{{ pack }}</span> -
              <span>{{ subscription }}</span>
            </div>
          </div>
          <div class="w-50 p-3">
            <div>
              <span class="white-clr font-weight-bold">Total</span>
            </div>
            <div> <span class="font-weight-bold gr-clr"> {{ total }}.00 $ </span> </div>
          </div>
        </div>
      </div>
      <div>
        <div class="mt-5 pay-btn-holder">
          <button @click="paySub()" class="btn-add-payment ml-auto pay-btn">
            <span><i class="fa-regular fa-credit-card"></i></span>
              <span> Pay </span> 
          </button>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  data(){
    return{
      pack: 'startup',
      subscription: 'monthly',
      paymentMethod: 'paypal',
      total: 15,
      pay:{
        pack: 'STARTER',
        subscription: 'MONTHLY',
        accountId:'',
        userId: '',
        currency: 'USD',
        email: ''
      },
      monthly: 15,
      quarterly: 24,
      semesterly: 34,
      yearly: 43,
      prices:[
        {
          pack: 'freeplan',
          monthly: 0,
          quarterly: 0,
          semesterly: 0,
          yearly: 0
        },
        {
          pack: 'startup',
          monthly: 15,
          quarterly: 24,
          semesterly: 34,
          yearly: 43
        },
        {
          pack: 'pro',
          monthly: 30,
          quarterly: 40,
          semesterly: 50,
          yearly: 60
        },
        {
          pack: 'business',
          monthly: 35,
          quarterly: 45,
          semesterly: 55,
          yearly: 65
        },
      ]
    }
  },
  async mounted(){
      await this.init();
  },
  computed:{
    me(){
      return this.$store.state.me
    },
    myAccount(){
      return this.$store.state.myAccount;
    },
    values() {
      return {
        pack: this.pack,
        subscription: this.subscription,
      };
    },
  },
  watch: {
    values: {
      handler(newValues, oldValues) {
        console.log(`Value A changed from ${oldValues.pack} to ${newValues.pack}`);
        console.log(`Value B changed from ${oldValues.subscription} to ${newValues.subscription}`);
        if(newValues.pack == 'freeplan'){
          this.prices.forEach(element => {
            if(element.pack == 'freeplan'){
              this.monthly = element.monthly;
              this.quarterly = element.quarterly;
              this.semesterly = element.semesterly;
              this.yearly = element.yearly;
              if(newValues.subscription == 'monthly'){
                this.total = element.monthly;
              }
              if(newValues.subscription == 'quarterly'){
                this.total = element.quarterly;
              }
              if(newValues.subscription == 'semesterly'){
                this.total = element.semesterly;
              }
              if(newValues.subscription == 'yearly'){
                this.total = element.yearly;
              }
            }
          });
        }
        if(newValues.pack == 'startup'){
          this.prices.forEach(element => {
            if(element.pack == 'startup'){
              this.monthly = element.monthly;
              this.quarterly = element.quarterly;
              this.semesterly = element.semesterly;
              this.yearly = element.yearly;
              if(newValues.subscription == 'monthly'){
                this.total = element.monthly;
              }
              if(newValues.subscription == 'quarterly'){
                this.total = element.quarterly;
              }
              if(newValues.subscription == 'semesterly'){
                this.total = element.semesterly;
              }
              if(newValues.subscription == 'yearly'){
                this.total = element.yearly;
              }
            }
          });
        }
        if(newValues.pack == 'pro'){
          this.prices.forEach(element => {
            if(element.pack == 'pro'){
              this.monthly = element.monthly;
              this.quarterly = element.quarterly;
              this.semesterly = element.semesterly;
              this.yearly = element.yearly;
              if(newValues.subscription == 'monthly'){
                this.total = element.monthly;
              }
              if(newValues.subscription == 'quarterly'){
                this.total = element.quarterly;
              }
              if(newValues.subscription == 'semesterly'){
                this.total = element.semesterly;
              }
              if(newValues.subscription == 'yearly'){
                this.total = element.yearly;
              }
            }
          });
        }
        if(newValues.pack == 'business'){
          this.prices.forEach(element => {
            if(element.pack == 'business'){
              this.monthly = element.monthly;
              this.quarterly = element.quarterly;
              this.semesterly = element.semesterly;
              this.yearly = element.yearly;
              if(newValues.subscription == 'monthly'){
                this.total = element.monthly;
              }
              if(newValues.subscription == 'quarterly'){
                this.total = element.quarterly;
              }
              if(newValues.subscription == 'semesterly'){
                this.total = element.semesterly;
              }
              if(newValues.subscription == 'yearly'){
                this.total = element.yearly;
              }
            }
          });
        }
      },
      deep: true,
    },
  },
  methods:{
    init(){
      console.log('me user at add payment ===',this.$store.state.me);
      this.pay.accountId = this.$store.state.me.accountId;
      this.pay.userId = this.$store.state.me._id;
      this.pay.email = this.$store.state.me.email
      console.log('pay object {}', this.pay);
    },
    async paySub(){
      if(this.pack == 'startup') this.pay.pack = 'STARTER';
      if(this.subscription == 'monthly') this.pay.subscription = 'MONTHLY';
      if(this.pack == 'pro') this.pay.pack = 'PRO';
      if(this.subscription == 'yearly') this.pay.subscription = 'YEARLY';
      let pay = this.pay;
      if(this.paymentMethod == 'paypal'){
        let respData = await this.$store.dispatch('paySubscription',pay);
        window.open(respData.approv_Link, "_blank");
      }else{
        pay.productId = "595953";
        let respData = await this.$store.dispatch('payByCard',pay);
        window.open(respData.checkoutUrl, "_blank");
        //alert('Payment by card does not exist right now try paypal method');
        console.log('payment method is ===>', this.paymentMethod);
      }
    }
  }
}
</script>

<style>
  .head-card-paym{
    padding: 13px 10px;
    border: 1px solid #e44cc4;
  }

  .footer-card-paym{
    padding: 13px 10px;
    border: 1px solid #e44cc4;
  }

  .br-mv{
    border: 1px solid #e44cc4;
  }

  .white-clr{
    color: #ffffff;
  }

  .mv-clr{
    color: #ba54f5;
  }

  .gr-clr{
    color: #28c76f;
  }

  .plans-holder{
    /* padding: 0 17px; */
  }

  .plan-cont{
    padding-top: 10px;
    width: 100%;
    padding-bottom: 10px;
    cursor: pointer;
  }

  .plan-cont:hover{
    border: 2px solid rgba(103,240,114,.9);
    background: rgba(103,240,114,.1);
  }

  .plan-cont.active{
    border: 2px solid rgba(103,240,114,.9);
    background: rgba(103,240,114,.1);
  }

  .item-method{
    width: 24%;
    margin-right: 1%;
    background-color: #ffffff;
    cursor: pointer;
    border: 1px solid #e44cc4;
  }

  .item-method img{
    width: 100%;
    height: 3rem;
    object-fit: contain;
  }

  .item-method:hover{
    border: 1px solid hsla(0,0%,80.8%,.9);
    background: #e0e0e0
  }

  .item-method.active{
    border: 1px solid rgba(92,150,252,.9);
    background: rgba(92,150,252,.6);
  }

  .pay-btn-holder{
    text-align: center;
  }

  .pay-btn-holder .pay-btn{
    width: 120px;

    padding: 7px 10px;
    font-size: 0.875rem;
    /* line-height: 1.25rem; */
    border-radius: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    text-transform: none;
    /* border: 0; */
    /* outline: 0; */
    cursor: pointer;

    background-color: #ff9f43;
    color: #fff;
  }

  .sub-plan{
    position: relative;
  }

  .check-ch{
    position: absolute;
    right: 5px;
    top: 3px;
  }
  

</style>